import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { HSRRelicSet } from '../common/components/hsr-relic-set';

interface IProps {
  slug: string;
  name: string;
  element: string;
  path: string;
}

export const HSRProfileRelics: React.FC<IProps> = ({
  slug,
  element,
  name,
  path
}) => {
  return (
    <>
      <div className="build-relics">
        <div className={`content-header ${element}`}>
          <FontAwesomeIcon icon={faSquare} width="18" /> Best Relics
        </div>
        {path === 'Erudition' && (
          <>
            <div className="info-box with-margin">
              <p>
                <strong className={`${element}`}>{name}</strong> Relic and Light
                Cone calculations are based on fighting against 3 targets.
              </p>
            </div>
          </>
        )}
        <p>
          <strong>Best Relic Sets</strong> show the best sets that support the
          main character playstyle and work out of the box while the{' '}
          <strong>Special Relic Sets</strong> either are situational, support an
          alternative playstyle or are targeted toward min-maxers. The
          percentage numbers showcase the relative DPS increase when you take
          into account the set bonus only. For some characters there's no DPS
          (or its equivalent) available and in that case we listed the sets in
          the order of priority.
        </p>
        {/* Sustain */}
        {slug === 'aventurine' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet name="Knight of Purity Palace" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Best defensive-focused set option for all Shield based
                    characters.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Knight of Purity Palace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Messenger Traversing Hackerspace"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Pioneer Diver of Dead Waters"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">
                        [DMG to debuffed enemies +12%]
                      </span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Scholar Lost in Erudition"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[CRIT Rate +8%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Wastelander of Banditry Desert"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[IMG DMG +10%]</span>
                    </li>
                  </ul>
                </div>
              </>
            </div>
            <h6>Special Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet
                    name="Pioneer Diver of Dead Waters"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Incredibly powerful offensive option for CRIT based
                    characters capable of inflicting a debuff once per action
                    while also playing in teams capable of applying multiple
                    debuffs.{' '}
                    <strong>
                      Aventurine's Ultimate, Follow-Up's (only with Signature)
                      and Basic Attacks (only with E2) can all activate the 4P
                      100% bonus of this set. Depending on your investment into
                      this character this may or may not worth using as a DPS
                      option for you.
                    </strong>
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'fu-xuan' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Longevous Disciple"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Fu Xuan has no dedicated 4P Relic set that is ideal for her.
                    Mix and match the 2P Bonuses from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Guard of Wuthering Snow"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[DMG Taken -8%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Messenger Traversing Hackerspace"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                  </ul>
                </div>
              </>
            </div>
            <h6>Special Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet name="Longevous Disciple" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Powerful damage focused set for characters with multipliers
                    that scale off of HP% and CRIT and who consistently take
                    damage from enemies or deal it to themselves.{' '}
                    <strong>
                      Niche option for those aiming to increase their Fu Xuan's
                      damage output.
                    </strong>
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'gallagher' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Outside of a pure focus on Break-damage Gallagher has no
                    dedicated 4P Relic set that is ideal for him. Mix and match
                    the 2P Bonuses from the following instead when focusing on
                    support:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span> (Recommended)
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Watchmaker, Master of Dream Machinations"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Break Effect +16%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Iron Cavalry Against the Scourge"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Break Effect +16%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Thief of Shooting Meteor"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Break Effect +16%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Passerby of Wandering Cloud"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Outgoing Healing +10%]</span>
                    </li>
                  </ul>
                </div>
              </>
            </div>
            <h6>Special Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet
                    name="Iron Cavalry Against the Scourge"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Top damage-focused option for characters that play within
                    teams that leverage Super Break or deal predominantly
                    Break/Super Break damage themselves. Becomes even stronger
                    with additional sources of DEF% reduction.{' '}
                    <strong>
                      When playing Gallagher in teams with a source of team-wide
                      Super Break, this option boosts his personal damage
                      considerably. Without a source of Super Break this option
                      is not nearly as useful.
                    </strong>
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet name="Thief of Shooting Meteor" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Good option for Break-scaling characters especially those
                    who can benefit from the additional energy gain granted when
                    the wearer Breaks enemies. Especially potent for and
                    recommended for AoE capable characters in modes with many
                    enemies to break like Pure Fiction.
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'gepard' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet name="Knight of Purity Palace" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Best defensive-focused set option for all Shield based
                    characters.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Knight of Purity Palace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Messenger Traversing Hackerspace"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                  </ul>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'march-7th' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet name="Knight of Purity Palace" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Best defensive-focused set option for all Shield based
                    characters.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Knight of Purity Palace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Messenger Traversing Hackerspace"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                  </ul>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'trailblazer-preservation' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet name="Knight of Purity Palace" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Best defensive-focused set option for all Shield based
                    characters.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Knight of Purity Palace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Guard of Wuthering Snow"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[DMG Taken -8%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Messenger Traversing Hackerspace"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                  </ul>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'lingsha' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet
                    name="Iron Cavalry Against the Scourge"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Top damage-focused option for characters that play within
                    teams that leverage Super Break or deal predominantly
                    Break/Super Break damage themselves. Becomes even stronger
                    with additional sources of DEF% reduction.{' '}
                    <strong>
                      When playing Lingsha in teams with a source of team-wide
                      Super Break, this option boosts her personal damage
                      considerably. Without a source of Super Break this option
                      is not nearly as useful.
                    </strong>
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <HSRRelicSet name="Thief of Shooting Meteor" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Good option for Break-scaling characters especially those
                    who can benefit from the additional energy gain granted when
                    the wearer Breaks enemies. Especially potent for and
                    recommended for AoE capable characters in modes with many
                    enemies to break like Pure Fiction.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>3</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Thief of Shooting Meteor"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Watchmaker, Master of Dream Machinations"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Break Effect +16%]</span>{' '}
                      (Recommended)
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Iron Cavalry Against the Scourge"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Break Effect +16%]</span>{' '}
                      (Recommended)
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Messenger Traversing Hackerspace"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Passerby of Wandering Cloud"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Outgoing Healing +10%]</span>{' '}
                      (Healing Focus Only)
                    </li>
                  </ul>
                </div>
              </>
            </div>
            <h6>Special Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet name="Passerby of Wandering Cloud" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Option for teams with front-loaded SP usage. Most suitable
                    for Sustain characters but can be used on others if the
                    skill points are needed.
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'luocha' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Luocha has no dedicated 4P Relic set that is ideal for him.
                    Mix and match the 2P Bonuses from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Passerby of Wandering Cloud"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Outgoing Healing +10%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Musketeer of Wild Wheat"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[ATK +12%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Prisoner in Deep Confinement"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[ATK +12%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="The Wind-Soaring Valorous"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[ATK +12%]</span>
                    </li>
                  </ul>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <HSRRelicSet name="Musketeer of Wild Wheat" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Accessible alternative to using 2P SPD + 2P ATK, assuming it
                    has strong sub-stats.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>3</p>
                  </div>
                  <HSRRelicSet name="Passerby of Wandering Cloud" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Option for teams with front-loaded SP usage. Most suitable
                    for Sustain characters but can be used on others if the
                    skill points are needed.
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'lynx' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet
                    name="Messenger Traversing Hackerspace"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Good team-wide supportive option for characters with
                    Ultimates that target allies. With this set equipped,
                    Ultimates are best timed carefully to ensure the Speed buff
                    is active on key members for as long as possible.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Passerby of Wandering Cloud"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Outgoing Healing +10%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet name="Longevous Disciple" compactMode smol />{' '}
                      <span className="value">[HP +12%]</span>
                    </li>
                  </ul>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>3</p>
                  </div>
                  <HSRRelicSet name="Passerby of Wandering Cloud" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Option for teams with front-loaded SP usage. Most suitable
                    for Sustain characters but can be used on others if the
                    skill points are needed.
                  </p>
                </div>
              </>
            </div>
            <h6>Special Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet name="Sacerdos' Relived Ordeal" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Incredibly powerful set for characters with Ultimates and or
                    Skills that target a single ally and who work well in teams
                    with a sole damage dealer who can benefit from CRIT DMG.{' '}
                    <strong>
                      In some teams Lynx consistently uses her Skill to maintain
                      its Taunting effect on a character - while doing this she
                      can apply and maintain this sets effect. Outside of this
                      scenario this sets use becomes a lot more limited.
                    </strong>
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'huohuo' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet name="Passerby of Wandering Cloud" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Option for teams with front-loaded SP usage. Most suitable
                    for Sustain characters but can be used on others if the
                    skill points are needed.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <HSRRelicSet
                    name="Messenger Traversing Hackerspace"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Good team-wide supportive option for characters with
                    Ultimates that target allies. With this set equipped,
                    Ultimates are best timed carefully to ensure the Speed buff
                    is active on key members for as long as possible.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>3</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Passerby of Wandering Cloud"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Outgoing Healing +10%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet name="Longevous Disciple" compactMode smol />{' '}
                      <span className="value">[HP +12%]</span>
                    </li>
                  </ul>
                </div>
              </>
            </div>
          </>
        )}
        {(slug === 'bailu' || slug === 'natasha') && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet
                    name="Messenger Traversing Hackerspace"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Good team-wide supportive option for characters with
                    Ultimates that target allies. With this set equipped,
                    Ultimates are best timed carefully to ensure the Speed buff
                    is active on key members for as long as possible.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Passerby of Wandering Cloud"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Outgoing Healing +10%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet name="Longevous Disciple" compactMode smol />{' '}
                      <span className="value">[HP +12%]</span>
                    </li>
                  </ul>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>3</p>
                  </div>
                  <HSRRelicSet name="Passerby of Wandering Cloud" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Option for teams with front-loaded SP usage. Most suitable
                    for Sustain characters but can be used on others if the
                    skill points are needed.
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {/* Amplifiers */}
        {slug === 'robin' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Musketeer of Wild Wheat"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Robin has no dedicated 4P Relic set that is ideal for her.
                    Mix and match the 2P Bonuses from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Messenger Traversing Hackerspace"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Prisoner in Deep Confinement"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[ATK +12%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="The Wind-Soaring Valorous"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[ATK +12%]</span>
                    </li>
                  </ul>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'ruan-mei' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet
                    name="Watchmaker, Master of Dream Machinations"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Incredible team-wide supportive set for Break-focused teams.
                    Most optimally played on the character in the team with the
                    most frequent access to an Ultimate which buffs allies to
                    ensure strong uptime. In teams with particularly fast
                    Break-focused damage dealers, equipping this to multiple
                    Supports for better uptime may be advised.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <HSRRelicSet
                    name="Messenger Traversing Hackerspace"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Good team-wide supportive option for characters with
                    Ultimates that target allies. With this set equipped,
                    Ultimates are best timed carefully to ensure the Speed buff
                    is active on key members for as long as possible.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>3</p>
                  </div>
                  <HSRRelicSet name="Thief of Shooting Meteor" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Good option for Break-scaling characters, especially those
                    who can benefit from the additional Energy gain granted when
                    the wearer Breaks enemies.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>4</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Thief of Shooting Meteor"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Iron Cavalry Against the Scourge"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Break Effect +16%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Watchmaker, Master of Dream Machinations"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Break Effect +16%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Messenger Traversing Hackerspace"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                  </ul>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'trailblazer-harmony' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet
                    name="Watchmaker, Master of Dream Machinations"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Incredible team-wide supportive set for Break-focused teams.
                    Most optimally played on the character in the team with the
                    most frequent access to an Ultimate which buffs allies to
                    ensure strong uptime. In teams with particularly fast
                    Break-focused damage dealers, equipping this to multiple
                    Supports for better uptime may be advised.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Thief of Shooting Meteor"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Iron Cavalry Against the Scourge"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Break Effect +16%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Watchmaker, Master of Dream Machinations"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Break Effect +16%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Messenger Traversing Hackerspace"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                  </ul>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>3</p>
                  </div>
                  <HSRRelicSet name="Thief of Shooting Meteor" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Good option for Break-scaling characters, especially those
                    who can benefit from the additional Energy gain granted when
                    the wearer Breaks enemies.
                  </p>
                </div>
              </>
            </div>
            <h6>Special Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet name="Eagle of Twilight Line" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Allows characters with accessible Ultimates to reach higher
                    Speed (and cycle) breakpoints compared to just stacking
                    Speed. Strongest on characters with powerful ultimate
                    related effects or who can access their ultimate at least
                    once every 3 (but ideally 2) actions. Particularly strong
                    when combined with other action advancement effects.
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'sparkle' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet name="Sacerdos' Relived Ordeal" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Incredibly powerful set for characters with Ultimates and or
                    Skills which target a single ally and who work well in teams
                    with a sole damage dealer who can benefit from CRIT DMG.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <strong>Rainbow set</strong> - if you have great pieces
                      with Speed from other sets, you can use them here.
                    </li>
                  </ul>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>3</p>
                  </div>
                  <HSRRelicSet
                    name="Messenger Traversing Hackerspace"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Good team wide supportive option for characters with
                    Ultimates which target allies. With this set equipped
                    Ultimates are best timed carefully to ensure the Speed buff
                    is active on key members for as long as possible.
                  </p>
                </div>
              </>
            </div>
            <h6>Special Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet name="Eagle of Twilight Line" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Allows characters with accessible Ultimates to reach higher
                    Speed (and cycle) breakpoints compared to just stacking
                    Speed. Strongest on characters with powerful ultimate
                    related effects or who can access their ultimate at least
                    once every 3 (but ideally 2) actions. Particularly strong
                    when combined with other action advancement effects.
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'tingyun' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet name="Sacerdos' Relived Ordeal" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Incredibly powerful set for characters with Ultimates and or
                    Skills which target a single ally and who work well in teams
                    with a sole damage dealer who can benefit from CRIT DMG.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <HSRRelicSet
                    name="Messenger Traversing Hackerspace"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Good team wide supportive option for characters with
                    Ultimates which target allies. With this set equipped
                    Ultimates are best timed carefully to ensure the Speed buff
                    is active on key members for as long as possible.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>3</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Musketeer of Wild Wheat"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[ATK +12%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Prisoner in Deep Confinement"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[ATK +12%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="The Wind-Soaring Valorous"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[ATK +12%]</span>
                    </li>
                  </ul>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>4</p>
                  </div>
                  <HSRRelicSet name="Musketeer of Wild Wheat" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Accessible alternative to using 2P SPD + 2P ATK, assuming it
                    has strong sub-stats.
                  </p>
                </div>
              </>
            </div>
            <h6>Special Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet name="Eagle of Twilight Line" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Allows characters with accessible Ultimates to reach higher
                    Speed (and cycle) breakpoints compared to just stacking
                    Speed. Strongest on characters with powerful ultimate
                    related effects or who can access their ultimate at least
                    once every 3 (but ideally 2) actions. Particularly strong
                    when combined with other action advancement effects.
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'bronya' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet name="Sacerdos' Relived Ordeal" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Incredibly powerful set for characters with Ultimates and or
                    Skills which target a single ally and who work well in teams
                    with a sole damage dealer who can benefit from CRIT DMG.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <strong>Rainbow set</strong> - if you have great pieces
                      with Speed from other sets, you can use them here.
                    </li>
                  </ul>
                </div>
              </>
            </div>
            <h6>Special Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet
                    name="Messenger Traversing Hackerspace"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Good team wide supportive option for characters with
                    Ultimates which target allies. With this set equipped
                    Ultimates are best timed carefully to ensure the Speed buff
                    is active on key members for as long as possible.{' '}
                    <strong>
                      When playing Bronya according to the -1 Speed build
                      exercise caution when using this set bonus as it can ruin
                      your Speed tuning unless carefully managed (challenging).
                    </strong>
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet name="Eagle of Twilight Line" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Allows characters with accessible Ultimates to reach higher
                    Speed (and cycle) breakpoints compared to just stacking
                    Speed. Strongest on characters with powerful ultimate
                    related effects or who can access their ultimate at least
                    once every 3 (but ideally 2) actions. Particularly strong
                    when combined with other action advancement effects.{' '}
                    <strong>
                      When playing Bronya according to the -1 Speed build
                      exercise caution when using this set bonus as it can ruin
                      your Speed tuning unless carefully managed (challenging).
                    </strong>
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'asta' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet
                    name="Messenger Traversing Hackerspace"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Good team wide supportive option for characters with
                    Ultimates which target allies. With this set equipped
                    Ultimates are best timed carefully to ensure the Speed buff
                    is active on key members for as long as possible.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <strong>Rainbow set</strong> - if you have great pieces
                      with Speed from other sets, you can use them here.
                    </li>
                  </ul>
                </div>
              </>
            </div>
            <h6>Special Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet name="Eagle of Twilight Line" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Allows characters with accessible Ultimates to reach higher
                    Speed (and cycle) breakpoints compared to just stacking
                    Speed. Strongest on characters with powerful ultimate
                    related effects or who can access their ultimate at least
                    once every 3 (but ideally 2) actions. Particularly strong
                    when combined with other action advancement effects.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet
                    name="Watchmaker, Master of Dream Machinations"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Incredible team-wide supportive set for Break-focused teams.
                    Most optimally played on the character in the team with the
                    most frequent access to an Ultimate which buffs allies to
                    ensure strong uptime. In teams with particularly fast
                    Break-focused damage dealers, equipping this to multiple
                    Supports for better uptime may be advised.{' '}
                    <strong>
                      Asta is sometimes played in Break Damage focused teams.
                      Whe she is - and only then - this set is a strong option
                      for her.
                    </strong>
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'hanya' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet
                    name="Messenger Traversing Hackerspace"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Good team wide supportive option for characters with
                    Ultimates which target allies. With this set equipped
                    Ultimates are best timed carefully to ensure the Speed buff
                    is active on key members for as long as possible.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <HSRRelicSet name="Sacerdos' Relived Ordeal" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Incredibly powerful set for characters with Ultimates and or
                    Skills which target a single ally and who work well in teams
                    with a sole damage dealer who can benefit from CRIT DMG.{' '}
                    <strong>
                      Hanya can only apply the bonus of this set with her
                      Ultimate which makes applying and maintaining it more
                      challenging for her than others.
                    </strong>
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>3</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <strong>Rainbow set</strong> - if you have great pieces
                      with Speed from other sets, you can use them here.
                    </li>
                  </ul>
                </div>
              </>
            </div>
            <h6>Special Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet name="Eagle of Twilight Line" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Allows characters with accessible Ultimates to reach higher
                    Speed (and cycle) breakpoints compared to just stacking
                    Speed. Strongest on characters with powerful ultimate
                    related effects or who can access their ultimate at least
                    once every 3 (but ideally 2) actions. Particularly strong
                    when combined with other action advancement effects.
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'yukong' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Yukong has no dedicated 4P Relic set that is ideal for her.
                    Mix and match the 2P Bonuses from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <strong>Rainbow set</strong> - if you have great pieces
                      with Speed from other sets, you can use them here.
                    </li>
                  </ul>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'sunday' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet name="Sacerdos' Relived Ordeal" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Best set for Sunday's most common build (-1 Speed) and a
                    huge asset to your carry. Aim to get this set if possible
                    unless playing Hyperspeed.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <strong>Rainbow set</strong> - if you have great pieces
                      with Speed from other sets, you can use them here.
                    </li>
                  </ul>
                </div>
              </>
            </div>
            <h6>Special Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet name="Eagle of Twilight Line" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Allows characters with accessible ultimates to reach higher
                    Speed (and cycle) breakpoints compared to just stacking
                    Speed. Strongest on characters with powerful Ultimate
                    related effects or who can access their ultimate at least
                    once every 3 (but ideally 2) actions. Particularly strong
                    when combined with other action advancement effects. If
                    playing Sunday according to the -1 Speed build, exercise
                    caution when using this set as it can ruin your Speed tuning
                    unless carefully managed (challenging).{' '}
                    <strong>
                      Only used with Hyperspeed Sunday to get more actions.
                    </strong>
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet
                    name="Messenger Traversing Hackerspace"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Good team-wide supportive option for characters with
                    Ultimates which target allies. With this set equipped
                    ultimates are best timed carefully to ensure the Speed buff
                    is active on key members for as long as possible.{' '}
                    <strong>
                      If playing Sunday according to the -1 Speed build,
                      exercise caution when using this set as it can ruin your
                      Speed tuning unless carefully managed (challenging).
                    </strong>
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {/* Amplifiers - debuffers */}
        {slug === 'pela' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet name="Eagle of Twilight Line" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Allows characters with accessible Ultimates to reach higher
                    Speed (and cycle) breakpoints compared to just stacking
                    Speed. Strongest on characters with powerful ultimate
                    related effects or who can access their ultimate at least
                    once every 3 (but ideally 2) actions. Particularly strong
                    when combined with other action advancement effects.
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <strong>Rainbow set</strong> - if you have great pieces
                      with Speed from other sets, you can use them here.
                    </li>
                  </ul>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'silver-wolf' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <strong>Rainbow set</strong> - if you have great pieces
                      with Speed from other sets, you can use them here.
                    </li>
                  </ul>
                </div>
              </>
            </div>
            <h6>Special Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet name="Genius of Brilliant Stars" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Strong pure and Break damage option when facing off against
                    enemies with Quantum weakness - especially powerful when
                    combined with other sources of DEF% reduction. Most suitable
                    for Quantum damage dealers as they are most likely to be up
                    against Quantum Weak enemies.{' '}
                    <strong>
                      Silver Wolf can implant the Quantum Weakness, making this
                      sets full power far more consistent. Primarily an option
                      for those looking to boost Silver Wolf's Break or direct
                      damage potential.
                    </strong>
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet name="Eagle of Twilight Line" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Allows characters with accessible Ultimates to reach higher
                    Speed (and cycle) breakpoints compared to just stacking
                    Speed. Strongest on characters with powerful ultimate
                    related effects or who can access their ultimate at least
                    once every 3 (but ideally 2) actions. Particularly strong
                    when combined with other action advancement effects.
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'jiaoqiu' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <HSRRelicSet name="Eagle of Twilight Line" compactMode />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Allows characters with accessible Ultimates to reach higher
                    Speed (and cycle) breakpoints compared to just stacking
                    Speed. Strongest on characters with powerful ultimate
                    related effects or who can access their ultimate at least
                    once every 3 (but ideally 2) actions. Particularly strong
                    when combined with other action advancement effects.{' '}
                    <strong>
                      The additional actions from this set are particularly
                      powerful when playing Jiaoqiu alongside Acheron who
                      benefits from the increased debuff application they
                      provide.
                    </strong>
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>2</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    If you can't put together a top recommended 4P set with
                    satisfactory sub/main stats, mix and match the 2P Bonuses
                    from the following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Pioneer Diver of Dead Waters"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">
                        [DMG to debuffed enemies +12%]
                      </span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Firesmith of Lava-Forging"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Fire DMG +10%]</span>
                    </li>
                  </ul>
                </div>
              </>
            </div>
            <h6>Special Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet
                    name="Prisoner in Deep Confinement"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Exceptional damage set for DoT-focused characters that play
                    within teams that can consistently apply and maintain 3
                    DoT's to enemies. Becomes more powerful when combined with
                    other sources of DEF% reduction.{' '}
                    <strong>A good option for E2+ Jiaoqiu.</strong>
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {slug === 'guinaifen' && (
          <>
            <h6>Best Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <div className={`percentage`}>
                    <p>1</p>
                  </div>
                  <div className="double-set">
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                      comment="2PC"
                    />
                    <>
                      <div className="flex-placeholder">Flex</div>
                    </>
                  </div>
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Guinaifen has no dedicated supportive 4P Relic set that is
                    ideal for her. Mix and match the 2P Bonuses from the
                    following instead:
                  </p>
                  <ul className="with-sets">
                    <li>
                      <HSRRelicSet
                        name="Sacerdos' Relived Ordeal"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Speed +6%]</span> (Recommended)
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Pioneer Diver of Dead Waters"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">
                        [DMG to debuffed enemies +12%]
                      </span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Firesmith of Lava-Forging"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[Fire DMG +10%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Musketeer of Wild Wheat"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[ATK +12%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="Prisoner in Deep Confinement"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[ATK +12%]</span>
                    </li>
                    <li>
                      <HSRRelicSet
                        name="The Wind-Soaring Valorous"
                        compactMode
                        smol
                      />{' '}
                      <span className="value">[ATK +12%]</span>
                    </li>
                  </ul>
                </div>
              </>
            </div>
            <h6>Special Relic Sets</h6>
            <div className="detailed-cones moc extra planar">
              <>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet
                    name="Prisoner in Deep Confinement"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Exceptional damage set for DoT-focused characters that play
                    within teams that can consistently apply and maintain 3
                    DoT's to enemies. Becomes more powerful when combined with
                    other sources of DEF% reduction.{' '}
                    <strong>
                      Best set for a damage-focused Guinaifen if supportive 2P
                      bonuses and sub stats aren't a priority.
                    </strong>
                  </p>
                </div>
                <div className={`single-cone with-notes ${element}`}>
                  <HSRRelicSet
                    name="Iron Cavalry Against the Scourge"
                    compactMode
                  />
                </div>
                <div className={`information ${element}`}>
                  <p>
                    Top damage-focused option for characters that play within
                    teams that leverage Super Break or deal predominantly
                    Break/Super Break damage themselves. Becomes even stronger
                    with additional sources of DEF% reduction.{' '}
                    <strong>
                      Should only be used when playing Guinaifen in Super Break
                      teams where she is granted the ability to do so by another
                      teammate.
                    </strong>
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        <h6>Best Planetary Sets</h6>
        <div className="detailed-cones moc extra planar">
          {/* Sustain */}
          {(slug === 'aventurine' || slug === 'fu-xuan') && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <div className={`percentage`}>
                  <p>1</p>
                </div>
                <HSRRelicSet name="Broken Keel" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  An option for supports stacking Effect RES, while also
                  increasing the entire team's CRIT DMG. Requires at least 30%
                  Effect RES to activate.
                </p>
              </div>
              <p className="with-margin-top">
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Fleet of the Ageless" compactMode smol /> -{' '}
                  Ideal for supporting teams that scale on ATK.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Lushaka, the Sunken Seas"
                    compactMode
                    smol
                  />{' '}
                  - Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </li>
              </ul>
            </>
          )}
          {(slug === 'gepard' || slug === 'trailblazer-preservation') && (
            <>
              <p className="with-margin-top">
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Broken Keel" compactMode smol /> - An
                  option for supports stacking Effect RES, while also increasing
                  the entire team's CRIT DMG. Requires at least 30% Effect RES
                  to activate.
                </li>
                <li>
                  <HSRRelicSet name="Fleet of the Ageless" compactMode smol /> -{' '}
                  Ideal for supporting teams that scale on ATK.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Lushaka, the Sunken Seas"
                    compactMode
                    smol
                  />{' '}
                  - Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </li>
              </ul>
            </>
          )}
          {slug === 'march-7th' && (
            <>
              <p className="with-margin-top">
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Broken Keel" compactMode smol /> - An
                  option for supports stacking Effect RES, while also increasing
                  the entire team's CRIT DMG. Requires at least 30% Effect RES
                  to activate.
                </li>
                <li>
                  <HSRRelicSet name="Fleet of the Ageless" compactMode smol /> -{' '}
                  Ideal for supporting teams that scale on ATK.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Lushaka, the Sunken Seas"
                    compactMode
                    smol
                  />{' '}
                  - Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Belobog of the Architects"
                    compactMode
                    smol
                  />{' '}
                  - Hyper specialized but strong option for Defense scaling
                  characters that also aim to stack a high amount of Effect Hit
                  Rating. March 7th builds both a high amount of Effect Hit
                  Rating to land her freeze's and Defense to improve her shields
                  making this a solid option if maxing defense is your primary
                  goal.
                </li>
              </ul>
            </>
          )}
          {slug === 'luocha' && (
            <>
              <p className="with-margin-top">
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Broken Keel" compactMode smol /> - An
                  option for supports stacking Effect RES, while also increasing
                  the entire team's CRIT DMG. Requires at least 30% Effect RES
                  to activate.
                </li>
                <li>
                  <HSRRelicSet name="Fleet of the Ageless" compactMode smol /> -{' '}
                  Ideal for supporting teams that scale on ATK.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Lushaka, the Sunken Seas"
                    compactMode
                    smol
                  />{' '}
                  - Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </li>
              </ul>
            </>
          )}
          {(slug === 'lingsha' || slug === 'gallagher') && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <div className={`percentage`}>
                  <p>1</p>
                </div>
                <HSRRelicSet name="Forge of the Kalpagni Lantern" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Powerful Break-focused set, primarily for Fire characters.
                  Useful both for Break characters and Break scaling Supports
                  and Sustains thanks to its massive Break Effect% buff and
                  permanent 6% Speed boost.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className={`percentage`}>
                  <p>2</p>
                </div>
                <HSRRelicSet name="Talia: Kingdom of Banditry" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Strong Break-focused planar set suitable for all Break scaling
                  characters without the conditional requirements of Forge of
                  Kalpagni - assuming the wearer can reach the 145 Speed
                  requirement.
                </p>
              </div>
            </>
          )}
          {(slug === 'lynx' ||
            slug === 'huohuo' ||
            slug === 'bailu' ||
            slug === 'natasha') && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <div className={`percentage`}>
                  <p>1</p>
                </div>
                <HSRRelicSet name="Fleet of the Ageless" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>Ideal for supporting teams that scale on ATK.</p>
              </div>
              <p className="with-margin-top">
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Broken Keel" compactMode smol /> - An
                  option for supports stacking Effect RES, while also increasing
                  the entire team's CRIT DMG. Requires at least 30% Effect RES
                  to activate.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Lushaka, the Sunken Seas"
                    compactMode
                    smol
                  />{' '}
                  - Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </li>
              </ul>
            </>
          )}
          {/* Amplifiers */}
          {slug === 'robin' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <div className={`percentage`}>
                  <p>1</p>
                </div>
                <HSRRelicSet name="Sprightly Vonwacq" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredibly potent option for buff/debuff based characters. The
                  advance from this set allows the wearer to apply their
                  buffs/debuffs before the rest of the team acts, accelerate
                  rotations, allows access to ultimates closer to the start of
                  the fight and also grants Energy Regen - a valuable stat for
                  many supports.
                </p>
              </div>
              <p className="with-margin-top">
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Fleet of the Ageless" compactMode smol /> -{' '}
                  Ideal for supporting teams that scale on ATK.
                </li>
                <li>
                  <HSRRelicSet name="Broken Keel" compactMode smol /> - An
                  option for supports stacking Effect RES, while also increasing
                  the entire team's CRIT DMG. Requires at least 30% Effect RES
                  to activate.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Lushaka, the Sunken Seas"
                    compactMode
                    smol
                  />{' '}
                  - Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </li>
              </ul>
            </>
          )}
          {slug === 'ruan-mei' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <div className={`percentage`}>
                  <p>1</p>
                </div>
                <HSRRelicSet name="Sprightly Vonwacq" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredibly potent option for buff/debuff based characters. The
                  advance from this set allows the wearer to apply their
                  buffs/debuffs before the rest of the team acts, accelerate
                  rotations, allows access to ultimates closer to the start of
                  the fight and also grants Energy Regen - a valuable stat for
                  many supports.
                </p>
              </div>
              <p className="with-margin-top">
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Broken Keel" compactMode smol /> - An
                  option for supports stacking Effect RES, while also increasing
                  the entire team's CRIT DMG. Requires at least 30% Effect RES
                  to activate.
                </li>
                <li>
                  <HSRRelicSet name="Fleet of the Ageless" compactMode smol /> -{' '}
                  Ideal for supporting teams that scale on ATK.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Lushaka, the Sunken Seas"
                    compactMode
                    smol
                  />{' '}
                  - Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </li>
              </ul>
            </>
          )}
          {slug === 'trailblazer-harmony' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <div className={`percentage`}>
                  <p>1</p>
                </div>
                <HSRRelicSet name="Talia: Kingdom of Banditry" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Strong Break-focused planar set suitable for all Break-scaling
                  characters without the conditional requirements of Forge of
                  Kalpagni, assuming the wearer can reach the 145 Speed
                  requirement.
                </p>
              </div>
              <p className="with-margin-top">
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Broken Keel" compactMode smol /> - An
                  option for supports stacking Effect RES, while also increasing
                  the entire team's CRIT DMG. Requires at least 30% Effect RES
                  to activate.
                </li>
                <li>
                  <HSRRelicSet name="Fleet of the Ageless" compactMode smol /> -{' '}
                  Ideal for supporting teams that scale on ATK.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Lushaka, the Sunken Seas"
                    compactMode
                    smol
                  />{' '}
                  - Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </li>
              </ul>
            </>
          )}
          {(slug === 'sparkle' ||
            slug === 'bronya' ||
            slug === 'hanya' ||
            slug === 'yukong') && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <div className={`percentage`}>
                  <p>1</p>
                </div>
                <HSRRelicSet name="Broken Keel" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  An option for supports stacking Effect RES, while also
                  increasing the entire team's CRIT DMG. Requires at least 30%
                  Effect RES to activate.
                </p>
              </div>
              <p className="with-margin-top">
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Fleet of the Ageless" compactMode smol /> -{' '}
                  Ideal for supporting teams that scale on ATK.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Lushaka, the Sunken Seas"
                    compactMode
                    smol
                  />{' '}
                  - Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </li>
              </ul>
            </>
          )}
          {(slug === 'tingyun' || slug === 'asta') && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <div className={`percentage`}>
                  <p>1</p>
                </div>
                <HSRRelicSet name="Sprightly Vonwacq" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredibly potent option for buff/debuff based characters. The
                  advance from this set allows the wearer to apply their
                  buffs/debuffs before the rest of the team acts, accelerate
                  rotations, allows access to ultimates closer to the start of
                  the fight and also grants Energy Regen - a valuable stat for
                  many supports.
                </p>
              </div>
              <p className="with-margin-top">
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Fleet of the Ageless" compactMode smol /> -{' '}
                  Ideal for supporting teams that scale on ATK.
                </li>
                <li>
                  <HSRRelicSet name="Broken Keel" compactMode smol /> - An
                  option for supports stacking Effect RES, while also increasing
                  the entire team's CRIT DMG. Requires at least 30% Effect RES
                  to activate.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Lushaka, the Sunken Seas"
                    compactMode
                    smol
                  />{' '}
                  - Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </li>
              </ul>
            </>
          )}
          {slug === 'sunday' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <div className={`percentage`}>
                  <p>1</p>
                </div>
                <HSRRelicSet name="Lushaka, the Sunken Seas" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </p>
              </div>
              <p className="with-margin-top">
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.{' '}
                <strong>
                  Energy Regen Planars increase the consistency of achieving a
                  3-action Ultimate with Sunday when playing without his
                  Signature - something he very much wants to do giving them an
                  edge over non-ER options.
                </strong>
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Broken Keel" compactMode smol /> - An
                  option for supports stacking Effect RES, while also increasing
                  the entire team's CRIT DMG. Requires at least 30% Effect RES
                  to activate.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet name="Fleet of the Ageless" compactMode smol /> -
                  Ideal for supporting teams that scale on ATK.
                </li>
              </ul>
            </>
          )}
          {/* Amplifiers - debuffers */}
          {(slug === 'pela' || slug === 'silver-wolf') && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <div className={`percentage`}>
                  <p>1</p>
                </div>
                <HSRRelicSet name="Broken Keel" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  An option for supports stacking Effect RES, while also
                  increasing the entire team's CRIT DMG. Requires at least 30%
                  Effect RES to activate.
                </p>
              </div>
              <p className="with-margin-top">
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Fleet of the Ageless" compactMode smol /> -{' '}
                  Ideal for supporting teams that scale on ATK.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Lushaka, the Sunken Seas"
                    compactMode
                    smol
                  />{' '}
                  - Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </li>
              </ul>
            </>
          )}
          {slug === 'jiaoqiu' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <div className={`percentage`}>
                  <p>1</p>
                </div>
                <HSRRelicSet name="Sprightly Vonwacq" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredibly potent option for buff/debuff based characters. The
                  advance from this set allows the wearer to apply their
                  buffs/debuffs before the rest of the team acts, accelerate
                  rotations, allows access to ultimates closer to the start of
                  the fight and also grants Energy Regen - a valuable stat for
                  many supports.
                </p>
              </div>
              <p className="with-margin-top">
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Fleet of the Ageless" compactMode smol /> -{' '}
                  Ideal for supporting teams that scale on ATK.
                </li>
                <li>
                  <HSRRelicSet name="Broken Keel" compactMode smol /> - An
                  option for supports stacking Effect RES, while also increasing
                  the entire team's CRIT DMG. Requires at least 30% Effect RES
                  to activate.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Lushaka, the Sunken Seas"
                    compactMode
                    smol
                  />{' '}
                  - Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </li>
              </ul>
            </>
          )}
          {slug === 'guinaifen' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <div className={`percentage`}>
                  <p>1</p>
                </div>
                <HSRRelicSet name="Fleet of the Ageless" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>Ideal for supporting teams that scale on ATK.</p>
              </div>
              <p className="with-margin-top">
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Broken Keel" compactMode smol /> - An
                  option for supports stacking Effect RES, while also increasing
                  the entire team's CRIT DMG. Requires at least 30% Effect RES
                  to activate.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Lushaka, the Sunken Seas"
                    compactMode
                    smol
                  />{' '}
                  - Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </li>
              </ul>
            </>
          )}
        </div>
        <h6>Special Planetary Sets</h6>
        <div className="detailed-cones moc extra planar">
          {/* No Special */}
          {(slug === 'fu-xuan' ||
            slug === 'trailblazer-preservation' ||
            slug === 'lynx' ||
            slug === 'natasha' ||
            slug === 'robin' ||
            slug === 'asta' ||
            slug === 'tingyun' ||
            slug === 'bailu' ||
            slug === 'luocha') && (
            <>
              <p>-</p>
            </>
          )}
          {/* Sustain */}
          {slug === 'aventurine' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet name="Inert Salsotto" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Powerful option for CRIT based characters that deal a large
                  portion of their damage through their ultimate or follow-up
                  attacks.{' '}
                  <strong>
                    An option for those looking to push their Aventurine's
                    damage higher and aren't interested in a more supportive
                    Planar set bonus.
                  </strong>
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet
                  name="Duran, Dynasty of Running Wolves"
                  compactMode
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredibly potent planar set for Follow-Up attackers that
                  either launch Follow-Up attacks incredibly frequently or are
                  played in teams with other Follow-Up attackers which do.
                  Relies on reaching maximum stacks as soon as possible but once
                  there is incredibly potent.{' '}
                  <strong>
                    Aventurine is frequently played in teams featuring multiple
                    Follow-Up attackers which attack incredibly frequently -
                    this plus his own Follow-Ups make this another strong but
                    conditional pure damage option to consider.
                  </strong>
                </p>
              </div>
            </>
          )}
          {slug === 'gepard' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet name="Belobog of the Architects" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Hyper specialized but strong option for Defense scaling
                  characters that also aim to stack a high amount of Effect Hit
                  Rating.{' '}
                  <strong>
                    Only recommended for those building Gepard with a focus on
                    getting enough Effect Hit Rating to fully active its effect.
                  </strong>
                </p>
              </div>
            </>
          )}
          {slug === 'lingsha' && (
            <>
              <p>
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Sprightly Vonwacq" compactMode smol /> -{' '}
                  Incredibly potent option for buff/debuff based characters. The
                  advance from this set allows the wearer to apply their
                  buffs/debuffs before the rest of the team acts, accelerate
                  rotations, allows access to ultimates closer to the start of
                  the fight and also grants Energy Regen - a valuable stat for
                  many supports.
                </li>
                <li>
                  <HSRRelicSet name="Fleet of the Ageless" compactMode smol /> -{' '}
                  Ideal for supporting teams that scale on ATK.
                </li>
                <li>
                  <HSRRelicSet name="Broken Keel" compactMode smol /> - An
                  option for supports stacking Effect RES, while also increasing
                  the entire team's CRIT DMG. Requires at least 30% Effect RES
                  to activate.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Lushaka, the Sunken Seas"
                    compactMode
                    smol
                  />{' '}
                  - Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </li>
              </ul>
            </>
          )}
          {slug === 'gallagher' && (
            <>
              <p>
                The following Planars are all usable and have their own strength
                and weaknesses when looking for team-based supportive effects.
              </p>
              <ul className="with-sets">
                <li>
                  <HSRRelicSet name="Fleet of the Ageless" compactMode smol /> -{' '}
                  Ideal for supporting teams that scale on ATK.
                </li>
                <li>
                  <HSRRelicSet name="Broken Keel" compactMode smol /> - An
                  option for supports stacking Effect RES, while also increasing
                  the entire team's CRIT DMG. Requires at least 30% Effect RES
                  to activate.
                </li>
                <li>
                  <HSRRelicSet
                    name="Penacony, Land of the Dreams"
                    compactMode
                    smol
                  />{' '}
                  - Top option for supporting teams with the same Element as the
                  wearer, and for its Energy Regen.
                </li>
                <li>
                  <HSRRelicSet
                    name="Lushaka, the Sunken Seas"
                    compactMode
                    smol
                  />{' '}
                  - Top option for Supporting a single ATK-scaling DPS, and for
                  its Energy Regen.
                </li>
              </ul>
            </>
          )}
          {(slug === 'march-7th' || slug === 'huohuo') && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet name="Sprightly Vonwacq" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredibly potent option for buff/debuff based characters. The
                  advance from this set allows the wearer to apply their
                  buffs/debuffs before the rest of the team acts, accelerate
                  rotations, allows access to ultimates closer to the start of
                  the fight and also grants Energy Regen - a valuable stat for
                  many supports.
                </p>
              </div>
            </>
          )}
          {/* Amplifiers */}
          {slug === 'ruan-mei' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet name="Talia: Kingdom of Banditry" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Strong Break-focused planar set suitable for all Break-scaling
                  characters without the conditional requirements of Forge of
                  Kalpagni, assuming the wearer can reach the 145 Speed
                  requirement.{' '}
                  <strong>
                    Unless you need the Break Effect% from this Planar to
                    satisfy Ruan's thresholds, other Planars may outperform it.
                  </strong>
                </p>
              </div>
            </>
          )}
          {(slug === 'sparkle' || slug === 'bronya' || slug === 'hanya') && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet name="Sprightly Vonwacq" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredibly potent option for buff/debuff based characters. The
                  advance from this set allows the wearer to apply their
                  buffs/debuffs before the rest of the team acts, accelerate
                  rotations, allows access to ultimates closer to the start of
                  the fight and also grants Energy Regen - a valuable stat for
                  many supports.
                </p>
              </div>
            </>
          )}
          {slug === 'sunday' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet name="Sprightly Vonwacq" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Only useful when playing Hyperspeed Sunday to achieve a faster
                  start and/or more actions in the first cycle/portion of a
                  fight. When playing -1 Sunday using this will only ruin your
                  initial Speed tuning.
                </p>
              </div>
            </>
          )}
          {slug === 'trailblazer-harmony' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet name="Forge of the Kalpagni Lantern" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Powerful Break-focused set, primarily for Fire characters.
                  Useful both for Break characters and Break scaling Supports
                  and Sustains thanks to its massive Break Effect% buff and
                  permanent 6% Speed boost.{' '}
                  <strong>
                    Despite not being a Fire character, Harmony Trailblazer is
                    frequently played along Firefly who is and who can implant
                    Fire weakness, making it viable and strong option to use.
                  </strong>
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet name="Sprightly Vonwacq" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredibly potent option for buff/debuff based characters. The
                  advance from this set allows the wearer to apply their
                  buffs/debuffs before the rest of the team acts, accelerate
                  rotations, allows access to ultimates closer to the start of
                  the fight and also grants Energy Regen - a valuable stat for
                  many supports.
                </p>
              </div>
            </>
          )}
          {slug === 'yukong' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet name="Inert Salsotto" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Powerful option for CRIT based characters that deal a large
                  portion of their damage through their ultimate or follow-up
                  attacks.{' '}
                  <strong>
                    An option for those looking to push their Yukong's damage
                    higher and aren't interested in a more supportive Planar
                    bonus.
                  </strong>
                </p>
              </div>
            </>
          )}
          {/* Amplifiers - debuffers */}
          {slug === 'pela' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet name="Sprightly Vonwacq" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredibly potent option for buff/debuff based characters. The
                  advance from this set allows the wearer to apply their
                  buffs/debuffs before the rest of the team acts, accelerate
                  rotations, allows access to ultimates closer to the start of
                  the fight and also grants Energy Regen - a valuable stat for
                  many supports.
                </p>
              </div>
            </>
          )}
          {slug === 'silver-wolf' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet
                  name="Pan-Cosmic Commercial Enterprise"
                  compactMode
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Option for Effect Hit Rate focused characters searching for
                  additional accessible source of Effect Hit Rating. When
                  factoring the bonus Effect Hit Rating, alongside the ATK%
                  boost can be a competitive damage option as well.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet name="Sprightly Vonwacq" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredibly potent option for buff/debuff based characters. The
                  advance from this set allows the wearer to apply their
                  buffs/debuffs before the rest of the team acts, accelerate
                  rotations, allows access to ultimates closer to the start of
                  the fight and also grants Energy Regen - a valuable stat for
                  many supports.
                </p>
              </div>
            </>
          )}
          {slug === 'jiaoqiu' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet
                  name="Pan-Cosmic Commercial Enterprise"
                  compactMode
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Option for Effect Hit Rate focused characters searching for
                  additional accessible source of Effect Hit Rating. When
                  factoring the bonus Effect Hit Rating, alongside the ATK%
                  boost can be a competitive damage option as well.
                </p>
              </div>
            </>
          )}
          {slug === 'guinaifen' && (
            <>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet name="Firmament Frontline: Glamoth" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  A generic and strong option for damage characters that scale
                  best from the combiantion of DMG% and ATK% and who can
                  build/reach a large amount of Speed (160 preferrably).{' '}
                  <strong>
                    A top option for those looking to elevate their Guinaifen's
                    damage instead of using supportive options.
                  </strong>
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet name="Forge of the Kalpagni Lantern" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Powerful Break-focused set, primarily for Fire characters.
                  Useful both for Break characters and Break scaling Supports
                  and Sustains thanks to its massive Break Effect% buff and
                  permanent 6% Speed boost.{' '}
                  <strong>
                    Should only be used when playing Guinaifen within Super
                    Break teams where she is granted the ability to do so by
                    another teammate.
                  </strong>
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <HSRRelicSet name="Sprightly Vonwacq" compactMode />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredibly potent option for buff/debuff based characters. The
                  advance from this set allows the wearer to apply their
                  buffs/debuffs before the rest of the team acts, accelerate
                  rotations, allows access to ultimates closer to the start of
                  the fight and also grants Energy Regen - a valuable stat for
                  many supports.
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
