import React from 'react';
import { HSRCharacter } from '../common/components/hsr-character';
import { Row, Col } from 'react-bootstrap';
import { HSRRelicSet } from '../common/components/hsr-relic-set';
import { HSRStat } from '../common/components/hsr-stat';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ProfileHarmonyDPS: React.FC = () => {
  return (
    <>
      <div className={`content-header Imaginary`}>
        <FontAwesomeIcon icon={faSquare} width="18" /> Super Break and Break
        Effect
      </div>
      <Row xs={1} xl={1} className="dps-comparison">
        <Col>
          <div className="box">
            <h5>
              Trailblazer Imaginary - Super Break dmg and Break Effect buff
            </h5>
            <div className="simulation-container">
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="trailblazer-harmony" mode="icon" />
                  <span className="eidolon">E6</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data smaller">
                      <span className="dps with-eidolon">
                        &nbsp;57,110 Super Break dmg
                      </span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data smaller">
                      <span className="dps with-eidolon">
                        &nbsp;80% BE buff
                      </span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="trailblazer-harmony" mode="icon" />
                  <span className="eidolon">E5</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '80%' }}
                    ></div>
                    <div className="data smaller">
                      <span className="dps with-eidolon">
                        &nbsp;45,688 Super Break dmg
                      </span>
                      <span className="percent">80%</span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data smaller">
                      <span className="dps with-eidolon">
                        &nbsp;80% BE buff
                      </span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="trailblazer-harmony" mode="icon" />
                  <span className="eidolon">E4</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '80%' }}
                    ></div>
                    <div className="data smaller">
                      <span className="dps with-eidolon">
                        &nbsp;45,688 Super Break dmg
                      </span>
                      <span className="percent">80%</span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{ width: '94.67%' }}
                    ></div>
                    <div className="data smaller">
                      <span className="dps with-eidolon">
                        &nbsp;76% BE buff
                      </span>
                      <span className="percent">94.67%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="trailblazer-harmony" mode="icon" />
                  <span className="eidolon">E3</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '80%' }}
                    ></div>
                    <div className="data smaller">
                      <span className="dps with-eidolon">
                        &nbsp;45,688 Super Break dmg
                      </span>
                      <span className="percent">80%</span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{ width: '40%' }}
                    ></div>
                    <div className="data smaller">
                      <span className="dps with-eidolon">
                        &nbsp;30% BE buff
                      </span>
                      <span className="percent">40%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="trailblazer-harmony" mode="icon" />
                  <span className="eidolon">E2</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '80%' }}
                    ></div>
                    <div className="data smaller">
                      <span className="dps with-eidolon">
                        &nbsp;45,688 Super Break dmg
                      </span>
                      <span className="percent">80%</span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{ width: '40%' }}
                    ></div>
                    <div className="data smaller">
                      <span className="dps with-eidolon">
                        &nbsp;30% BE buff
                      </span>
                      <span className="percent">40%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="trailblazer-harmony" mode="icon" />
                  <span className="eidolon">E1</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '80%' }}
                    ></div>
                    <div className="data smaller">
                      <span className="dps with-eidolon">
                        &nbsp;45,688 Super Break dmg
                      </span>
                      <span className="percent">80%</span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{ width: '40%' }}
                    ></div>
                    <div className="data smaller">
                      <span className="dps with-eidolon">
                        &nbsp;30% BE buff
                      </span>
                      <span className="percent">40%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="trailblazer-harmony" mode="icon" />
                  <span className="eidolon">E0</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '80%' }}
                    ></div>
                    <div className="data smaller">
                      <span className="dps with-eidolon">
                        &nbsp;45,688 Super Break dmg
                      </span>
                      <span className="percent">80%</span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{ width: '40%' }}
                    ></div>
                    <div className="data smaller">
                      <span className="dps with-eidolon">
                        &nbsp;30% BE buff
                      </span>
                      <span className="percent">40%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="section-build perf">
        <div className="build-relics perf">
          <Row xs={1} xl={2} xxl={2} className="relics">
            <Col>
              <h6 className="header">Relic Set</h6>
              <div className="relic-sets-rec">
                <HSRRelicSet
                  name="Watchmaker, Master of Dream Machinations"
                  compactMode
                />
              </div>
            </Col>
            <Col>
              <h6 className="header">Planetary Sets</h6>
              <div className="relic-sets-rec">
                <HSRRelicSet name="Talia: Kingdom of Banditry" compactMode />
              </div>
            </Col>
          </Row>
        </div>
        <h6 className="header">Relic Stats</h6>
        <div className="build-stats">
          <Row xs={1} xl={2} xxl={4} className="main-stats">
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Body</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="HP%" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Feet</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="Speed" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Planar Sphere</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="HP%" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Link Rope</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="Break Effect" />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="box sub-stats">
                <span>Substats:</span>
                <p>Break Effect (62.21%), Speed (18)</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
