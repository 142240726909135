export const MoCDuos = [
  {
    char: 'ruan-mei',
    app: 89.32,
    char_1: 'trailblazer-harmony',
    app_rate_1: '91.08%',
    avg_round_1: 8.31,
    app_flat_1: 3984,
    char_2: 'Firefly',
    app_rate_2: '65.96%',
    avg_round_2: 8.41,
    app_flat_2: 2453,
    char_3: 'Gallagher',
    app_rate_3: '50.89%',
    avg_round_3: 8.51,
    app_flat_3: 2546,
    char_4: 'Lingsha',
    app_rate_4: '37.13%',
    avg_round_4: 7.67,
    app_flat_4: 1347,
    char_5: 'Rappa',
    app_rate_5: '20.12%',
    avg_round_5: 7.56,
    app_flat_5: 1187,
    char_6: 'Himeko',
    app_rate_6: '4.5%',
    avg_round_6: 9.29,
    app_flat_6: 253,
    char_7: 'Kafka',
    app_rate_7: '3.69%',
    avg_round_7: 8.43,
    app_flat_7: 89,
    char_8: 'black-swan',
    app_rate_8: '3.54%',
    avg_round_8: 8.32,
    app_flat_8: 82,
    char_9: 'Huohuo',
    app_rate_9: '3.19%',
    avg_round_9: 8.46,
    app_flat_9: 74,
    char_10: 'Luocha',
    app_rate_10: '2.34%',
    avg_round_10: 9.09,
    app_flat_10: 69,
    char_11: 'Aventurine',
    app_rate_11: '2.25%',
    avg_round_11: 9.45,
    app_flat_11: 76,
    char_12: 'Sparkle',
    app_rate_12: '1.82%',
    avg_round_12: 9.11,
    app_flat_12: 15,
    char_13: 'Acheron',
    app_rate_13: '1.75%',
    avg_round_13: 9.25,
    app_flat_13: 14,
    char_14: 'Jiaoqiu',
    app_rate_14: '1.54%',
    avg_round_14: 8.12,
    app_flat_14: 16,
    char_15: 'imbibitor-lunae',
    app_rate_15: '1.45%',
    avg_round_15: 9.12,
    app_flat_15: 14,
    char_16: 'Boothill',
    app_rate_16: '1.19%',
    avg_round_16: 9.14,
    app_flat_16: 56,
    char_17: 'fu-xuan',
    app_rate_17: '1.15%',
    avg_round_17: 9.54,
    app_flat_17: 23,
    char_18: 'Bronya',
    app_rate_18: '0.96%',
    avg_round_18: 9.29,
    app_flat_18: 7,
    char_19: 'march-7th-swordmaster',
    app_rate_19: '0.6%',
    avg_round_19: 8.52,
    app_flat_19: 23,
    char_20: 'Pela',
    app_rate_20: '0.54%',
    avg_round_20: 10,
    app_flat_20: 2,
    char_21: 'Feixiao',
    app_rate_21: '0.51%',
    avg_round_21: 8.3,
    app_flat_21: 20,
    char_22: 'Jade',
    app_rate_22: '0.43%',
    avg_round_22: 8,
    app_flat_22: 11,
    char_23: 'Robin',
    app_rate_23: '0.4%',
    avg_round_23: 7.5,
    app_flat_23: 4,
    char_24: 'Tingyun',
    app_rate_24: '0.35%',
    avg_round_24: 8.5,
    app_flat_24: 2,
    char_25: 'topaz',
    app_rate_25: '0.35%',
    avg_round_25: 8.88,
    app_flat_25: 16,
    char_26: 'Blade',
    app_rate_26: '0.23%',
    avg_round_26: 10.0,
    app_flat_26: 4,
    char_27: 'Guinaifen',
    app_rate_27: '0.22%',
    avg_round_27: 9.67,
    app_flat_27: 10,
    char_28: 'jing-yuan',
    app_rate_28: '0.22%',
    avg_round_28: 7.6,
    app_flat_28: 5,
    char_29: 'Jingliu',
    app_rate_29: '0.18%',
    avg_round_29: 9,
    app_flat_29: 2,
    char_30: 'Bailu',
    app_rate_30: '0.17%',
    avg_round_30: 9,
    app_flat_30: 7
  },
  {
    char: 'trailblazer-harmony',
    app: 83.73,
    char_1: 'ruan-mei',
    app_rate_1: '97.16%',
    avg_round_1: 8.31,
    app_flat_1: 3984,
    char_2: 'Firefly',
    app_rate_2: '72.0%',
    avg_round_2: 8.44,
    app_flat_2: 2530,
    char_3: 'Gallagher',
    app_rate_3: '54.26%',
    avg_round_3: 8.54,
    app_flat_3: 2615,
    char_4: 'Lingsha',
    app_rate_4: '40.04%',
    avg_round_4: 7.7,
    app_flat_4: 1372,
    char_5: 'Rappa',
    app_rate_5: '22.81%',
    avg_round_5: 7.65,
    app_flat_5: 1277,
    char_6: 'Himeko',
    app_rate_6: '5.09%',
    avg_round_6: 9.33,
    app_flat_6: 282,
    char_7: 'Luocha',
    app_rate_7: '1.45%',
    avg_round_7: 9.3,
    app_flat_7: 53,
    char_8: 'Aventurine',
    app_rate_8: '1.29%',
    avg_round_8: 9.6,
    app_flat_8: 49,
    char_9: 'Boothill',
    app_rate_9: '1.07%',
    avg_round_9: 9.17,
    app_flat_9: 55,
    char_10: 'Huohuo',
    app_rate_10: '0.82%',
    avg_round_10: 8.92,
    app_flat_10: 24,
    char_11: 'Bronya',
    app_rate_11: '0.65%',
    avg_round_11: 8.86,
    app_flat_11: 7,
    char_12: 'march-7th-swordmaster',
    app_rate_12: '0.64%',
    avg_round_12: 9.24,
    app_flat_12: 31,
    char_13: 'Pela',
    app_rate_13: '0.47%',
    avg_round_13: 8.67,
    app_flat_13: 21,
    char_14: 'fu-xuan',
    app_rate_14: '0.33%',
    avg_round_14: 9.67,
    app_flat_14: 10,
    char_15: 'Asta',
    app_rate_15: '0.27%',
    avg_round_15: 9.78,
    app_flat_15: 15,
    char_16: 'Jiaoqiu',
    app_rate_16: '0.24%',
    avg_round_16: 9.17,
    app_flat_16: 10,
    char_17: 'Guinaifen',
    app_rate_17: '0.18%',
    avg_round_17: 9.22,
    app_flat_17: 9,
    char_18: 'Jade',
    app_rate_18: '0.16%',
    avg_round_18: 8,
    app_flat_18: 7,
    char_19: 'imbibitor-lunae',
    app_rate_19: '0.14%',
    avg_round_19: 10,
    app_flat_19: 3,
    char_20: 'Welt',
    app_rate_20: '0.13%',
    avg_round_20: 9.25,
    app_flat_20: 4,
    char_21: 'Bailu',
    app_rate_21: '0.1%',
    avg_round_21: 9,
    app_flat_21: 3,
    char_22: 'Kafka',
    app_rate_22: '0.09%',
    avg_round_22: 9.4,
    app_flat_22: 5,
    char_23: 'Tingyun',
    app_rate_23: '0.07%',
    avg_round_23: 8.75,
    app_flat_23: 4,
    char_24: 'Sparkle',
    app_rate_24: '0.06%',
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'Robin',
    app_rate_25: '0.06%',
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'silver-wolf',
    app_rate_26: '0.06%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'jing-yuan',
    app_rate_27: '0.06%',
    avg_round_27: 7,
    app_flat_27: 4,
    char_28: 'Acheron',
    app_rate_28: '0.06%',
    avg_round_28: 9,
    app_flat_28: 2,
    char_29: 'Lynx',
    app_rate_29: '0.05%',
    avg_round_29: 10,
    app_flat_29: 2,
    char_30: 'Gepard',
    app_rate_30: '0.02%',
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'Firefly',
    app: 60.52,
    char_1: 'trailblazer-harmony',
    app_rate_1: '99.62%',
    avg_round_1: 8.44,
    app_flat_1: 2530,
    char_2: 'ruan-mei',
    app_rate_2: '97.35%',
    avg_round_2: 8.41,
    app_flat_2: 2453,
    char_3: 'Gallagher',
    app_rate_3: '53.9%',
    avg_round_3: 8.61,
    app_flat_3: 1627,
    char_4: 'Lingsha',
    app_rate_4: '41.13%',
    avg_round_4: 7.95,
    app_flat_4: 844,
    char_5: 'Rappa',
    app_rate_5: '1.94%',
    avg_round_5: 9.0,
    app_flat_5: 34,
    char_6: 'Luocha',
    app_rate_6: '1.04%',
    avg_round_6: 9.4,
    app_flat_6: 29,
    char_7: 'Aventurine',
    app_rate_7: '0.91%',
    avg_round_7: 9.82,
    app_flat_7: 21,
    char_8: 'Himeko',
    app_rate_8: '0.77%',
    avg_round_8: 9.5,
    app_flat_8: 18,
    char_9: 'Bronya',
    app_rate_9: '0.73%',
    avg_round_9: 9,
    app_flat_9: 1,
    char_10: 'Huohuo',
    app_rate_10: '0.63%',
    avg_round_10: 9,
    app_flat_10: 7,
    char_11: 'Pela',
    app_rate_11: '0.3%',
    avg_round_11: 9.2,
    app_flat_11: 10,
    char_12: 'march-7th-swordmaster',
    app_rate_12: '0.3%',
    avg_round_12: 9.3,
    app_flat_12: 10,
    char_13: 'fu-xuan',
    app_rate_13: '0.3%',
    avg_round_13: 9.67,
    app_flat_13: 3,
    char_14: 'Asta',
    app_rate_14: '0.2%',
    avg_round_14: 9.33,
    app_flat_14: 6,
    char_15: 'Robin',
    app_rate_15: '0.13%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Jiaoqiu',
    app_rate_16: '0.09%',
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'Bailu',
    app_rate_17: '0.09%',
    avg_round_17: 9.67,
    app_flat_17: 3,
    char_18: 'Sparkle',
    app_rate_18: '0.06%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Acheron',
    app_rate_19: '0.06%',
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'Welt',
    app_rate_20: '0.06%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Kafka',
    app_rate_21: '0.05%',
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'black-swan',
    app_rate_22: '0.05%',
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'silver-wolf',
    app_rate_23: '0.05%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Lynx',
    app_rate_24: '0.03%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Xueyi',
    app_rate_25: '0.03%',
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'Feixiao',
    app_rate_26: '0.03%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Natasha',
    app_rate_27: '0.02%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Guinaifen',
    app_rate_28: '0.02%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Boothill',
    app_rate_29: '0.02%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Gepard',
    app_rate_30: '0.02%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Aventurine',
    app: 57.72,
    char_1: 'Robin',
    app_rate_1: '60.96%',
    avg_round_1: 7.64,
    app_flat_1: 1946,
    char_2: 'Feixiao',
    app_rate_2: '54.74%',
    avg_round_2: 7.6,
    app_flat_2: 1881,
    char_3: 'topaz',
    app_rate_3: '35.06%',
    avg_round_3: 7.51,
    app_flat_3: 1027,
    char_4: 'Acheron',
    app_rate_4: '33.54%',
    avg_round_4: 8.65,
    app_flat_4: 820,
    char_5: 'Jiaoqiu',
    app_rate_5: '20.1%',
    avg_round_5: 8.07,
    app_flat_5: 474,
    char_6: 'march-7th-swordmaster',
    app_rate_6: '15.77%',
    avg_round_6: 7.88,
    app_flat_6: 657,
    char_7: 'Sparkle',
    app_rate_7: '13.4%',
    avg_round_7: 8.86,
    app_flat_7: 113,
    char_8: 'Pela',
    app_rate_8: '13.37%',
    avg_round_8: 8.36,
    app_flat_8: 464,
    char_9: 'black-swan',
    app_rate_9: '10.28%',
    avg_round_9: 8.82,
    app_flat_9: 304,
    char_10: 'Moze',
    app_rate_10: '8.21%',
    avg_round_10: 7.75,
    app_flat_10: 288,
    char_11: 'Kafka',
    app_rate_11: '6.79%',
    avg_round_11: 8.91,
    app_flat_11: 198,
    char_12: 'silver-wolf',
    app_rate_12: '5.14%',
    avg_round_12: 9.1,
    app_flat_12: 162,
    char_13: 'dr-ratio',
    app_rate_13: '3.69%',
    avg_round_13: 9.42,
    app_flat_13: 61,
    char_14: 'ruan-mei',
    app_rate_14: '3.49%',
    avg_round_14: 9.45,
    app_flat_14: 76,
    char_15: 'Bronya',
    app_rate_15: '3.01%',
    avg_round_15: 8.79,
    app_flat_15: 57,
    char_16: 'trailblazer-harmony',
    app_rate_16: '1.87%',
    avg_round_16: 9.6,
    app_flat_16: 49,
    char_17: 'Tingyun',
    app_rate_17: '1.42%',
    avg_round_17: 9.59,
    app_flat_17: 31,
    char_18: 'Yunli',
    app_rate_18: '1.22%',
    avg_round_18: 9.56,
    app_flat_18: 32,
    char_19: 'imbibitor-lunae',
    app_rate_19: '1.12%',
    avg_round_19: 9.4,
    app_flat_19: 7,
    char_20: 'Jade',
    app_rate_20: '1.11%',
    avg_round_20: 9.75,
    app_flat_20: 8,
    char_21: 'Firefly',
    app_rate_21: '0.96%',
    avg_round_21: 9.82,
    app_flat_21: 21,
    char_22: 'Himeko',
    app_rate_22: '0.93%',
    avg_round_22: 9.62,
    app_flat_22: 23,
    char_23: 'jing-yuan',
    app_rate_23: '0.88%',
    avg_round_23: 9.19,
    app_flat_23: 21,
    char_24: 'Guinaifen',
    app_rate_24: '0.83%',
    avg_round_24: 9.27,
    app_flat_24: 29,
    char_25: 'Rappa',
    app_rate_25: '0.71%',
    avg_round_25: 9.31,
    app_flat_25: 23,
    char_26: 'Sampo',
    app_rate_26: '0.28%',
    avg_round_26: 9.31,
    app_flat_26: 13,
    char_27: 'Clara',
    app_rate_27: '0.25%',
    avg_round_27: 8.5,
    app_flat_27: 6,
    char_28: 'Boothill',
    app_rate_28: '0.13%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Welt',
    app_rate_29: '0.13%',
    avg_round_29: 9.5,
    app_flat_29: 2,
    char_30: 'Asta',
    app_rate_30: '0.12%',
    avg_round_30: 9.67,
    app_flat_30: 3
  },
  {
    char: 'Robin',
    app: 56.13,
    char_1: 'Feixiao',
    app_rate_1: '73.65%',
    avg_round_1: 7.62,
    app_flat_1: 2365,
    char_2: 'Aventurine',
    app_rate_2: '62.69%',
    avg_round_2: 7.64,
    app_flat_2: 1946,
    char_3: 'topaz',
    app_rate_3: '40.97%',
    avg_round_3: 7.54,
    app_flat_3: 1143,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '26.84%',
    avg_round_4: 7.84,
    app_flat_4: 972,
    char_5: 'Huohuo',
    app_rate_5: '12.19%',
    avg_round_5: 8.51,
    app_flat_5: 283,
    char_6: 'Moze',
    app_rate_6: '9.55%',
    avg_round_6: 7.64,
    app_flat_6: 318,
    char_7: 'fu-xuan',
    app_rate_7: '9.48%',
    avg_round_7: 8.25,
    app_flat_7: 234,
    char_8: 'black-swan',
    app_rate_8: '8.36%',
    avg_round_8: 8.72,
    app_flat_8: 157,
    char_9: 'Kafka',
    app_rate_9: '8.21%',
    avg_round_9: 8.75,
    app_flat_9: 159,
    char_10: 'Acheron',
    app_rate_10: '5.25%',
    avg_round_10: 8.56,
    app_flat_10: 66,
    char_11: 'Yunli',
    app_rate_11: '4.54%',
    avg_round_11: 9.0,
    app_flat_11: 109,
    char_12: 'Gallagher',
    app_rate_12: '4.37%',
    avg_round_12: 7.22,
    app_flat_12: 136,
    char_13: 'Luocha',
    app_rate_13: '3.88%',
    avg_round_13: 8.79,
    app_flat_13: 105,
    char_14: 'Jiaoqiu',
    app_rate_14: '3.65%',
    avg_round_14: 7.75,
    app_flat_14: 48,
    char_15: 'dr-ratio',
    app_rate_15: '3.64%',
    avg_round_15: 9.45,
    app_flat_15: 61,
    char_16: 'Sparkle',
    app_rate_16: '3.42%',
    avg_round_16: 9.31,
    app_flat_16: 32,
    char_17: 'Lingsha',
    app_rate_17: '3.11%',
    avg_round_17: 8.26,
    app_flat_17: 82,
    char_18: 'Bronya',
    app_rate_18: '2.7%',
    avg_round_18: 7.46,
    app_flat_18: 28,
    char_19: 'Tingyun',
    app_rate_19: '2.38%',
    avg_round_19: 9.24,
    app_flat_19: 49,
    char_20: 'Jade',
    app_rate_20: '1.84%',
    avg_round_20: 7.73,
    app_flat_20: 15,
    char_21: 'jing-yuan',
    app_rate_21: '1.5%',
    avg_round_21: 9.19,
    app_flat_21: 27,
    char_22: 'Pela',
    app_rate_22: '1.04%',
    avg_round_22: 8.75,
    app_flat_22: 8,
    char_23: 'Blade',
    app_rate_23: '0.93%',
    avg_round_23: 9.89,
    app_flat_23: 17,
    char_24: 'imbibitor-lunae',
    app_rate_24: '0.8%',
    avg_round_24: 9,
    app_flat_24: 1,
    char_25: 'Clara',
    app_rate_25: '0.8%',
    avg_round_25: 9.82,
    app_flat_25: 21,
    char_26: 'ruan-mei',
    app_rate_26: '0.63%',
    avg_round_26: 7.5,
    app_flat_26: 4,
    char_27: 'silver-wolf',
    app_rate_27: '0.63%',
    avg_round_27: 9.5,
    app_flat_27: 12,
    char_28: 'Bailu',
    app_rate_28: '0.54%',
    avg_round_28: 9.33,
    app_flat_28: 15,
    char_29: 'Himeko',
    app_rate_29: '0.49%',
    avg_round_29: 9.62,
    app_flat_29: 13,
    char_30: 'Lynx',
    app_rate_30: '0.29%',
    avg_round_30: 9.2,
    app_flat_30: 9
  },
  {
    char: 'Gallagher',
    app: 53.48,
    char_1: 'ruan-mei',
    app_rate_1: '85.0%',
    avg_round_1: 8.51,
    app_flat_1: 2546,
    char_2: 'trailblazer-harmony',
    app_rate_2: '84.96%',
    avg_round_2: 8.54,
    app_flat_2: 2615,
    char_3: 'Firefly',
    app_rate_3: '61.0%',
    avg_round_3: 8.61,
    app_flat_3: 1627,
    char_4: 'Rappa',
    app_rate_4: '18.14%',
    avg_round_4: 8.13,
    app_flat_4: 756,
    char_5: 'Acheron',
    app_rate_5: '8.28%',
    avg_round_5: 7.48,
    app_flat_5: 212,
    char_6: 'Jiaoqiu',
    app_rate_6: '5.69%',
    avg_round_6: 7.14,
    app_flat_6: 156,
    char_7: 'Himeko',
    app_rate_7: '5.25%',
    avg_round_7: 9.42,
    app_flat_7: 212,
    char_8: 'Robin',
    app_rate_8: '4.59%',
    avg_round_8: 7.22,
    app_flat_8: 136,
    char_9: 'Pela',
    app_rate_9: '4.42%',
    avg_round_9: 7.49,
    app_flat_9: 159,
    char_10: 'Feixiao',
    app_rate_10: '2.98%',
    avg_round_10: 7.17,
    app_flat_10: 111,
    char_11: 'Sparkle',
    app_rate_11: '2.78%',
    avg_round_11: 8.26,
    app_flat_11: 19,
    char_12: 'black-swan',
    app_rate_12: '2.69%',
    avg_round_12: 8.27,
    app_flat_12: 59,
    char_13: 'Kafka',
    app_rate_13: '2.57%',
    avg_round_13: 8.45,
    app_flat_13: 58,
    char_14: 'march-7th-swordmaster',
    app_rate_14: '2.3%',
    avg_round_14: 7.73,
    app_flat_14: 93,
    char_15: 'Bronya',
    app_rate_15: '1.52%',
    avg_round_15: 9.0,
    app_flat_15: 21,
    char_16: 'Boothill',
    app_rate_16: '1.27%',
    avg_round_16: 9.16,
    app_flat_16: 47,
    char_17: 'imbibitor-lunae',
    app_rate_17: '1.05%',
    avg_round_17: 8.33,
    app_flat_17: 6,
    char_18: 'silver-wolf',
    app_rate_18: '0.93%',
    avg_round_18: 8.15,
    app_flat_18: 26,
    char_19: 'topaz',
    app_rate_19: '0.62%',
    avg_round_19: 7.32,
    app_flat_19: 19,
    char_20: 'Moze',
    app_rate_20: '0.61%',
    avg_round_20: 7.05,
    app_flat_20: 20,
    char_21: 'Guinaifen',
    app_rate_21: '0.61%',
    avg_round_21: 9,
    app_flat_21: 20,
    char_22: 'Tingyun',
    app_rate_22: '0.55%',
    avg_round_22: 9.0,
    app_flat_22: 4,
    char_23: 'Asta',
    app_rate_23: '0.3%',
    avg_round_23: 9.83,
    app_flat_23: 12,
    char_24: 'Jade',
    app_rate_24: '0.23%',
    avg_round_24: 8.5,
    app_flat_24: 4,
    char_25: 'Blade',
    app_rate_25: '0.2%',
    avg_round_25: 9,
    app_flat_25: 4,
    char_26: 'jing-yuan',
    app_rate_26: '0.2%',
    avg_round_26: 9.33,
    app_flat_26: 3,
    char_27: 'Welt',
    app_rate_27: '0.18%',
    avg_round_27: 9.4,
    app_flat_27: 7,
    char_28: 'Lingsha',
    app_rate_28: '0.16%',
    avg_round_28: 8.25,
    app_flat_28: 4,
    char_29: 'Yunli',
    app_rate_29: '0.16%',
    avg_round_29: 9.0,
    app_flat_29: 4,
    char_30: 'Sampo',
    app_rate_30: '0.14%',
    avg_round_30: 9,
    app_flat_30: 1
  },
  {
    char: 'Acheron',
    app: 48.75,
    char_1: 'Jiaoqiu',
    app_rate_1: '50.25%',
    avg_round_1: 7.82,
    app_flat_1: 961,
    char_2: 'Aventurine',
    app_rate_2: '39.71%',
    avg_round_2: 8.65,
    app_flat_2: 820,
    char_3: 'Pela',
    app_rate_3: '37.91%',
    avg_round_3: 8.32,
    app_flat_3: 989,
    char_4: 'fu-xuan',
    app_rate_4: '33.25%',
    avg_round_4: 8.52,
    app_flat_4: 508,
    char_5: 'Sparkle',
    app_rate_5: '32.88%',
    avg_round_5: 8.53,
    app_flat_5: 132,
    char_6: 'black-swan',
    app_rate_6: '26.93%',
    avg_round_6: 8.87,
    app_flat_6: 573,
    char_7: 'Kafka',
    app_rate_7: '18.22%',
    avg_round_7: 8.97,
    app_flat_7: 369,
    char_8: 'silver-wolf',
    app_rate_8: '16.67%',
    avg_round_8: 8.6,
    app_flat_8: 348,
    char_9: 'Gallagher',
    app_rate_9: '9.08%',
    avg_round_9: 7.48,
    app_flat_9: 212,
    char_10: 'Huohuo',
    app_rate_10: '7.53%',
    avg_round_10: 8.69,
    app_flat_10: 116,
    char_11: 'Robin',
    app_rate_11: '6.05%',
    avg_round_11: 8.56,
    app_flat_11: 66,
    char_12: 'Bronya',
    app_rate_12: '5.87%',
    avg_round_12: 7.67,
    app_flat_12: 3,
    char_13: 'ruan-mei',
    app_rate_13: '3.21%',
    avg_round_13: 9.25,
    app_flat_13: 14,
    char_14: 'Luocha',
    app_rate_14: '3.17%',
    avg_round_14: 8.65,
    app_flat_14: 52,
    char_15: 'Gepard',
    app_rate_15: '2.05%',
    avg_round_15: 8.79,
    app_flat_15: 34,
    char_16: 'Guinaifen',
    app_rate_16: '1.96%',
    avg_round_16: 9.34,
    app_flat_16: 64,
    char_17: 'Lingsha',
    app_rate_17: '1.78%',
    avg_round_17: 7.8,
    app_flat_17: 27,
    char_18: 'Welt',
    app_rate_18: '0.9%',
    avg_round_18: 9.67,
    app_flat_18: 12,
    char_19: 'Bailu',
    app_rate_19: '0.7%',
    avg_round_19: 8.9,
    app_flat_19: 10,
    char_20: 'Sampo',
    app_rate_20: '0.57%',
    avg_round_20: 9.62,
    app_flat_20: 16,
    char_21: 'Feixiao',
    app_rate_21: '0.37%',
    avg_round_21: 8.33,
    app_flat_21: 6,
    char_22: 'trailblazer-harmony',
    app_rate_22: '0.1%',
    avg_round_22: 9,
    app_flat_22: 2,
    char_23: 'Himeko',
    app_rate_23: '0.08%',
    avg_round_23: 9,
    app_flat_23: 2,
    char_24: 'Lynx',
    app_rate_24: '0.08%',
    avg_round_24: 9,
    app_flat_24: 1,
    char_25: 'march-7th',
    app_rate_25: '0.08%',
    avg_round_25: 10,
    app_flat_25: 2,
    char_26: 'Firefly',
    app_rate_26: '0.08%',
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'Yunli',
    app_rate_27: '0.06%',
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'Tingyun',
    app_rate_28: '0.06%',
    avg_round_28: 9,
    app_flat_28: 2,
    char_29: 'Boothill',
    app_rate_29: '0.06%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Serval',
    app_rate_30: '0.04%',
    avg_round_30: 8.5,
    app_flat_30: 2
  },
  {
    char: 'Feixiao',
    app: 44.32,
    char_1: 'Robin',
    app_rate_1: '93.28%',
    avg_round_1: 7.62,
    app_flat_1: 2365,
    char_2: 'Aventurine',
    app_rate_2: '71.3%',
    avg_round_2: 7.6,
    app_flat_2: 1881,
    char_3: 'topaz',
    app_rate_3: '49.39%',
    avg_round_3: 7.52,
    app_flat_3: 1140,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '35.07%',
    avg_round_4: 7.9,
    app_flat_4: 1026,
    char_5: 'Moze',
    app_rate_5: '12.29%',
    avg_round_5: 7.68,
    app_flat_5: 328,
    char_6: 'fu-xuan',
    app_rate_6: '9.52%',
    avg_round_6: 8.18,
    app_flat_6: 222,
    char_7: 'Huohuo',
    app_rate_7: '5.6%',
    avg_round_7: 7.96,
    app_flat_7: 149,
    char_8: 'Bronya',
    app_rate_8: '4.22%',
    avg_round_8: 8.8,
    app_flat_8: 79,
    char_9: 'Gallagher',
    app_rate_9: '3.6%',
    avg_round_9: 7.17,
    app_flat_9: 111,
    char_10: 'Luocha',
    app_rate_10: '3.44%',
    avg_round_10: 8.69,
    app_flat_10: 89,
    char_11: 'Lingsha',
    app_rate_11: '2.84%',
    avg_round_11: 8.21,
    app_flat_11: 65,
    char_12: 'Sparkle',
    app_rate_12: '2.54%',
    avg_round_12: 8.73,
    app_flat_12: 44,
    char_13: 'Yunli',
    app_rate_13: '1.49%',
    avg_round_13: 9.11,
    app_flat_13: 34,
    char_14: 'Jade',
    app_rate_14: '1.31%',
    avg_round_14: 8,
    app_flat_14: 2,
    char_15: 'ruan-mei',
    app_rate_15: '1.03%',
    avg_round_15: 8.3,
    app_flat_15: 20,
    char_16: 'Bailu',
    app_rate_16: '0.45%',
    avg_round_16: 9.43,
    app_flat_16: 13,
    char_17: 'Jiaoqiu',
    app_rate_17: '0.45%',
    avg_round_17: 8.56,
    app_flat_17: 9,
    char_18: 'Acheron',
    app_rate_18: '0.41%',
    avg_round_18: 8.33,
    app_flat_18: 6,
    char_19: 'Clara',
    app_rate_19: '0.24%',
    avg_round_19: 9,
    app_flat_19: 6,
    char_20: 'Tingyun',
    app_rate_20: '0.17%',
    avg_round_20: 9.5,
    app_flat_20: 2,
    char_21: 'Kafka',
    app_rate_21: '0.15%',
    avg_round_21: 8.33,
    app_flat_21: 3,
    char_22: 'Lynx',
    app_rate_22: '0.13%',
    avg_round_22: 8.5,
    app_flat_22: 4,
    char_23: 'dr-ratio',
    app_rate_23: '0.13%',
    avg_round_23: 8,
    app_flat_23: 2,
    char_24: 'black-swan',
    app_rate_24: '0.13%',
    avg_round_24: 9,
    app_flat_24: 2,
    char_25: 'jing-yuan',
    app_rate_25: '0.13%',
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'Gepard',
    app_rate_26: '0.11%',
    avg_round_26: 8.25,
    app_flat_26: 4,
    char_27: 'Blade',
    app_rate_27: '0.06%',
    avg_round_27: 10,
    app_flat_27: 2,
    char_28: 'silver-wolf',
    app_rate_28: '0.06%',
    avg_round_28: 9.5,
    app_flat_28: 2,
    char_29: 'Welt',
    app_rate_29: '0.04%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Asta',
    app_rate_30: '0.04%',
    avg_round_30: 9.5,
    app_flat_30: 2
  },
  {
    char: 'Lingsha',
    app: 36.63,
    char_1: 'trailblazer-harmony',
    app_rate_1: '91.53%',
    avg_round_1: 7.7,
    app_flat_1: 1372,
    char_2: 'ruan-mei',
    app_rate_2: '90.54%',
    avg_round_2: 7.67,
    app_flat_2: 1347,
    char_3: 'Firefly',
    app_rate_3: '67.96%',
    avg_round_3: 7.95,
    app_flat_3: 844,
    char_4: 'Rappa',
    app_rate_4: '20.68%',
    avg_round_4: 7.1,
    app_flat_4: 451,
    char_5: 'Robin',
    app_rate_5: '4.77%',
    avg_round_5: 8.26,
    app_flat_5: 82,
    char_6: 'Feixiao',
    app_rate_6: '3.44%',
    avg_round_6: 8.21,
    app_flat_6: 65,
    char_7: 'Himeko',
    app_rate_7: '3.18%',
    avg_round_7: 8.76,
    app_flat_7: 72,
    char_8: 'Acheron',
    app_rate_8: '2.37%',
    avg_round_8: 7.8,
    app_flat_8: 27,
    char_9: 'march-7th-swordmaster',
    app_rate_9: '2.03%',
    avg_round_9: 8.48,
    app_flat_9: 45,
    char_10: 'topaz',
    app_rate_10: '1.88%',
    avg_round_10: 8.53,
    app_flat_10: 31,
    char_11: 'Jiaoqiu',
    app_rate_11: '1.82%',
    avg_round_11: 7.23,
    app_flat_11: 26,
    char_12: 'Sparkle',
    app_rate_12: '1.67%',
    avg_round_12: 9.17,
    app_flat_12: 10,
    char_13: 'Jade',
    app_rate_13: '1.28%',
    avg_round_13: 7.19,
    app_flat_13: 16,
    char_14: 'Pela',
    app_rate_14: '1.17%',
    avg_round_14: 7.3,
    app_flat_14: 20,
    char_15: 'black-swan',
    app_rate_15: '0.76%',
    avg_round_15: 8.5,
    app_flat_15: 8,
    char_16: 'Bronya',
    app_rate_16: '0.65%',
    avg_round_16: 6.83,
    app_flat_16: 6,
    char_17: 'Boothill',
    app_rate_17: '0.49%',
    avg_round_17: 8.91,
    app_flat_17: 11,
    char_18: 'Tingyun',
    app_rate_18: '0.47%',
    avg_round_18: 8.18,
    app_flat_18: 11,
    char_19: 'Kafka',
    app_rate_19: '0.44%',
    avg_round_19: 9.5,
    app_flat_19: 4,
    char_20: 'Moze',
    app_rate_20: '0.31%',
    avg_round_20: 7,
    app_flat_20: 4,
    char_21: 'Asta',
    app_rate_21: '0.29%',
    avg_round_21: 10.0,
    app_flat_21: 6,
    char_22: 'jing-yuan',
    app_rate_22: '0.26%',
    avg_round_22: 7.67,
    app_flat_22: 6,
    char_23: 'imbibitor-lunae',
    app_rate_23: '0.26%',
    avg_round_23: 8.67,
    app_flat_23: 3,
    char_24: 'Gallagher',
    app_rate_24: '0.23%',
    avg_round_24: 8.25,
    app_flat_24: 4,
    char_25: 'Yunli',
    app_rate_25: '0.23%',
    avg_round_25: 9,
    app_flat_25: 3,
    char_26: 'silver-wolf',
    app_rate_26: '0.23%',
    avg_round_26: 9.5,
    app_flat_26: 2,
    char_27: 'Jingliu',
    app_rate_27: '0.18%',
    avg_round_27: 9,
    app_flat_27: 2,
    char_28: 'Herta',
    app_rate_28: '0.13%',
    avg_round_28: 9.67,
    app_flat_28: 3,
    char_29: 'Guinaifen',
    app_rate_29: '0.13%',
    avg_round_29: 9.33,
    app_flat_29: 3,
    char_30: 'Welt',
    app_rate_30: '0.08%',
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'Jiaoqiu',
    app: 26.4,
    char_1: 'Acheron',
    app_rate_1: '92.81%',
    avg_round_1: 7.82,
    app_flat_1: 961,
    char_2: 'Aventurine',
    app_rate_2: '43.95%',
    avg_round_2: 8.07,
    app_flat_2: 474,
    char_3: 'Sparkle',
    app_rate_3: '34.08%',
    avg_round_3: 7.73,
    app_flat_3: 78,
    char_4: 'Pela',
    app_rate_4: '29.78%',
    avg_round_4: 7.64,
    app_flat_4: 587,
    char_5: 'fu-xuan',
    app_rate_5: '25.41%',
    avg_round_5: 7.87,
    app_flat_5: 240,
    char_6: 'black-swan',
    app_rate_6: '11.6%',
    avg_round_6: 8.39,
    app_flat_6: 150,
    char_7: 'Gallagher',
    app_rate_7: '11.53%',
    avg_round_7: 7.14,
    app_flat_7: 156,
    char_8: 'Robin',
    app_rate_8: '7.77%',
    avg_round_8: 7.75,
    app_flat_8: 48,
    char_9: 'Huohuo',
    app_rate_9: '6.94%',
    avg_round_9: 8.19,
    app_flat_9: 61,
    char_10: 'Bronya',
    app_rate_10: '5.75%',
    avg_round_10: 8,
    app_flat_10: 2,
    char_11: 'silver-wolf',
    app_rate_11: '5.2%',
    avg_round_11: 8.18,
    app_flat_11: 74,
    char_12: 'ruan-mei',
    app_rate_12: '5.2%',
    avg_round_12: 8.12,
    app_flat_12: 16,
    char_13: 'Kafka',
    app_rate_13: '5.13%',
    avg_round_13: 8.84,
    app_flat_13: 43,
    char_14: 'Luocha',
    app_rate_14: '3.98%',
    avg_round_14: 8.65,
    app_flat_14: 33,
    char_15: 'Lingsha',
    app_rate_15: '2.53%',
    avg_round_15: 7.23,
    app_flat_15: 26,
    char_16: 'Gepard',
    app_rate_16: '0.94%',
    avg_round_16: 8.5,
    app_flat_16: 10,
    char_17: 'imbibitor-lunae',
    app_rate_17: '0.83%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'trailblazer-harmony',
    app_rate_18: '0.76%',
    avg_round_18: 9.17,
    app_flat_18: 10,
    char_19: 'Feixiao',
    app_rate_19: '0.76%',
    avg_round_19: 8.56,
    app_flat_19: 9,
    char_20: 'dr-ratio',
    app_rate_20: '0.54%',
    avg_round_20: 10,
    app_flat_20: 1,
    char_21: 'Guinaifen',
    app_rate_21: '0.54%',
    avg_round_21: 8.25,
    app_flat_21: 6,
    char_22: 'Himeko',
    app_rate_22: '0.47%',
    avg_round_22: 9.5,
    app_flat_22: 6,
    char_23: 'Sampo',
    app_rate_23: '0.43%',
    avg_round_23: 8.67,
    app_flat_23: 6,
    char_24: 'Bailu',
    app_rate_24: '0.4%',
    avg_round_24: 8.5,
    app_flat_24: 4,
    char_25: 'Rappa',
    app_rate_25: '0.33%',
    avg_round_25: 9,
    app_flat_25: 3,
    char_26: 'Welt',
    app_rate_26: '0.33%',
    avg_round_26: 10.0,
    app_flat_26: 5,
    char_27: 'topaz',
    app_rate_27: '0.25%',
    avg_round_27: 8,
    app_flat_27: 1,
    char_28: 'Moze',
    app_rate_28: '0.22%',
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: 'Firefly',
    app_rate_29: '0.22%',
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'Tingyun',
    app_rate_30: '0.18%',
    avg_round_30: 9,
    app_flat_30: 2
  },
  {
    char: 'topaz',
    app: 24.47,
    char_1: 'Robin',
    app_rate_1: '93.96%',
    avg_round_1: 7.54,
    app_flat_1: 1143,
    char_2: 'Feixiao',
    app_rate_2: '89.43%',
    avg_round_2: 7.52,
    app_flat_2: 1140,
    char_3: 'Aventurine',
    app_rate_3: '82.69%',
    avg_round_3: 7.51,
    app_flat_3: 1027,
    char_4: 'fu-xuan',
    app_rate_4: '6.39%',
    avg_round_4: 8.29,
    app_flat_4: 78,
    char_5: 'dr-ratio',
    app_rate_5: '5.93%',
    avg_round_5: 9.38,
    app_flat_5: 39,
    char_6: 'Huohuo',
    app_rate_6: '3.16%',
    avg_round_6: 8.03,
    app_flat_6: 40,
    char_7: 'Lingsha',
    app_rate_7: '2.81%',
    avg_round_7: 8.53,
    app_flat_7: 31,
    char_8: 'Yunli',
    app_rate_8: '2.14%',
    avg_round_8: 9.0,
    app_flat_8: 19,
    char_9: 'Luocha',
    app_rate_9: '1.95%',
    avg_round_9: 8.35,
    app_flat_9: 20,
    char_10: 'Sparkle',
    app_rate_10: '1.56%',
    avg_round_10: 8.31,
    app_flat_10: 13,
    char_11: 'march-7th-swordmaster',
    app_rate_11: '1.56%',
    avg_round_11: 8.85,
    app_flat_11: 13,
    char_12: 'Bronya',
    app_rate_12: '1.48%',
    avg_round_12: 8.94,
    app_flat_12: 17,
    char_13: 'Moze',
    app_rate_13: '1.44%',
    avg_round_13: 8.05,
    app_flat_13: 20,
    char_14: 'Gallagher',
    app_rate_14: '1.36%',
    avg_round_14: 7.32,
    app_flat_14: 19,
    char_15: 'ruan-mei',
    app_rate_15: '1.29%',
    avg_round_15: 8.88,
    app_flat_15: 16,
    char_16: 'Himeko',
    app_rate_16: '0.7%',
    avg_round_16: 9.8,
    app_flat_16: 7,
    char_17: 'Jade',
    app_rate_17: '0.58%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Clara',
    app_rate_18: '0.43%',
    avg_round_18: 8.33,
    app_flat_18: 3,
    char_19: 'Jiaoqiu',
    app_rate_19: '0.27%',
    avg_round_19: 8,
    app_flat_19: 1,
    char_20: 'jing-yuan',
    app_rate_20: '0.23%',
    avg_round_20: 10,
    app_flat_20: 3,
    char_21: 'silver-wolf',
    app_rate_21: '0.12%',
    avg_round_21: 9.5,
    app_flat_21: 2,
    char_22: 'Bailu',
    app_rate_22: '0.08%',
    avg_round_22: 10,
    app_flat_22: 2,
    char_23: 'march-7th',
    app_rate_23: '0.08%',
    avg_round_23: 3,
    app_flat_23: 1,
    char_24: 'Lynx',
    app_rate_24: '0.08%',
    avg_round_24: 6,
    app_flat_24: 1,
    char_25: 'Asta',
    app_rate_25: '0.08%',
    avg_round_25: 9,
    app_flat_25: 1,
    char_26: 'dan-heng',
    app_rate_26: '0.04%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Tingyun',
    app_rate_27: '0.04%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Kafka',
    app_rate_28: '0.04%',
    avg_round_28: 8,
    app_flat_28: 1,
    char_29: 'Herta',
    app_rate_29: '0.04%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Acheron',
    app_rate_30: '0.04%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'fu-xuan',
    app: 23.54,
    char_1: 'Acheron',
    app_rate_1: '68.87%',
    avg_round_1: 8.52,
    app_flat_1: 508,
    char_2: 'Sparkle',
    app_rate_2: '33.81%',
    avg_round_2: 8.89,
    app_flat_2: 74,
    char_3: 'Jiaoqiu',
    app_rate_3: '28.5%',
    avg_round_3: 7.87,
    app_flat_3: 240,
    char_4: 'Pela',
    app_rate_4: '26.71%',
    avg_round_4: 8.11,
    app_flat_4: 280,
    char_5: 'Robin',
    app_rate_5: '22.62%',
    avg_round_5: 8.25,
    app_flat_5: 234,
    char_6: 'black-swan',
    app_rate_6: '21.04%',
    avg_round_6: 8.99,
    app_flat_6: 188,
    char_7: 'silver-wolf',
    app_rate_7: '19.98%',
    avg_round_7: 8.43,
    app_flat_7: 150,
    char_8: 'Feixiao',
    app_rate_8: '17.92%',
    avg_round_8: 8.18,
    app_flat_8: 222,
    char_9: 'Kafka',
    app_rate_9: '15.57%',
    avg_round_9: 8.98,
    app_flat_9: 131,
    char_10: 'march-7th-swordmaster',
    app_rate_10: '10.58%',
    avg_round_10: 8.21,
    app_flat_10: 132,
    char_11: 'topaz',
    app_rate_11: '6.65%',
    avg_round_11: 8.29,
    app_flat_11: 78,
    char_12: 'ruan-mei',
    app_rate_12: '4.38%',
    avg_round_12: 9.54,
    app_flat_12: 23,
    char_13: 'Bronya',
    app_rate_13: '4.34%',
    avg_round_13: 9,
    app_flat_13: 8,
    char_14: 'Tingyun',
    app_rate_14: '2.51%',
    avg_round_14: 8.77,
    app_flat_14: 13,
    char_15: 'Yunli',
    app_rate_15: '1.7%',
    avg_round_15: 8.45,
    app_flat_15: 20,
    char_16: 'jing-yuan',
    app_rate_16: '1.62%',
    avg_round_16: 9,
    app_flat_16: 7,
    char_17: 'Moze',
    app_rate_17: '1.5%',
    avg_round_17: 8.12,
    app_flat_17: 16,
    char_18: 'Qingque',
    app_rate_18: '1.3%',
    avg_round_18: 10.0,
    app_flat_18: 4,
    char_19: 'Seele',
    app_rate_19: '1.26%',
    avg_round_19: 9.67,
    app_flat_19: 3,
    char_20: 'trailblazer-harmony',
    app_rate_20: '1.18%',
    avg_round_20: 9.67,
    app_flat_20: 10,
    char_21: 'imbibitor-lunae',
    app_rate_21: '1.01%',
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'Clara',
    app_rate_22: '0.89%',
    avg_round_22: 10.0,
    app_flat_22: 6,
    char_23: 'Guinaifen',
    app_rate_23: '0.89%',
    avg_round_23: 9.8,
    app_flat_23: 9,
    char_24: 'Blade',
    app_rate_24: '0.81%',
    avg_round_24: 10,
    app_flat_24: 3,
    char_25: 'Firefly',
    app_rate_25: '0.77%',
    avg_round_25: 9.67,
    app_flat_25: 3,
    char_26: 'Jade',
    app_rate_26: '0.65%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Sampo',
    app_rate_27: '0.65%',
    avg_round_27: 10,
    app_flat_27: 6,
    char_28: 'Rappa',
    app_rate_28: '0.45%',
    avg_round_28: 9.75,
    app_flat_28: 6,
    char_29: 'Welt',
    app_rate_29: '0.45%',
    avg_round_29: 9.0,
    app_flat_29: 4,
    char_30: 'Serval',
    app_rate_30: '0.24%',
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'Sparkle',
    app: 23.17,
    char_1: 'Acheron',
    app_rate_1: '69.19%',
    avg_round_1: 8.53,
    app_flat_1: 132,
    char_2: 'Jiaoqiu',
    app_rate_2: '38.84%',
    avg_round_2: 7.73,
    app_flat_2: 78,
    char_3: 'fu-xuan',
    app_rate_3: '34.35%',
    avg_round_3: 8.89,
    app_flat_3: 74,
    char_4: 'Aventurine',
    app_rate_4: '33.4%',
    avg_round_4: 8.86,
    app_flat_4: 113,
    char_5: 'Pela',
    app_rate_5: '15.44%',
    avg_round_5: 9.19,
    app_flat_5: 30,
    char_6: 'silver-wolf',
    app_rate_6: '12.89%',
    avg_round_6: 9.1,
    app_flat_6: 21,
    char_7: 'imbibitor-lunae',
    app_rate_7: '11.2%',
    avg_round_7: 9.36,
    app_flat_7: 21,
    char_8: 'Huohuo',
    app_rate_8: '10.91%',
    avg_round_8: 9.2,
    app_flat_8: 49,
    char_9: 'Robin',
    app_rate_9: '8.28%',
    avg_round_9: 9.31,
    app_flat_9: 32,
    char_10: 'Tingyun',
    app_rate_10: '8.03%',
    avg_round_10: 9.19,
    app_flat_10: 41,
    char_11: 'ruan-mei',
    app_rate_11: '7.0%',
    avg_round_11: 9.11,
    app_flat_11: 15,
    char_12: 'Gallagher',
    app_rate_12: '6.43%',
    avg_round_12: 8.26,
    app_flat_12: 19,
    char_13: 'black-swan',
    app_rate_13: '4.9%',
    avg_round_13: 9.8,
    app_flat_13: 9,
    char_14: 'Luocha',
    app_rate_14: '4.86%',
    avg_round_14: 8.93,
    app_flat_14: 14,
    char_15: 'Feixiao',
    app_rate_15: '4.86%',
    avg_round_15: 8.73,
    app_flat_15: 44,
    char_16: 'jing-yuan',
    app_rate_16: '3.83%',
    avg_round_16: 9.41,
    app_flat_16: 31,
    char_17: 'Yunli',
    app_rate_17: '3.42%',
    avg_round_17: 8.46,
    app_flat_17: 24,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '2.68%',
    avg_round_18: 9.12,
    app_flat_18: 24,
    char_19: 'Lingsha',
    app_rate_19: '2.64%',
    avg_round_19: 9.17,
    app_flat_19: 10,
    char_20: 'Bronya',
    app_rate_20: '2.1%',
    avg_round_20: 9.83,
    app_flat_20: 10,
    char_21: 'Blade',
    app_rate_21: '1.81%',
    avg_round_21: 9.83,
    app_flat_21: 12,
    char_22: 'topaz',
    app_rate_22: '1.65%',
    avg_round_22: 8.31,
    app_flat_22: 13,
    char_23: 'Kafka',
    app_rate_23: '1.4%',
    avg_round_23: 9.5,
    app_flat_23: 4,
    char_24: 'Qingque',
    app_rate_24: '1.36%',
    avg_round_24: 10.0,
    app_flat_24: 4,
    char_25: 'Seele',
    app_rate_25: '1.32%',
    avg_round_25: 9.67,
    app_flat_25: 3,
    char_26: 'Gepard',
    app_rate_26: '1.11%',
    avg_round_26: 9,
    app_flat_26: 4,
    char_27: 'Jade',
    app_rate_27: '0.99%',
    avg_round_27: 9.5,
    app_flat_27: 2,
    char_28: 'Welt',
    app_rate_28: '0.78%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Clara',
    app_rate_29: '0.66%',
    avg_round_29: 9.5,
    app_flat_29: 6,
    char_30: 'Jingliu',
    app_rate_30: '0.45%',
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'black-swan',
    app: 22.41,
    char_1: 'Kafka',
    app_rate_1: '74.71%',
    avg_round_1: 8.86,
    app_flat_1: 605,
    char_2: 'Acheron',
    app_rate_2: '58.58%',
    avg_round_2: 8.87,
    app_flat_2: 573,
    char_3: 'Huohuo',
    app_rate_3: '31.29%',
    avg_round_3: 8.88,
    app_flat_3: 208,
    char_4: 'Aventurine',
    app_rate_4: '26.48%',
    avg_round_4: 8.82,
    app_flat_4: 304,
    char_5: 'fu-xuan',
    app_rate_5: '22.09%',
    avg_round_5: 8.99,
    app_flat_5: 188,
    char_6: 'Robin',
    app_rate_6: '20.95%',
    avg_round_6: 8.72,
    app_flat_6: 157,
    char_7: 'ruan-mei',
    app_rate_7: '14.09%',
    avg_round_7: 8.32,
    app_flat_7: 82,
    char_8: 'Jiaoqiu',
    app_rate_8: '13.67%',
    avg_round_8: 8.39,
    app_flat_8: 150,
    char_9: 'Luocha',
    app_rate_9: '7.58%',
    avg_round_9: 8.43,
    app_flat_9: 58,
    char_10: 'Gallagher',
    app_rate_10: '6.43%',
    avg_round_10: 8.27,
    app_flat_10: 59,
    char_11: 'Pela',
    app_rate_11: '5.36%',
    avg_round_11: 9.0,
    app_flat_11: 75,
    char_12: 'Sparkle',
    app_rate_12: '5.07%',
    avg_round_12: 9.8,
    app_flat_12: 9,
    char_13: 'Gepard',
    app_rate_13: '1.87%',
    avg_round_13: 9.33,
    app_flat_13: 15,
    char_14: 'Bailu',
    app_rate_14: '1.75%',
    avg_round_14: 9,
    app_flat_14: 13,
    char_15: 'Guinaifen',
    app_rate_15: '1.66%',
    avg_round_15: 9.5,
    app_flat_15: 12,
    char_16: 'silver-wolf',
    app_rate_16: '1.53%',
    avg_round_16: 8.42,
    app_flat_16: 19,
    char_17: 'Tingyun',
    app_rate_17: '1.32%',
    avg_round_17: 9.1,
    app_flat_17: 10,
    char_18: 'Sampo',
    app_rate_18: '1.28%',
    avg_round_18: 9,
    app_flat_18: 12,
    char_19: 'Lingsha',
    app_rate_19: '1.23%',
    avg_round_19: 8.5,
    app_flat_19: 8,
    char_20: 'Bronya',
    app_rate_20: '0.72%',
    avg_round_20: 9.5,
    app_flat_20: 2,
    char_21: 'Asta',
    app_rate_21: '0.68%',
    avg_round_21: 9.5,
    app_flat_21: 4,
    char_22: 'Lynx',
    app_rate_22: '0.43%',
    avg_round_22: 9.33,
    app_flat_22: 5,
    char_23: 'Feixiao',
    app_rate_23: '0.26%',
    avg_round_23: 9,
    app_flat_23: 2,
    char_24: 'Firefly',
    app_rate_24: '0.13%',
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'Blade',
    app_rate_25: '0.13%',
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'Moze',
    app_rate_26: '0.09%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Clara',
    app_rate_27: '0.09%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Serval',
    app_rate_28: '0.09%',
    avg_round_28: 8,
    app_flat_28: 1,
    char_29: 'Hanya',
    app_rate_29: '0.09%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Welt',
    app_rate_30: '0.09%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Rappa',
    app: 19.32,
    char_1: 'trailblazer-harmony',
    app_rate_1: '98.86%',
    avg_round_1: 7.65,
    app_flat_1: 1277,
    char_2: 'ruan-mei',
    app_rate_2: '93.04%',
    avg_round_2: 7.56,
    app_flat_2: 1187,
    char_3: 'Gallagher',
    app_rate_3: '50.22%',
    avg_round_3: 8.13,
    app_flat_3: 756,
    char_4: 'Lingsha',
    app_rate_4: '39.21%',
    avg_round_4: 7.1,
    app_flat_4: 451,
    char_5: 'Firefly',
    app_rate_5: '6.07%',
    avg_round_5: 9.0,
    app_flat_5: 34,
    char_6: 'Luocha',
    app_rate_6: '2.32%',
    avg_round_6: 8.91,
    app_flat_6: 21,
    char_7: 'Aventurine',
    app_rate_7: '2.12%',
    avg_round_7: 9.31,
    app_flat_7: 23,
    char_8: 'Himeko',
    app_rate_8: '1.93%',
    avg_round_8: 9.6,
    app_flat_8: 20,
    char_9: 'Huohuo',
    app_rate_9: '1.93%',
    avg_round_9: 8.89,
    app_flat_9: 17,
    char_10: 'Pela',
    app_rate_10: '0.94%',
    avg_round_10: 7.83,
    app_flat_10: 10,
    char_11: 'fu-xuan',
    app_rate_11: '0.54%',
    avg_round_11: 9.75,
    app_flat_11: 6,
    char_12: 'Asta',
    app_rate_12: '0.54%',
    avg_round_12: 10.0,
    app_flat_12: 8,
    char_13: 'Jiaoqiu',
    app_rate_13: '0.44%',
    avg_round_13: 9,
    app_flat_13: 3,
    char_14: 'Tingyun',
    app_rate_14: '0.3%',
    avg_round_14: 9,
    app_flat_14: 3,
    char_15: 'Bronya',
    app_rate_15: '0.25%',
    avg_round_15: 8.6,
    app_flat_15: 5,
    char_16: 'Bailu',
    app_rate_16: '0.15%',
    avg_round_16: 8,
    app_flat_16: 1,
    char_17: 'Robin',
    app_rate_17: '0.15%',
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '0.15%',
    avg_round_18: 8.67,
    app_flat_18: 3,
    char_19: 'Boothill',
    app_rate_19: '0.15%',
    avg_round_19: 9.5,
    app_flat_19: 2,
    char_20: 'Jade',
    app_rate_20: '0.15%',
    avg_round_20: 6,
    app_flat_20: 1,
    char_21: 'Lynx',
    app_rate_21: '0.1%',
    avg_round_21: 10,
    app_flat_21: 2,
    char_22: 'Gepard',
    app_rate_22: '0.05%',
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'Jingliu',
    app_rate_23: '0.05%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Hanya',
    app_rate_24: '0.05%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'silver-wolf',
    app_rate_25: '0.05%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Acheron',
    app_rate_26: '0.05%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Kafka',
    app_rate_27: '0.05%',
    avg_round_27: 9,
    app_flat_27: 1,
    char_28: 'Welt',
    app_rate_28: '0.05%',
    avg_round_28: 8,
    app_flat_28: 1,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Pela',
    app: 19.09,
    char_1: 'Acheron',
    app_rate_1: '96.8%',
    avg_round_1: 8.32,
    app_flat_1: 989,
    char_2: 'Jiaoqiu',
    app_rate_2: '41.18%',
    avg_round_2: 7.64,
    app_flat_2: 587,
    char_3: 'Aventurine',
    app_rate_3: '40.43%',
    avg_round_3: 8.36,
    app_flat_3: 464,
    char_4: 'fu-xuan',
    app_rate_4: '32.93%',
    avg_round_4: 8.11,
    app_flat_4: 280,
    char_5: 'Sparkle',
    app_rate_5: '18.74%',
    avg_round_5: 9.19,
    app_flat_5: 30,
    char_6: 'silver-wolf',
    app_rate_6: '18.09%',
    avg_round_6: 8.71,
    app_flat_6: 230,
    char_7: 'Gallagher',
    app_rate_7: '12.39%',
    avg_round_7: 7.49,
    app_flat_7: 159,
    char_8: 'black-swan',
    app_rate_8: '6.3%',
    avg_round_8: 9.0,
    app_flat_8: 75,
    char_9: 'Bronya',
    app_rate_9: '5.65%',
    avg_round_9: 9,
    app_flat_9: 3,
    char_10: 'Huohuo',
    app_rate_10: '3.7%',
    avg_round_10: 8.19,
    app_flat_10: 41,
    char_11: 'Robin',
    app_rate_11: '3.05%',
    avg_round_11: 8.75,
    app_flat_11: 8,
    char_12: 'Guinaifen',
    app_rate_12: '2.65%',
    avg_round_12: 9.38,
    app_flat_12: 41,
    char_13: 'Luocha',
    app_rate_13: '2.55%',
    avg_round_13: 8.85,
    app_flat_13: 23,
    char_14: 'ruan-mei',
    app_rate_14: '2.55%',
    avg_round_14: 10,
    app_flat_14: 2,
    char_15: 'Gepard',
    app_rate_15: '2.45%',
    avg_round_15: 9.0,
    app_flat_15: 21,
    char_16: 'Lingsha',
    app_rate_16: '2.25%',
    avg_round_16: 7.3,
    app_flat_16: 20,
    char_17: 'trailblazer-harmony',
    app_rate_17: '2.05%',
    avg_round_17: 8.67,
    app_flat_17: 21,
    char_18: 'Kafka',
    app_rate_18: '1.4%',
    avg_round_18: 9.17,
    app_flat_18: 12,
    char_19: 'Welt',
    app_rate_19: '1.05%',
    avg_round_19: 9.25,
    app_flat_19: 6,
    char_20: 'Firefly',
    app_rate_20: '0.95%',
    avg_round_20: 9.2,
    app_flat_20: 10,
    char_21: 'Rappa',
    app_rate_21: '0.95%',
    avg_round_21: 7.83,
    app_flat_21: 10,
    char_22: 'Bailu',
    app_rate_22: '0.4%',
    avg_round_22: 8.33,
    app_flat_22: 5,
    char_23: 'imbibitor-lunae',
    app_rate_23: '0.2%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Himeko',
    app_rate_24: '0.15%',
    avg_round_24: 10,
    app_flat_24: 2,
    char_25: 'march-7th',
    app_rate_25: '0.15%',
    avg_round_25: 10,
    app_flat_25: 2,
    char_26: 'Yunli',
    app_rate_26: '0.1%',
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'Boothill',
    app_rate_27: '0.1%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Herta',
    app_rate_28: '0.1%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Jingliu',
    app_rate_29: '0.1%',
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'Sampo',
    app_rate_30: '0.1%',
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'Kafka',
    app: 18.52,
    char_1: 'black-swan',
    app_rate_1: '90.42%',
    avg_round_1: 8.86,
    app_flat_1: 605,
    char_2: 'Acheron',
    app_rate_2: '47.96%',
    avg_round_2: 8.97,
    app_flat_2: 369,
    char_3: 'Huohuo',
    app_rate_3: '37.2%',
    avg_round_3: 8.88,
    app_flat_3: 197,
    char_4: 'Robin',
    app_rate_4: '24.88%',
    avg_round_4: 8.75,
    app_flat_4: 159,
    char_5: 'Aventurine',
    app_rate_5: '21.17%',
    avg_round_5: 8.91,
    app_flat_5: 198,
    char_6: 'fu-xuan',
    app_rate_6: '19.78%',
    avg_round_6: 8.98,
    app_flat_6: 131,
    char_7: 'ruan-mei',
    app_rate_7: '17.77%',
    avg_round_7: 8.43,
    app_flat_7: 89,
    char_8: 'Luocha',
    app_rate_8: '8.71%',
    avg_round_8: 8.41,
    app_flat_8: 54,
    char_9: 'Gallagher',
    app_rate_9: '7.42%',
    avg_round_9: 8.45,
    app_flat_9: 58,
    char_10: 'Jiaoqiu',
    app_rate_10: '7.32%',
    avg_round_10: 8.84,
    app_flat_10: 43,
    char_11: 'Sampo',
    app_rate_11: '2.32%',
    avg_round_11: 9.8,
    app_flat_11: 20,
    char_12: 'Bailu',
    app_rate_12: '2.06%',
    avg_round_12: 9,
    app_flat_12: 14,
    char_13: 'Sparkle',
    app_rate_13: '1.75%',
    avg_round_13: 9.5,
    app_flat_13: 4,
    char_14: 'Tingyun',
    app_rate_14: '1.7%',
    avg_round_14: 9.25,
    app_flat_14: 12,
    char_15: 'Gepard',
    app_rate_15: '1.6%',
    avg_round_15: 8.91,
    app_flat_15: 11,
    char_16: 'Guinaifen',
    app_rate_16: '1.49%',
    avg_round_16: 8.83,
    app_flat_16: 6,
    char_17: 'Pela',
    app_rate_17: '1.44%',
    avg_round_17: 9.17,
    app_flat_17: 12,
    char_18: 'Lingsha',
    app_rate_18: '0.88%',
    avg_round_18: 9.5,
    app_flat_18: 4,
    char_19: 'Asta',
    app_rate_19: '0.82%',
    avg_round_19: 9.5,
    app_flat_19: 4,
    char_20: 'silver-wolf',
    app_rate_20: '0.77%',
    avg_round_20: 8,
    app_flat_20: 2,
    char_21: 'Lynx',
    app_rate_21: '0.46%',
    avg_round_21: 9.5,
    app_flat_21: 4,
    char_22: 'trailblazer-harmony',
    app_rate_22: '0.41%',
    avg_round_22: 9.4,
    app_flat_22: 5,
    char_23: 'Feixiao',
    app_rate_23: '0.36%',
    avg_round_23: 8.33,
    app_flat_23: 3,
    char_24: 'Firefly',
    app_rate_24: '0.15%',
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'march-7th-swordmaster',
    app_rate_25: '0.15%',
    avg_round_25: 9.5,
    app_flat_25: 2,
    char_26: 'Bronya',
    app_rate_26: '0.15%',
    avg_round_26: 9,
    app_flat_26: 1,
    char_27: 'Blade',
    app_rate_27: '0.1%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Serval',
    app_rate_28: '0.1%',
    avg_round_28: 9,
    app_flat_28: 1,
    char_29: 'Welt',
    app_rate_29: '0.1%',
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'Clara',
    app_rate_30: '0.05%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'march-7th-swordmaster',
    app: 17.2,
    char_1: 'Feixiao',
    app_rate_1: '90.35%',
    avg_round_1: 7.9,
    app_flat_1: 1026,
    char_2: 'Robin',
    app_rate_2: '87.58%',
    avg_round_2: 7.84,
    app_flat_2: 972,
    char_3: 'Aventurine',
    app_rate_3: '52.91%',
    avg_round_3: 7.88,
    app_flat_3: 657,
    char_4: 'fu-xuan',
    app_rate_4: '14.48%',
    avg_round_4: 8.21,
    app_flat_4: 132,
    char_5: 'Huohuo',
    app_rate_5: '9.93%',
    avg_round_5: 8.22,
    app_flat_5: 108,
    char_6: 'Gallagher',
    app_rate_6: '7.15%',
    avg_round_6: 7.73,
    app_flat_6: 93,
    char_7: 'Bronya',
    app_rate_7: '6.21%',
    avg_round_7: 9.32,
    app_flat_7: 35,
    char_8: 'Luocha',
    app_rate_8: '4.6%',
    avg_round_8: 8.64,
    app_flat_8: 49,
    char_9: 'Lingsha',
    app_rate_9: '4.33%',
    avg_round_9: 8.48,
    app_flat_9: 45,
    char_10: 'Sparkle',
    app_rate_10: '3.61%',
    avg_round_10: 9.12,
    app_flat_10: 24,
    char_11: 'ruan-mei',
    app_rate_11: '3.11%',
    avg_round_11: 8.52,
    app_flat_11: 23,
    char_12: 'trailblazer-harmony',
    app_rate_12: '3.11%',
    avg_round_12: 9.24,
    app_flat_12: 31,
    char_13: 'topaz',
    app_rate_13: '2.22%',
    avg_round_13: 8.85,
    app_flat_13: 13,
    char_14: 'Yunli',
    app_rate_14: '1.66%',
    avg_round_14: 8.78,
    app_flat_14: 17,
    char_15: 'Moze',
    app_rate_15: '1.55%',
    avg_round_15: 9.38,
    app_flat_15: 16,
    char_16: 'Firefly',
    app_rate_16: '1.05%',
    avg_round_16: 9.3,
    app_flat_16: 10,
    char_17: 'dr-ratio',
    app_rate_17: '0.89%',
    avg_round_17: 9,
    app_flat_17: 4,
    char_18: 'Blade',
    app_rate_18: '0.78%',
    avg_round_18: 10.0,
    app_flat_18: 4,
    char_19: 'Clara',
    app_rate_19: '0.72%',
    avg_round_19: 9.75,
    app_flat_19: 8,
    char_20: 'Bailu',
    app_rate_20: '0.67%',
    avg_round_20: 9.33,
    app_flat_20: 10,
    char_21: 'Himeko',
    app_rate_21: '0.5%',
    avg_round_21: 9.0,
    app_flat_21: 4,
    char_22: 'Lynx',
    app_rate_22: '0.39%',
    avg_round_22: 9.5,
    app_flat_22: 4,
    char_23: 'Tingyun',
    app_rate_23: '0.33%',
    avg_round_23: 8.5,
    app_flat_23: 2,
    char_24: 'imbibitor-lunae',
    app_rate_24: '0.22%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Jade',
    app_rate_25: '0.17%',
    avg_round_25: 7,
    app_flat_25: 1,
    char_26: 'Kafka',
    app_rate_26: '0.17%',
    avg_round_26: 9.5,
    app_flat_26: 2,
    char_27: 'Rappa',
    app_rate_27: '0.17%',
    avg_round_27: 8.67,
    app_flat_27: 3,
    char_28: 'Gepard',
    app_rate_28: '0.17%',
    avg_round_28: 8,
    app_flat_28: 3,
    char_29: 'Asta',
    app_rate_29: '0.11%',
    avg_round_29: 10,
    app_flat_29: 2,
    char_30: 'jing-yuan',
    app_rate_30: '0.11%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Huohuo',
    app: 15.22,
    char_1: 'black-swan',
    app_rate_1: '46.08%',
    avg_round_1: 8.88,
    app_flat_1: 208,
    char_2: 'Kafka',
    app_rate_2: '45.27%',
    avg_round_2: 8.88,
    app_flat_2: 197,
    char_3: 'Robin',
    app_rate_3: '44.95%',
    avg_round_3: 8.51,
    app_flat_3: 283,
    char_4: 'Acheron',
    app_rate_4: '24.14%',
    avg_round_4: 8.69,
    app_flat_4: 116,
    char_5: 'ruan-mei',
    app_rate_5: '18.75%',
    avg_round_5: 8.46,
    app_flat_5: 74,
    char_6: 'Sparkle',
    app_rate_6: '16.61%',
    avg_round_6: 9.2,
    app_flat_6: 49,
    char_7: 'Feixiao',
    app_rate_7: '16.3%',
    avg_round_7: 7.96,
    app_flat_7: 149,
    char_8: 'Jiaoqiu',
    app_rate_8: '12.04%',
    avg_round_8: 8.19,
    app_flat_8: 61,
    char_9: 'march-7th-swordmaster',
    app_rate_9: '11.22%',
    avg_round_9: 8.22,
    app_flat_9: 108,
    char_10: 'Tingyun',
    app_rate_10: '10.72%',
    avg_round_10: 8.92,
    app_flat_10: 48,
    char_11: 'Yunli',
    app_rate_11: '10.16%',
    avg_round_11: 8.44,
    app_flat_11: 63,
    char_12: 'imbibitor-lunae',
    app_rate_12: '5.27%',
    avg_round_12: 9,
    app_flat_12: 3,
    char_13: 'topaz',
    app_rate_13: '5.08%',
    avg_round_13: 8.03,
    app_flat_13: 40,
    char_14: 'Pela',
    app_rate_14: '4.64%',
    avg_round_14: 8.19,
    app_flat_14: 41,
    char_15: 'trailblazer-harmony',
    app_rate_15: '4.51%',
    avg_round_15: 8.92,
    app_flat_15: 24,
    char_16: 'Bronya',
    app_rate_16: '2.95%',
    avg_round_16: 9.75,
    app_flat_16: 8,
    char_17: 'jing-yuan',
    app_rate_17: '2.7%',
    avg_round_17: 9.29,
    app_flat_17: 11,
    char_18: 'Firefly',
    app_rate_18: '2.51%',
    avg_round_18: 9,
    app_flat_18: 7,
    char_19: 'Blade',
    app_rate_19: '2.51%',
    avg_round_19: 9.8,
    app_flat_19: 9,
    char_20: 'Rappa',
    app_rate_20: '2.45%',
    avg_round_20: 8.89,
    app_flat_20: 17,
    char_21: 'silver-wolf',
    app_rate_21: '2.19%',
    avg_round_21: 8.82,
    app_flat_21: 11,
    char_22: 'Sampo',
    app_rate_22: '1.63%',
    avg_round_22: 9.4,
    app_flat_22: 7,
    char_23: 'Moze',
    app_rate_23: '1.19%',
    avg_round_23: 8.67,
    app_flat_23: 12,
    char_24: 'Guinaifen',
    app_rate_24: '1.13%',
    avg_round_24: 9,
    app_flat_24: 7,
    char_25: 'Jade',
    app_rate_25: '1.13%',
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'Jingliu',
    app_rate_26: '0.75%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Asta',
    app_rate_27: '0.63%',
    avg_round_27: 9,
    app_flat_27: 2,
    char_28: 'Clara',
    app_rate_28: '0.63%',
    avg_round_28: 9.75,
    app_flat_28: 8,
    char_29: 'fu-xuan',
    app_rate_29: '0.38%',
    avg_round_29: 8,
    app_flat_29: 1,
    char_30: 'Serval',
    app_rate_30: '0.31%',
    avg_round_30: 9,
    app_flat_30: 3
  },
  {
    char: 'silver-wolf',
    app: 9.1,
    char_1: 'Acheron',
    app_rate_1: '89.31%',
    avg_round_1: 8.6,
    app_flat_1: 348,
    char_2: 'fu-xuan',
    app_rate_2: '51.68%',
    avg_round_2: 8.43,
    app_flat_2: 150,
    char_3: 'Pela',
    app_rate_3: '37.95%',
    avg_round_3: 8.71,
    app_flat_3: 230,
    char_4: 'Sparkle',
    app_rate_4: '32.81%',
    avg_round_4: 9.1,
    app_flat_4: 21,
    char_5: 'Aventurine',
    app_rate_5: '32.6%',
    avg_round_5: 9.1,
    app_flat_5: 162,
    char_6: 'Jiaoqiu',
    app_rate_6: '15.09%',
    avg_round_6: 8.18,
    app_flat_6: 74,
    char_7: 'Gallagher',
    app_rate_7: '5.45%',
    avg_round_7: 8.15,
    app_flat_7: 26,
    char_8: 'Robin',
    app_rate_8: '3.88%',
    avg_round_8: 9.5,
    app_flat_8: 12,
    char_9: 'black-swan',
    app_rate_9: '3.77%',
    avg_round_9: 8.42,
    app_flat_9: 19,
    char_10: 'Huohuo',
    app_rate_10: '3.67%',
    avg_round_10: 8.82,
    app_flat_10: 11,
    char_11: 'Bronya',
    app_rate_11: '2.83%',
    avg_round_11: 9,
    app_flat_11: 1,
    char_12: 'Qingque',
    app_rate_12: '2.83%',
    avg_round_12: 10.0,
    app_flat_12: 4,
    char_13: 'Seele',
    app_rate_13: '2.73%',
    avg_round_13: 9.5,
    app_flat_13: 2,
    char_14: 'Gepard',
    app_rate_14: '2.31%',
    avg_round_14: 8.67,
    app_flat_14: 9,
    char_15: 'dr-ratio',
    app_rate_15: '1.89%',
    avg_round_15: 9,
    app_flat_15: 7,
    char_16: 'Kafka',
    app_rate_16: '1.57%',
    avg_round_16: 8,
    app_flat_16: 2,
    char_17: 'Luocha',
    app_rate_17: '1.26%',
    avg_round_17: 9.5,
    app_flat_17: 2,
    char_18: 'Lingsha',
    app_rate_18: '0.94%',
    avg_round_18: 9.5,
    app_flat_18: 2,
    char_19: 'ruan-mei',
    app_rate_19: '0.84%',
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'Bailu',
    app_rate_20: '0.73%',
    avg_round_20: 9,
    app_flat_20: 3,
    char_21: 'Yunli',
    app_rate_21: '0.52%',
    avg_round_21: 8,
    app_flat_21: 2,
    char_22: 'Tingyun',
    app_rate_22: '0.52%',
    avg_round_22: 7,
    app_flat_22: 1,
    char_23: 'trailblazer-harmony',
    app_rate_23: '0.52%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Lynx',
    app_rate_24: '0.52%',
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'Guinaifen',
    app_rate_25: '0.42%',
    avg_round_25: 9.33,
    app_flat_25: 3,
    char_26: 'topaz',
    app_rate_26: '0.31%',
    avg_round_26: 9.5,
    app_flat_26: 2,
    char_27: 'Firefly',
    app_rate_27: '0.31%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Feixiao',
    app_rate_28: '0.31%',
    avg_round_28: 9.5,
    app_flat_28: 2,
    char_29: 'Blade',
    app_rate_29: '0.21%',
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'Jade',
    app_rate_30: '0.21%',
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'Luocha',
    app: 6.76,
    char_1: 'Robin',
    app_rate_1: '32.2%',
    avg_round_1: 8.79,
    app_flat_1: 105,
    char_2: 'ruan-mei',
    app_rate_2: '30.93%',
    avg_round_2: 9.09,
    app_flat_2: 69,
    char_3: 'black-swan',
    app_rate_3: '25.14%',
    avg_round_3: 8.43,
    app_flat_3: 58,
    char_4: 'Kafka',
    app_rate_4: '23.87%',
    avg_round_4: 8.41,
    app_flat_4: 54,
    char_5: 'Acheron',
    app_rate_5: '22.88%',
    avg_round_5: 8.65,
    app_flat_5: 52,
    char_6: 'Feixiao',
    app_rate_6: '22.6%',
    avg_round_6: 8.69,
    app_flat_6: 89,
    char_7: 'trailblazer-harmony',
    app_rate_7: '17.94%',
    avg_round_7: 9.3,
    app_flat_7: 53,
    char_8: 'Sparkle',
    app_rate_8: '16.67%',
    avg_round_8: 8.93,
    app_flat_8: 14,
    char_9: 'Jiaoqiu',
    app_rate_9: '15.54%',
    avg_round_9: 8.65,
    app_flat_9: 33,
    char_10: 'march-7th-swordmaster',
    app_rate_10: '11.72%',
    avg_round_10: 8.64,
    app_flat_10: 49,
    char_11: 'Firefly',
    app_rate_11: '9.32%',
    avg_round_11: 9.4,
    app_flat_11: 29,
    char_12: 'imbibitor-lunae',
    app_rate_12: '8.76%',
    avg_round_12: 9,
    app_flat_12: 3,
    char_13: 'Bronya',
    app_rate_13: '7.49%',
    avg_round_13: 9.9,
    app_flat_13: 20,
    char_14: 'Pela',
    app_rate_14: '7.2%',
    avg_round_14: 8.85,
    app_flat_14: 23,
    char_15: 'topaz',
    app_rate_15: '7.06%',
    avg_round_15: 8.35,
    app_flat_15: 20,
    char_16: 'Blade',
    app_rate_16: '6.78%',
    avg_round_16: 10.0,
    app_flat_16: 16,
    char_17: 'Rappa',
    app_rate_17: '6.64%',
    avg_round_17: 8.91,
    app_flat_17: 21,
    char_18: 'Tingyun',
    app_rate_18: '4.66%',
    avg_round_18: 9.5,
    app_flat_18: 2,
    char_19: 'Moze',
    app_rate_19: '4.24%',
    avg_round_19: 8.7,
    app_flat_19: 18,
    char_20: 'Yunli',
    app_rate_20: '2.54%',
    avg_round_20: 8.86,
    app_flat_20: 7,
    char_21: 'Himeko',
    app_rate_21: '2.26%',
    avg_round_21: 9.2,
    app_flat_21: 5,
    char_22: 'Jade',
    app_rate_22: '2.26%',
    avg_round_22: 10,
    app_flat_22: 3,
    char_23: 'jing-yuan',
    app_rate_23: '1.84%',
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'silver-wolf',
    app_rate_24: '1.69%',
    avg_round_24: 9.5,
    app_flat_24: 2,
    char_25: 'Guinaifen',
    app_rate_25: '1.27%',
    avg_round_25: 10.0,
    app_flat_25: 6,
    char_26: 'Sampo',
    app_rate_26: '1.27%',
    avg_round_26: 8.6,
    app_flat_26: 5,
    char_27: 'Clara',
    app_rate_27: '1.13%',
    avg_round_27: 9.67,
    app_flat_27: 3,
    char_28: 'Jingliu',
    app_rate_28: '0.85%',
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: 'dr-ratio',
    app_rate_29: '0.71%',
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'Asta',
    app_rate_30: '0.56%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Bronya',
    app: 6.6,
    char_1: 'Acheron',
    app_rate_1: '43.35%',
    avg_round_1: 7.67,
    app_flat_1: 3,
    char_2: 'Feixiao',
    app_rate_2: '28.32%',
    avg_round_2: 8.8,
    app_flat_2: 79,
    char_3: 'Aventurine',
    app_rate_3: '26.3%',
    avg_round_3: 8.79,
    app_flat_3: 57,
    char_4: 'Robin',
    app_rate_4: '22.98%',
    avg_round_4: 7.46,
    app_flat_4: 28,
    char_5: 'Jiaoqiu',
    app_rate_5: '22.98%',
    avg_round_5: 8,
    app_flat_5: 2,
    char_6: 'Pela',
    app_rate_6: '16.33%',
    avg_round_6: 9,
    app_flat_6: 3,
    char_7: 'march-7th-swordmaster',
    app_rate_7: '16.18%',
    avg_round_7: 9.32,
    app_flat_7: 35,
    char_8: 'fu-xuan',
    app_rate_8: '15.46%',
    avg_round_8: 9,
    app_flat_8: 8,
    char_9: 'ruan-mei',
    app_rate_9: '13.01%',
    avg_round_9: 9.29,
    app_flat_9: 7,
    char_10: 'Gallagher',
    app_rate_10: '12.28%',
    avg_round_10: 9.0,
    app_flat_10: 21,
    char_11: 'Blade',
    app_rate_11: '9.97%',
    avg_round_11: 10.0,
    app_flat_11: 20,
    char_12: 'trailblazer-harmony',
    app_rate_12: '8.24%',
    avg_round_12: 8.86,
    app_flat_12: 7,
    char_13: 'Luocha',
    app_rate_13: '7.66%',
    avg_round_13: 9.9,
    app_flat_13: 20,
    char_14: 'Sparkle',
    app_rate_14: '7.37%',
    avg_round_14: 9.83,
    app_flat_14: 10,
    char_15: 'Huohuo',
    app_rate_15: '6.79%',
    avg_round_15: 9.75,
    app_flat_15: 8,
    char_16: 'Firefly',
    app_rate_16: '6.65%',
    avg_round_16: 9,
    app_flat_16: 1,
    char_17: 'topaz',
    app_rate_17: '5.49%',
    avg_round_17: 8.94,
    app_flat_17: 17,
    char_18: 'Boothill',
    app_rate_18: '4.62%',
    avg_round_18: 9.33,
    app_flat_18: 6,
    char_19: 'silver-wolf',
    app_rate_19: '3.9%',
    avg_round_19: 9,
    app_flat_19: 1,
    char_20: 'Jade',
    app_rate_20: '3.61%',
    avg_round_20: 9.75,
    app_flat_20: 8,
    char_21: 'Lingsha',
    app_rate_21: '3.61%',
    avg_round_21: 6.83,
    app_flat_21: 6,
    char_22: 'black-swan',
    app_rate_22: '2.46%',
    avg_round_22: 9.5,
    app_flat_22: 2,
    char_23: 'Jingliu',
    app_rate_23: '2.02%',
    avg_round_23: 9.33,
    app_flat_23: 3,
    char_24: 'Gepard',
    app_rate_24: '1.73%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Moze',
    app_rate_25: '1.59%',
    avg_round_25: 9,
    app_flat_25: 6,
    char_26: 'Bailu',
    app_rate_26: '1.01%',
    avg_round_26: 10,
    app_flat_26: 2,
    char_27: 'Lynx',
    app_rate_27: '0.87%',
    avg_round_27: 8,
    app_flat_27: 1,
    char_28: 'Rappa',
    app_rate_28: '0.72%',
    avg_round_28: 8.6,
    app_flat_28: 5,
    char_29: 'imbibitor-lunae',
    app_rate_29: '0.58%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Welt',
    app_rate_30: '0.58%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Moze',
    app: 6.11,
    char_1: 'Feixiao',
    app_rate_1: '89.22%',
    avg_round_1: 7.68,
    app_flat_1: 328,
    char_2: 'Robin',
    app_rate_2: '87.81%',
    avg_round_2: 7.64,
    app_flat_2: 318,
    char_3: 'Aventurine',
    app_rate_3: '77.66%',
    avg_round_3: 7.75,
    app_flat_3: 288,
    char_4: 'fu-xuan',
    app_rate_4: '5.78%',
    avg_round_4: 8.12,
    app_flat_4: 16,
    char_5: 'dr-ratio',
    app_rate_5: '5.78%',
    avg_round_5: 9.83,
    app_flat_5: 12,
    char_6: 'topaz',
    app_rate_6: '5.78%',
    avg_round_6: 8.05,
    app_flat_6: 20,
    char_7: 'Gallagher',
    app_rate_7: '5.31%',
    avg_round_7: 7.05,
    app_flat_7: 20,
    char_8: 'Luocha',
    app_rate_8: '4.69%',
    avg_round_8: 8.7,
    app_flat_8: 18,
    char_9: 'march-7th-swordmaster',
    app_rate_9: '4.38%',
    avg_round_9: 9.38,
    app_flat_9: 16,
    char_10: 'Huohuo',
    app_rate_10: '2.97%',
    avg_round_10: 8.67,
    app_flat_10: 12,
    char_11: 'Lingsha',
    app_rate_11: '1.88%',
    avg_round_11: 7,
    app_flat_11: 4,
    char_12: 'Bronya',
    app_rate_12: '1.72%',
    avg_round_12: 9,
    app_flat_12: 6,
    char_13: 'ruan-mei',
    app_rate_13: '1.25%',
    avg_round_13: 9.67,
    app_flat_13: 3,
    char_14: 'Sparkle',
    app_rate_14: '1.09%',
    avg_round_14: 8.25,
    app_flat_14: 4,
    char_15: 'Yunli',
    app_rate_15: '0.94%',
    avg_round_15: 8.8,
    app_flat_15: 5,
    char_16: 'Jiaoqiu',
    app_rate_16: '0.94%',
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'Clara',
    app_rate_17: '0.62%',
    avg_round_17: 9.33,
    app_flat_17: 3,
    char_18: 'Blade',
    app_rate_18: '0.47%',
    avg_round_18: 10,
    app_flat_18: 2,
    char_19: 'Tingyun',
    app_rate_19: '0.47%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'black-swan',
    app_rate_20: '0.31%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'silver-wolf',
    app_rate_21: '0.31%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'jing-yuan',
    app_rate_22: '0.16%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'march-7th',
    app_rate_23: '0.16%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Bailu',
    app_rate_24: '0.16%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Gepard',
    app_rate_25: '0.16%',
    avg_round_25: 9,
    app_flat_25: 1,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Himeko',
    app: 5.0,
    char_1: 'trailblazer-harmony',
    app_rate_1: '85.31%',
    avg_round_1: 9.33,
    app_flat_1: 282,
    char_2: 'ruan-mei',
    app_rate_2: '80.34%',
    avg_round_2: 9.29,
    app_flat_2: 253,
    char_3: 'Gallagher',
    app_rate_3: '56.11%',
    avg_round_3: 9.42,
    app_flat_3: 212,
    char_4: 'Lingsha',
    app_rate_4: '23.28%',
    avg_round_4: 8.76,
    app_flat_4: 72,
    char_5: 'Aventurine',
    app_rate_5: '10.69%',
    avg_round_5: 9.62,
    app_flat_5: 23,
    char_6: 'Firefly',
    app_rate_6: '9.35%',
    avg_round_6: 9.5,
    app_flat_6: 18,
    char_7: 'Rappa',
    app_rate_7: '7.44%',
    avg_round_7: 9.6,
    app_flat_7: 20,
    char_8: 'Robin',
    app_rate_8: '5.53%',
    avg_round_8: 9.62,
    app_flat_8: 13,
    char_9: 'topaz',
    app_rate_9: '3.44%',
    avg_round_9: 9.8,
    app_flat_9: 7,
    char_10: 'Jade',
    app_rate_10: '3.24%',
    avg_round_10: 9.33,
    app_flat_10: 6,
    char_11: 'Luocha',
    app_rate_11: '3.05%',
    avg_round_11: 9.2,
    app_flat_11: 5,
    char_12: 'Jiaoqiu',
    app_rate_12: '2.48%',
    avg_round_12: 9.5,
    app_flat_12: 6,
    char_13: 'march-7th-swordmaster',
    app_rate_13: '1.72%',
    avg_round_13: 9.0,
    app_flat_13: 4,
    char_14: 'Asta',
    app_rate_14: '1.15%',
    avg_round_14: 10,
    app_flat_14: 3,
    char_15: 'Sparkle',
    app_rate_15: '1.15%',
    avg_round_15: 7,
    app_flat_15: 1,
    char_16: 'Tingyun',
    app_rate_16: '0.76%',
    avg_round_16: 9,
    app_flat_16: 3,
    char_17: 'Acheron',
    app_rate_17: '0.76%',
    avg_round_17: 9,
    app_flat_17: 2,
    char_18: 'Herta',
    app_rate_18: '0.76%',
    avg_round_18: 10,
    app_flat_18: 2,
    char_19: 'imbibitor-lunae',
    app_rate_19: '0.57%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Pela',
    app_rate_20: '0.57%',
    avg_round_20: 10,
    app_flat_20: 2,
    char_21: 'Huohuo',
    app_rate_21: '0.38%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Guinaifen',
    app_rate_22: '0.38%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'fu-xuan',
    app_rate_23: '0.38%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Feixiao',
    app_rate_24: '0.19%',
    avg_round_24: 9,
    app_flat_24: 1,
    char_25: 'Welt',
    app_rate_25: '0.19%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Bailu',
    app_rate_26: '0.19%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Jingliu',
    app_rate_27: '0.19%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'jing-yuan',
    app_rate_28: '0.19%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Serval',
    app_rate_29: '0.19%',
    avg_round_29: 8,
    app_flat_29: 1,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Tingyun',
    app: 4.05,
    char_1: 'Sparkle',
    app_rate_1: '45.88%',
    avg_round_1: 9.19,
    app_flat_1: 41,
    char_2: 'Huohuo',
    app_rate_2: '40.24%',
    avg_round_2: 8.92,
    app_flat_2: 48,
    char_3: 'Robin',
    app_rate_3: '32.94%',
    avg_round_3: 9.24,
    app_flat_3: 49,
    char_4: 'Yunli',
    app_rate_4: '28.24%',
    avg_round_4: 8.48,
    app_flat_4: 42,
    char_5: 'imbibitor-lunae',
    app_rate_5: '26.35%',
    avg_round_5: 9.6,
    app_flat_5: 9,
    char_6: 'jing-yuan',
    app_rate_6: '24.0%',
    avg_round_6: 9.07,
    app_flat_6: 27,
    char_7: 'Aventurine',
    app_rate_7: '20.24%',
    avg_round_7: 9.59,
    app_flat_7: 31,
    char_8: 'fu-xuan',
    app_rate_8: '14.59%',
    avg_round_8: 8.77,
    app_flat_8: 13,
    char_9: 'Kafka',
    app_rate_9: '7.76%',
    avg_round_9: 9.25,
    app_flat_9: 12,
    char_10: 'ruan-mei',
    app_rate_10: '7.76%',
    avg_round_10: 8.5,
    app_flat_10: 2,
    char_11: 'Luocha',
    app_rate_11: '7.76%',
    avg_round_11: 9.5,
    app_flat_11: 2,
    char_12: 'black-swan',
    app_rate_12: '7.29%',
    avg_round_12: 9.1,
    app_flat_12: 10,
    char_13: 'Gallagher',
    app_rate_13: '7.29%',
    avg_round_13: 9.0,
    app_flat_13: 4,
    char_14: 'Lingsha',
    app_rate_14: '4.24%',
    avg_round_14: 8.18,
    app_flat_14: 11,
    char_15: 'Serval',
    app_rate_15: '1.88%',
    avg_round_15: 9.5,
    app_flat_15: 2,
    char_16: 'Feixiao',
    app_rate_16: '1.88%',
    avg_round_16: 9.5,
    app_flat_16: 2,
    char_17: 'Clara',
    app_rate_17: '1.65%',
    avg_round_17: 10,
    app_flat_17: 4,
    char_18: 'Jade',
    app_rate_18: '1.65%',
    avg_round_18: 6,
    app_flat_18: 2,
    char_19: 'Rappa',
    app_rate_19: '1.41%',
    avg_round_19: 9,
    app_flat_19: 3,
    char_20: 'trailblazer-harmony',
    app_rate_20: '1.41%',
    avg_round_20: 8.75,
    app_flat_20: 4,
    char_21: 'march-7th-swordmaster',
    app_rate_21: '1.41%',
    avg_round_21: 8.5,
    app_flat_21: 2,
    char_22: 'Jiaoqiu',
    app_rate_22: '1.18%',
    avg_round_22: 9,
    app_flat_22: 2,
    char_23: 'silver-wolf',
    app_rate_23: '1.18%',
    avg_round_23: 7,
    app_flat_23: 1,
    char_24: 'Bailu',
    app_rate_24: '0.94%',
    avg_round_24: 9.5,
    app_flat_24: 2,
    char_25: 'Himeko',
    app_rate_25: '0.94%',
    avg_round_25: 9,
    app_flat_25: 3,
    char_26: 'Jingliu',
    app_rate_26: '0.94%',
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'Hanya',
    app_rate_27: '0.94%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Acheron',
    app_rate_28: '0.71%',
    avg_round_28: 9,
    app_flat_28: 2,
    char_29: 'dr-ratio',
    app_rate_29: '0.71%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Moze',
    app_rate_30: '0.71%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Yunli',
    app: 3.2,
    char_1: 'Robin',
    app_rate_1: '79.7%',
    avg_round_1: 9.0,
    app_flat_1: 109,
    char_2: 'Huohuo',
    app_rate_2: '48.36%',
    avg_round_2: 8.44,
    app_flat_2: 63,
    char_3: 'Tingyun',
    app_rate_3: '35.82%',
    avg_round_3: 8.48,
    app_flat_3: 42,
    char_4: 'Sparkle',
    app_rate_4: '24.78%',
    avg_round_4: 8.46,
    app_flat_4: 24,
    char_5: 'Aventurine',
    app_rate_5: '22.09%',
    avg_round_5: 9.56,
    app_flat_5: 32,
    char_6: 'Feixiao',
    app_rate_6: '20.6%',
    avg_round_6: 9.11,
    app_flat_6: 34,
    char_7: 'topaz',
    app_rate_7: '16.42%',
    avg_round_7: 9.0,
    app_flat_7: 19,
    char_8: 'fu-xuan',
    app_rate_8: '12.54%',
    avg_round_8: 8.45,
    app_flat_8: 20,
    char_9: 'march-7th-swordmaster',
    app_rate_9: '8.96%',
    avg_round_9: 8.78,
    app_flat_9: 17,
    char_10: 'Luocha',
    app_rate_10: '5.37%',
    avg_round_10: 8.86,
    app_flat_10: 7,
    char_11: 'ruan-mei',
    app_rate_11: '4.48%',
    avg_round_11: 8,
    app_flat_11: 3,
    char_12: 'Lingsha',
    app_rate_12: '2.69%',
    avg_round_12: 9,
    app_flat_12: 3,
    char_13: 'Gallagher',
    app_rate_13: '2.69%',
    avg_round_13: 9.0,
    app_flat_13: 4,
    char_14: 'Moze',
    app_rate_14: '1.79%',
    avg_round_14: 8.8,
    app_flat_14: 5,
    char_15: 'Lynx',
    app_rate_15: '1.79%',
    avg_round_15: 9.5,
    app_flat_15: 2,
    char_16: 'silver-wolf',
    app_rate_16: '1.49%',
    avg_round_16: 8,
    app_flat_16: 2,
    char_17: 'Jade',
    app_rate_17: '1.49%',
    avg_round_17: 8.5,
    app_flat_17: 2,
    char_18: 'Jiaoqiu',
    app_rate_18: '1.19%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Clara',
    app_rate_19: '1.19%',
    avg_round_19: 9,
    app_flat_19: 1,
    char_20: 'Acheron',
    app_rate_20: '0.9%',
    avg_round_20: 10,
    app_flat_20: 1,
    char_21: 'Bailu',
    app_rate_21: '0.9%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Blade',
    app_rate_22: '0.9%',
    avg_round_22: 8,
    app_flat_22: 2,
    char_23: 'Pela',
    app_rate_23: '0.6%',
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'Hanya',
    app_rate_24: '0.6%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'imbibitor-lunae',
    app_rate_25: '0.6%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Jingliu',
    app_rate_26: '0.3%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Kafka',
    app_rate_27: '0.3%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'jing-yuan',
    app_rate_28: '0.3%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'march-7th',
    app_rate_29: '0.3%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Bronya',
    app_rate_30: '0.3%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'imbibitor-lunae',
    app: 2.99,
    char_1: 'Sparkle',
    app_rate_1: '86.9%',
    avg_round_1: 9.36,
    app_flat_1: 21,
    char_2: 'ruan-mei',
    app_rate_2: '43.45%',
    avg_round_2: 9.12,
    app_flat_2: 14,
    char_3: 'Tingyun',
    app_rate_3: '35.78%',
    avg_round_3: 9.6,
    app_flat_3: 9,
    char_4: 'Huohuo',
    app_rate_4: '26.84%',
    avg_round_4: 9,
    app_flat_4: 3,
    char_5: 'Aventurine',
    app_rate_5: '21.73%',
    avg_round_5: 9.4,
    app_flat_5: 7,
    char_6: 'Luocha',
    app_rate_6: '19.81%',
    avg_round_6: 9,
    app_flat_6: 3,
    char_7: 'Gallagher',
    app_rate_7: '18.85%',
    avg_round_7: 8.33,
    app_flat_7: 6,
    char_8: 'Robin',
    app_rate_8: '15.02%',
    avg_round_8: 9,
    app_flat_8: 1,
    char_9: 'fu-xuan',
    app_rate_9: '7.99%',
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'Jiaoqiu',
    app_rate_10: '7.35%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'trailblazer-harmony',
    app_rate_11: '3.83%',
    avg_round_11: 10,
    app_flat_11: 3,
    char_12: 'Lingsha',
    app_rate_12: '3.19%',
    avg_round_12: 8.67,
    app_flat_12: 3,
    char_13: 'Bronya',
    app_rate_13: '1.28%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'march-7th-swordmaster',
    app_rate_14: '1.28%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Pela',
    app_rate_15: '1.28%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Hanya',
    app_rate_16: '1.28%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Himeko',
    app_rate_17: '0.96%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Bailu',
    app_rate_18: '0.64%',
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'silver-wolf',
    app_rate_19: '0.64%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Yunli',
    app_rate_20: '0.64%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Jingliu',
    app_rate_21: '0.32%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Asta',
    app_rate_22: '0.32%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Jade',
    app_rate_23: '0.32%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Yukong',
    app_rate_24: '0.32%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    app: 2.33,
    char_1: 'Aventurine',
    app_rate_1: '91.39%',
    avg_round_1: 9.42,
    app_flat_1: 61,
    char_2: 'Robin',
    app_rate_2: '87.7%',
    avg_round_2: 9.45,
    app_flat_2: 61,
    char_3: 'topaz',
    app_rate_3: '62.3%',
    avg_round_3: 9.38,
    app_flat_3: 39,
    char_4: 'Moze',
    app_rate_4: '15.16%',
    avg_round_4: 9.83,
    app_flat_4: 12,
    char_5: 'silver-wolf',
    app_rate_5: '7.38%',
    avg_round_5: 9,
    app_flat_5: 7,
    char_6: 'march-7th-swordmaster',
    app_rate_6: '6.56%',
    avg_round_6: 9,
    app_flat_6: 4,
    char_7: 'Jiaoqiu',
    app_rate_7: '6.15%',
    avg_round_7: 10,
    app_flat_7: 1,
    char_8: 'ruan-mei',
    app_rate_8: '5.74%',
    avg_round_8: 9.33,
    app_flat_8: 3,
    char_9: 'Feixiao',
    app_rate_9: '2.46%',
    avg_round_9: 8,
    app_flat_9: 2,
    char_10: 'Sparkle',
    app_rate_10: '2.46%',
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'Gallagher',
    app_rate_11: '2.46%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Luocha',
    app_rate_12: '2.05%',
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'fu-xuan',
    app_rate_13: '2.05%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Huohuo',
    app_rate_14: '1.23%',
    avg_round_14: 10,
    app_flat_14: 2,
    char_15: 'Tingyun',
    app_rate_15: '1.23%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Lingsha',
    app_rate_16: '1.23%',
    avg_round_16: 9,
    app_flat_16: 1,
    char_17: 'Bronya',
    app_rate_17: '0.82%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Pela',
    app_rate_18: '0.41%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Lynx',
    app_rate_19: '0.41%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Blade',
    app_rate_20: '0.41%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'trailblazer-harmony',
    app_rate_21: '0.41%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Jade',
    app: 1.77,
    char_1: 'Robin',
    app_rate_1: '58.06%',
    avg_round_1: 7.73,
    app_flat_1: 15,
    char_2: 'Aventurine',
    app_rate_2: '36.02%',
    avg_round_2: 9.75,
    app_flat_2: 8,
    char_3: 'Feixiao',
    app_rate_3: '32.8%',
    avg_round_3: 8,
    app_flat_3: 2,
    char_4: 'Lingsha',
    app_rate_4: '26.34%',
    avg_round_4: 7.19,
    app_flat_4: 16,
    char_5: 'ruan-mei',
    app_rate_5: '21.51%',
    avg_round_5: 8,
    app_flat_5: 11,
    char_6: 'Blade',
    app_rate_6: '15.59%',
    avg_round_6: 10,
    app_flat_6: 8,
    char_7: 'Bronya',
    app_rate_7: '13.44%',
    avg_round_7: 9.75,
    app_flat_7: 8,
    char_8: 'Sparkle',
    app_rate_8: '12.9%',
    avg_round_8: 9.5,
    app_flat_8: 2,
    char_9: 'Huohuo',
    app_rate_9: '9.68%',
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'Himeko',
    app_rate_10: '9.14%',
    avg_round_10: 9.33,
    app_flat_10: 6,
    char_11: 'fu-xuan',
    app_rate_11: '8.6%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Luocha',
    app_rate_12: '8.6%',
    avg_round_12: 10,
    app_flat_12: 3,
    char_13: 'topaz',
    app_rate_13: '8.06%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'trailblazer-harmony',
    app_rate_14: '7.53%',
    avg_round_14: 8,
    app_flat_14: 7,
    char_15: 'Gallagher',
    app_rate_15: '6.99%',
    avg_round_15: 8.5,
    app_flat_15: 4,
    char_16: 'Tingyun',
    app_rate_16: '3.76%',
    avg_round_16: 6,
    app_flat_16: 2,
    char_17: 'jing-yuan',
    app_rate_17: '2.69%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Yunli',
    app_rate_18: '2.69%',
    avg_round_18: 8.5,
    app_flat_18: 2,
    char_19: 'Jiaoqiu',
    app_rate_19: '2.15%',
    avg_round_19: 6,
    app_flat_19: 1,
    char_20: 'Herta',
    app_rate_20: '1.61%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'march-7th-swordmaster',
    app_rate_21: '1.61%',
    avg_round_21: 7,
    app_flat_21: 1,
    char_22: 'Rappa',
    app_rate_22: '1.61%',
    avg_round_22: 6,
    app_flat_22: 1,
    char_23: 'silver-wolf',
    app_rate_23: '1.08%',
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'Clara',
    app_rate_24: '1.08%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Jingliu',
    app_rate_25: '1.08%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Lynx',
    app_rate_26: '1.08%',
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'Bailu',
    app_rate_27: '1.08%',
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'Asta',
    app_rate_28: '0.54%',
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: 'Pela',
    app_rate_29: '0.54%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Kafka',
    app_rate_30: '0.54%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'jing-yuan',
    app: 1.64,
    char_1: 'Tingyun',
    app_rate_1: '59.3%',
    avg_round_1: 9.07,
    app_flat_1: 27,
    char_2: 'Sparkle',
    app_rate_2: '54.07%',
    avg_round_2: 9.41,
    app_flat_2: 31,
    char_3: 'Robin',
    app_rate_3: '51.16%',
    avg_round_3: 9.19,
    app_flat_3: 27,
    char_4: 'Aventurine',
    app_rate_4: '30.81%',
    avg_round_4: 9.19,
    app_flat_4: 21,
    char_5: 'Huohuo',
    app_rate_5: '25.0%',
    avg_round_5: 9.29,
    app_flat_5: 11,
    char_6: 'fu-xuan',
    app_rate_6: '23.26%',
    avg_round_6: 9,
    app_flat_6: 7,
    char_7: 'ruan-mei',
    app_rate_7: '12.21%',
    avg_round_7: 7.6,
    app_flat_7: 5,
    char_8: 'Luocha',
    app_rate_8: '7.56%',
    avg_round_8: 10,
    app_flat_8: 1,
    char_9: 'Gallagher',
    app_rate_9: '6.4%',
    avg_round_9: 9.33,
    app_flat_9: 3,
    char_10: 'Lingsha',
    app_rate_10: '5.81%',
    avg_round_10: 7.67,
    app_flat_10: 6,
    char_11: 'topaz',
    app_rate_11: '3.49%',
    avg_round_11: 10,
    app_flat_11: 3,
    char_12: 'Feixiao',
    app_rate_12: '3.49%',
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'Jade',
    app_rate_13: '2.91%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'trailblazer-harmony',
    app_rate_14: '2.91%',
    avg_round_14: 7,
    app_flat_14: 4,
    char_15: 'Jiaoqiu',
    app_rate_15: '2.91%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Bronya',
    app_rate_16: '2.33%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Blade',
    app_rate_17: '1.74%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '1.16%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Moze',
    app_rate_19: '0.58%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Hanya',
    app_rate_20: '0.58%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Yunli',
    app_rate_21: '0.58%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Himeko',
    app_rate_22: '0.58%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Sampo',
    app_rate_23: '0.58%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Asta',
    app_rate_24: '0.58%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Guinaifen',
    app: 1.37,
    char_1: 'Acheron',
    app_rate_1: '69.44%',
    avg_round_1: 9.34,
    app_flat_1: 64,
    char_2: 'Pela',
    app_rate_2: '36.81%',
    avg_round_2: 9.38,
    app_flat_2: 41,
    char_3: 'Aventurine',
    app_rate_3: '34.72%',
    avg_round_3: 9.27,
    app_flat_3: 29,
    char_4: 'black-swan',
    app_rate_4: '27.08%',
    avg_round_4: 9.5,
    app_flat_4: 12,
    char_5: 'Gallagher',
    app_rate_5: '23.61%',
    avg_round_5: 9,
    app_flat_5: 20,
    char_6: 'Kafka',
    app_rate_6: '20.14%',
    avg_round_6: 8.83,
    app_flat_6: 6,
    char_7: 'fu-xuan',
    app_rate_7: '15.28%',
    avg_round_7: 9.8,
    app_flat_7: 9,
    char_8: 'ruan-mei',
    app_rate_8: '14.58%',
    avg_round_8: 9.67,
    app_flat_8: 10,
    char_9: 'Huohuo',
    app_rate_9: '12.5%',
    avg_round_9: 9,
    app_flat_9: 7,
    char_10: 'trailblazer-harmony',
    app_rate_10: '11.11%',
    avg_round_10: 9.22,
    app_flat_10: 9,
    char_11: 'Jiaoqiu',
    app_rate_11: '10.42%',
    avg_round_11: 8.25,
    app_flat_11: 6,
    char_12: 'Luocha',
    app_rate_12: '6.25%',
    avg_round_12: 10.0,
    app_flat_12: 6,
    char_13: 'Lingsha',
    app_rate_13: '3.47%',
    avg_round_13: 9.33,
    app_flat_13: 3,
    char_14: 'Sparkle',
    app_rate_14: '2.78%',
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'silver-wolf',
    app_rate_15: '2.78%',
    avg_round_15: 9.33,
    app_flat_15: 3,
    char_16: 'Gepard',
    app_rate_16: '2.08%',
    avg_round_16: 9,
    app_flat_16: 1,
    char_17: 'Robin',
    app_rate_17: '1.39%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Himeko',
    app_rate_18: '1.39%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Bailu',
    app_rate_19: '1.39%',
    avg_round_19: 9,
    app_flat_19: 1,
    char_20: 'Bronya',
    app_rate_20: '0.69%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Firefly',
    app_rate_21: '0.69%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Asta',
    app_rate_22: '0.69%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Sampo',
    app_rate_23: '0.69%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Blade',
    app: 1.28,
    char_1: 'Bronya',
    app_rate_1: '51.49%',
    avg_round_1: 10.0,
    app_flat_1: 20,
    char_2: 'Robin',
    app_rate_2: '41.04%',
    avg_round_2: 9.89,
    app_flat_2: 17,
    char_3: 'Luocha',
    app_rate_3: '35.82%',
    avg_round_3: 10.0,
    app_flat_3: 16,
    char_4: 'Sparkle',
    app_rate_4: '32.84%',
    avg_round_4: 9.83,
    app_flat_4: 12,
    char_5: 'Huohuo',
    app_rate_5: '29.85%',
    avg_round_5: 9.8,
    app_flat_5: 9,
    char_6: 'Jade',
    app_rate_6: '21.64%',
    avg_round_6: 10,
    app_flat_6: 8,
    char_7: 'ruan-mei',
    app_rate_7: '16.42%',
    avg_round_7: 10.0,
    app_flat_7: 4,
    char_8: 'fu-xuan',
    app_rate_8: '14.93%',
    avg_round_8: 10,
    app_flat_8: 3,
    char_9: 'march-7th-swordmaster',
    app_rate_9: '10.45%',
    avg_round_9: 10.0,
    app_flat_9: 4,
    char_10: 'Gallagher',
    app_rate_10: '8.21%',
    avg_round_10: 9,
    app_flat_10: 4,
    char_11: 'Lynx',
    app_rate_11: '5.97%',
    avg_round_11: 8.5,
    app_flat_11: 2,
    char_12: 'Jingliu',
    app_rate_12: '4.48%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Jiaoqiu',
    app_rate_13: '2.99%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'black-swan',
    app_rate_14: '2.24%',
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'Clara',
    app_rate_15: '2.24%',
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'Moze',
    app_rate_16: '2.24%',
    avg_round_16: 10,
    app_flat_16: 2,
    char_17: 'Feixiao',
    app_rate_17: '2.24%',
    avg_round_17: 10,
    app_flat_17: 2,
    char_18: 'jing-yuan',
    app_rate_18: '2.24%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Yunli',
    app_rate_19: '2.24%',
    avg_round_19: 8,
    app_flat_19: 2,
    char_20: 'silver-wolf',
    app_rate_20: '1.49%',
    avg_round_20: 10,
    app_flat_20: 1,
    char_21: 'Kafka',
    app_rate_21: '1.49%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Aventurine',
    app_rate_22: '1.49%',
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'Bailu',
    app_rate_23: '1.49%',
    avg_round_23: 10,
    app_flat_23: 2,
    char_24: 'Lingsha',
    app_rate_24: '1.49%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Seele',
    app_rate_25: '0.75%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'dr-ratio',
    app_rate_26: '0.75%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Acheron',
    app_rate_27: '0.75%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Tingyun',
    app_rate_28: '0.75%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Gepard',
    app: 1.15,
    char_1: 'Acheron',
    app_rate_1: '86.78%',
    avg_round_1: 8.79,
    app_flat_1: 34,
    char_2: 'Pela',
    app_rate_2: '40.5%',
    avg_round_2: 9.0,
    app_flat_2: 21,
    char_3: 'black-swan',
    app_rate_3: '36.36%',
    avg_round_3: 9.33,
    app_flat_3: 15,
    char_4: 'Kafka',
    app_rate_4: '25.62%',
    avg_round_4: 8.91,
    app_flat_4: 11,
    char_5: 'Sparkle',
    app_rate_5: '22.31%',
    avg_round_5: 9,
    app_flat_5: 4,
    char_6: 'Jiaoqiu',
    app_rate_6: '21.49%',
    avg_round_6: 8.5,
    app_flat_6: 10,
    char_7: 'silver-wolf',
    app_rate_7: '18.18%',
    avg_round_7: 8.67,
    app_flat_7: 9,
    char_8: 'Robin',
    app_rate_8: '12.4%',
    avg_round_8: 8.6,
    app_flat_8: 7,
    char_9: 'Bronya',
    app_rate_9: '9.92%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'ruan-mei',
    app_rate_10: '6.61%',
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'Feixiao',
    app_rate_11: '4.13%',
    avg_round_11: 8.25,
    app_flat_11: 4,
    char_12: 'Guinaifen',
    app_rate_12: '2.48%',
    avg_round_12: 9,
    app_flat_12: 1,
    char_13: 'march-7th-swordmaster',
    app_rate_13: '2.48%',
    avg_round_13: 8,
    app_flat_13: 3,
    char_14: 'trailblazer-harmony',
    app_rate_14: '1.65%',
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'Welt',
    app_rate_15: '1.65%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Tingyun',
    app_rate_16: '1.65%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Gallagher',
    app_rate_17: '1.65%',
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'Rappa',
    app_rate_18: '0.83%',
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'Sampo',
    app_rate_19: '0.83%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Firefly',
    app_rate_20: '0.83%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Hanya',
    app_rate_21: '0.83%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Moze',
    app_rate_22: '0.83%',
    avg_round_22: 9,
    app_flat_22: 1,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Boothill',
    app: 1.14,
    char_1: 'ruan-mei',
    app_rate_1: '93.28%',
    avg_round_1: 9.14,
    app_flat_1: 56,
    char_2: 'trailblazer-harmony',
    app_rate_2: '78.99%',
    avg_round_2: 9.17,
    app_flat_2: 55,
    char_3: 'Gallagher',
    app_rate_3: '59.66%',
    avg_round_3: 9.16,
    app_flat_3: 47,
    char_4: 'Bronya',
    app_rate_4: '26.89%',
    avg_round_4: 9.33,
    app_flat_4: 6,
    char_5: 'Lingsha',
    app_rate_5: '15.97%',
    avg_round_5: 8.91,
    app_flat_5: 11,
    char_6: 'Aventurine',
    app_rate_6: '6.72%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Acheron',
    app_rate_7: '2.52%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Rappa',
    app_rate_8: '2.52%',
    avg_round_8: 9.5,
    app_flat_8: 2,
    char_9: 'Pela',
    app_rate_9: '1.68%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'march-7th-swordmaster',
    app_rate_10: '1.68%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Huohuo',
    app_rate_11: '1.68%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'fu-xuan',
    app_rate_12: '1.68%',
    avg_round_12: 7,
    app_flat_12: 1,
    char_13: 'Luocha',
    app_rate_13: '1.68%',
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'Jiaoqiu',
    app_rate_14: '1.68%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'silver-wolf',
    app_rate_15: '0.84%',
    avg_round_15: 9,
    app_flat_15: 1,
    char_16: 'Tingyun',
    app_rate_16: '0.84%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Firefly',
    app_rate_17: '0.84%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Feixiao',
    app_rate_18: '0.84%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Bailu',
    app: 0.89,
    char_1: 'black-swan',
    app_rate_1: '44.09%',
    avg_round_1: 9,
    app_flat_1: 13,
    char_2: 'Kafka',
    app_rate_2: '43.01%',
    avg_round_2: 9,
    app_flat_2: 14,
    char_3: 'Acheron',
    app_rate_3: '38.71%',
    avg_round_3: 8.9,
    app_flat_3: 10,
    char_4: 'Robin',
    app_rate_4: '34.41%',
    avg_round_4: 9.33,
    app_flat_4: 15,
    char_5: 'Feixiao',
    app_rate_5: '22.58%',
    avg_round_5: 9.43,
    app_flat_5: 13,
    char_6: 'ruan-mei',
    app_rate_6: '17.2%',
    avg_round_6: 9,
    app_flat_6: 7,
    char_7: 'march-7th-swordmaster',
    app_rate_7: '12.9%',
    avg_round_7: 9.33,
    app_flat_7: 10,
    char_8: 'Jiaoqiu',
    app_rate_8: '11.83%',
    avg_round_8: 8.5,
    app_flat_8: 4,
    char_9: 'Sparkle',
    app_rate_9: '10.75%',
    avg_round_9: 10,
    app_flat_9: 2,
    char_10: 'trailblazer-harmony',
    app_rate_10: '9.68%',
    avg_round_10: 9,
    app_flat_10: 3,
    char_11: 'Pela',
    app_rate_11: '8.6%',
    avg_round_11: 8.33,
    app_flat_11: 5,
    char_12: 'Bronya',
    app_rate_12: '7.53%',
    avg_round_12: 10,
    app_flat_12: 2,
    char_13: 'silver-wolf',
    app_rate_13: '7.53%',
    avg_round_13: 9,
    app_flat_13: 3,
    char_14: 'Firefly',
    app_rate_14: '6.45%',
    avg_round_14: 9.67,
    app_flat_14: 3,
    char_15: 'Tingyun',
    app_rate_15: '4.3%',
    avg_round_15: 9.5,
    app_flat_15: 2,
    char_16: 'Rappa',
    app_rate_16: '3.23%',
    avg_round_16: 8,
    app_flat_16: 1,
    char_17: 'Yunli',
    app_rate_17: '3.23%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'imbibitor-lunae',
    app_rate_18: '2.15%',
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'topaz',
    app_rate_19: '2.15%',
    avg_round_19: 10,
    app_flat_19: 2,
    char_20: 'Blade',
    app_rate_20: '2.15%',
    avg_round_20: 10,
    app_flat_20: 2,
    char_21: 'Guinaifen',
    app_rate_21: '2.15%',
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: 'Jade',
    app_rate_22: '2.15%',
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'Asta',
    app_rate_23: '1.08%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Himeko',
    app_rate_24: '1.08%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Moze',
    app_rate_25: '1.08%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sampo',
    app: 0.73,
    char_1: 'Kafka',
    app_rate_1: '59.21%',
    avg_round_1: 9.8,
    app_flat_1: 20,
    char_2: 'black-swan',
    app_rate_2: '39.47%',
    avg_round_2: 9,
    app_flat_2: 12,
    char_3: 'Acheron',
    app_rate_3: '38.16%',
    avg_round_3: 9.62,
    app_flat_3: 16,
    char_4: 'Huohuo',
    app_rate_4: '34.21%',
    avg_round_4: 9.4,
    app_flat_4: 7,
    char_5: 'Aventurine',
    app_rate_5: '22.37%',
    avg_round_5: 9.31,
    app_flat_5: 13,
    char_6: 'fu-xuan',
    app_rate_6: '21.05%',
    avg_round_6: 10,
    app_flat_6: 6,
    char_7: 'Jiaoqiu',
    app_rate_7: '15.79%',
    avg_round_7: 8.67,
    app_flat_7: 6,
    char_8: 'Robin',
    app_rate_8: '14.47%',
    avg_round_8: 10.0,
    app_flat_8: 5,
    char_9: 'ruan-mei',
    app_rate_9: '14.47%',
    avg_round_9: 9.67,
    app_flat_9: 3,
    char_10: 'Luocha',
    app_rate_10: '11.84%',
    avg_round_10: 8.6,
    app_flat_10: 5,
    char_11: 'Gallagher',
    app_rate_11: '10.53%',
    avg_round_11: 9,
    app_flat_11: 1,
    char_12: 'Sparkle',
    app_rate_12: '3.95%',
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'silver-wolf',
    app_rate_13: '2.63%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Pela',
    app_rate_14: '2.63%',
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '1.32%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Gepard',
    app_rate_16: '1.32%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Tingyun',
    app_rate_17: '1.32%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'trailblazer-harmony',
    app_rate_18: '1.32%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'jing-yuan',
    app_rate_19: '1.32%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Bronya',
    app_rate_20: '1.32%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Guinaifen',
    app_rate_21: '1.32%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Clara',
    app: 0.59,
    char_1: 'Robin',
    app_rate_1: '75.81%',
    avg_round_1: 9.82,
    app_flat_1: 21,
    char_2: 'fu-xuan',
    app_rate_2: '35.48%',
    avg_round_2: 10.0,
    app_flat_2: 6,
    char_3: 'Sparkle',
    app_rate_3: '25.81%',
    avg_round_3: 9.5,
    app_flat_3: 6,
    char_4: 'Aventurine',
    app_rate_4: '24.19%',
    avg_round_4: 8.5,
    app_flat_4: 6,
    char_5: 'march-7th-swordmaster',
    app_rate_5: '20.97%',
    avg_round_5: 9.75,
    app_flat_5: 8,
    char_6: 'Feixiao',
    app_rate_6: '17.74%',
    avg_round_6: 9,
    app_flat_6: 6,
    char_7: 'topaz',
    app_rate_7: '17.74%',
    avg_round_7: 8.33,
    app_flat_7: 3,
    char_8: 'Huohuo',
    app_rate_8: '16.13%',
    avg_round_8: 9.75,
    app_flat_8: 8,
    char_9: 'Luocha',
    app_rate_9: '12.9%',
    avg_round_9: 9.67,
    app_flat_9: 3,
    char_10: 'Tingyun',
    app_rate_10: '11.29%',
    avg_round_10: 10,
    app_flat_10: 4,
    char_11: 'Yunli',
    app_rate_11: '6.45%',
    avg_round_11: 9,
    app_flat_11: 1,
    char_12: 'Moze',
    app_rate_12: '6.45%',
    avg_round_12: 9.33,
    app_flat_12: 3,
    char_13: 'Gallagher',
    app_rate_13: '6.45%',
    avg_round_13: 9.33,
    app_flat_13: 3,
    char_14: 'Blade',
    app_rate_14: '4.84%',
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'Lynx',
    app_rate_15: '3.23%',
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'black-swan',
    app_rate_16: '3.23%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Jade',
    app_rate_17: '3.23%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Kafka',
    app_rate_18: '1.61%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Lingsha',
    app_rate_19: '1.61%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Herta',
    app_rate_20: '1.61%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Bronya',
    app_rate_21: '1.61%',
    avg_round_21: 8,
    app_flat_21: 1,
    char_22: 'Acheron',
    app_rate_22: '1.61%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Welt',
    app: 0.57,
    char_1: 'Acheron',
    app_rate_1: '76.67%',
    avg_round_1: 9.67,
    app_flat_1: 12,
    char_2: 'Pela',
    app_rate_2: '35.0%',
    avg_round_2: 9.25,
    app_flat_2: 6,
    char_3: 'Sparkle',
    app_rate_3: '31.67%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'ruan-mei',
    app_rate_4: '25.0%',
    avg_round_4: 9.5,
    app_flat_4: 2,
    char_5: 'trailblazer-harmony',
    app_rate_5: '18.33%',
    avg_round_5: 9.25,
    app_flat_5: 4,
    char_6: 'fu-xuan',
    app_rate_6: '18.33%',
    avg_round_6: 9.0,
    app_flat_6: 4,
    char_7: 'Gallagher',
    app_rate_7: '16.67%',
    avg_round_7: 9.4,
    app_flat_7: 7,
    char_8: 'Jiaoqiu',
    app_rate_8: '15.0%',
    avg_round_8: 10.0,
    app_flat_8: 5,
    char_9: 'Aventurine',
    app_rate_9: '13.33%',
    avg_round_9: 9.5,
    app_flat_9: 2,
    char_10: 'Bronya',
    app_rate_10: '6.67%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Firefly',
    app_rate_11: '6.67%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Lingsha',
    app_rate_12: '5.0%',
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'Feixiao',
    app_rate_13: '3.33%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Robin',
    app_rate_14: '3.33%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Gepard',
    app_rate_15: '3.33%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Luocha',
    app_rate_16: '3.33%',
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'black-swan',
    app_rate_17: '3.33%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'silver-wolf',
    app_rate_18: '3.33%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Kafka',
    app_rate_19: '3.33%',
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'Huohuo',
    app_rate_20: '3.33%',
    avg_round_20: 10,
    app_flat_20: 1,
    char_21: 'march-7th-swordmaster',
    app_rate_21: '1.67%',
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'Himeko',
    app_rate_22: '1.67%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Rappa',
    app_rate_23: '1.67%',
    avg_round_23: 8,
    app_flat_23: 1,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Asta',
    app: 0.51,
    char_1: 'trailblazer-harmony',
    app_rate_1: '45.28%',
    avg_round_1: 9.78,
    app_flat_1: 15,
    char_2: 'Gallagher',
    app_rate_2: '32.08%',
    avg_round_2: 9.83,
    app_flat_2: 12,
    char_3: 'Kafka',
    app_rate_3: '30.19%',
    avg_round_3: 9.5,
    app_flat_3: 4,
    char_4: 'black-swan',
    app_rate_4: '30.19%',
    avg_round_4: 9.5,
    app_flat_4: 4,
    char_5: 'Firefly',
    app_rate_5: '24.53%',
    avg_round_5: 9.33,
    app_flat_5: 6,
    char_6: 'Lingsha',
    app_rate_6: '20.75%',
    avg_round_6: 10.0,
    app_flat_6: 6,
    char_7: 'Rappa',
    app_rate_7: '20.75%',
    avg_round_7: 10.0,
    app_flat_7: 8,
    char_8: 'Huohuo',
    app_rate_8: '18.87%',
    avg_round_8: 9,
    app_flat_8: 2,
    char_9: 'Aventurine',
    app_rate_9: '13.21%',
    avg_round_9: 9.67,
    app_flat_9: 3,
    char_10: 'ruan-mei',
    app_rate_10: '11.32%',
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'Himeko',
    app_rate_11: '11.32%',
    avg_round_11: 10,
    app_flat_11: 3,
    char_12: 'Luocha',
    app_rate_12: '7.55%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Feixiao',
    app_rate_13: '3.77%',
    avg_round_13: 9.5,
    app_flat_13: 2,
    char_14: 'march-7th-swordmaster',
    app_rate_14: '3.77%',
    avg_round_14: 10,
    app_flat_14: 2,
    char_15: 'Tingyun',
    app_rate_15: '3.77%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'topaz',
    app_rate_16: '3.77%',
    avg_round_16: 9,
    app_flat_16: 1,
    char_17: 'fu-xuan',
    app_rate_17: '1.89%',
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'Bailu',
    app_rate_18: '1.89%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Jade',
    app_rate_19: '1.89%',
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'imbibitor-lunae',
    app_rate_20: '1.89%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Robin',
    app_rate_21: '1.89%',
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'Guinaifen',
    app_rate_22: '1.89%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Jiaoqiu',
    app_rate_23: '1.89%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Acheron',
    app_rate_24: '1.89%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Pela',
    app_rate_25: '1.89%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'jing-yuan',
    app_rate_26: '1.89%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lynx',
    app: 0.37,
    char_1: 'Robin',
    app_rate_1: '43.59%',
    avg_round_1: 9.2,
    app_flat_1: 9,
    char_2: 'black-swan',
    app_rate_2: '25.64%',
    avg_round_2: 9.33,
    app_flat_2: 5,
    char_3: 'ruan-mei',
    app_rate_3: '23.08%',
    avg_round_3: 9,
    app_flat_3: 3,
    char_4: 'Sparkle',
    app_rate_4: '23.08%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Kafka',
    app_rate_5: '23.08%',
    avg_round_5: 9.5,
    app_flat_5: 4,
    char_6: 'Blade',
    app_rate_6: '20.51%',
    avg_round_6: 8.5,
    app_flat_6: 2,
    char_7: 'march-7th-swordmaster',
    app_rate_7: '17.95%',
    avg_round_7: 9.5,
    app_flat_7: 4,
    char_8: 'Yunli',
    app_rate_8: '15.38%',
    avg_round_8: 9.5,
    app_flat_8: 2,
    char_9: 'Feixiao',
    app_rate_9: '15.38%',
    avg_round_9: 8.5,
    app_flat_9: 4,
    char_10: 'Bronya',
    app_rate_10: '15.38%',
    avg_round_10: 8,
    app_flat_10: 1,
    char_11: 'silver-wolf',
    app_rate_11: '12.82%',
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: 'trailblazer-harmony',
    app_rate_12: '10.26%',
    avg_round_12: 10,
    app_flat_12: 2,
    char_13: 'Acheron',
    app_rate_13: '10.26%',
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'Rappa',
    app_rate_14: '5.13%',
    avg_round_14: 10,
    app_flat_14: 2,
    char_15: 'Clara',
    app_rate_15: '5.13%',
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'Firefly',
    app_rate_16: '5.13%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Jiaoqiu',
    app_rate_17: '5.13%',
    avg_round_17: 9,
    app_flat_17: 1,
    char_18: 'topaz',
    app_rate_18: '5.13%',
    avg_round_18: 6,
    app_flat_18: 1,
    char_19: 'Jade',
    app_rate_19: '5.13%',
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'dr-ratio',
    app_rate_20: '2.56%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Tingyun',
    app_rate_21: '2.56%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Hanya',
    app_rate_22: '2.56%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Jingliu',
    app_rate_23: '2.56%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Pela',
    app_rate_24: '2.56%',
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Jingliu',
    app: 0.35,
    char_1: 'ruan-mei',
    app_rate_1: '45.95%',
    avg_round_1: 9,
    app_flat_1: 2,
    char_2: 'Bronya',
    app_rate_2: '37.84%',
    avg_round_2: 9.33,
    app_flat_2: 3,
    char_3: 'Huohuo',
    app_rate_3: '32.43%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Sparkle',
    app_rate_4: '29.73%',
    avg_round_4: 10,
    app_flat_4: 1,
    char_5: 'Robin',
    app_rate_5: '29.73%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Lingsha',
    app_rate_6: '18.92%',
    avg_round_6: 9,
    app_flat_6: 2,
    char_7: 'Luocha',
    app_rate_7: '16.22%',
    avg_round_7: 10,
    app_flat_7: 1,
    char_8: 'Blade',
    app_rate_8: '16.22%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Gallagher',
    app_rate_9: '13.51%',
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'fu-xuan',
    app_rate_10: '13.51%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Tingyun',
    app_rate_11: '10.81%',
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: 'Pela',
    app_rate_12: '5.41%',
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'silver-wolf',
    app_rate_13: '5.41%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Jade',
    app_rate_14: '5.41%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Rappa',
    app_rate_15: '2.7%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Feixiao',
    app_rate_16: '2.7%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Yunli',
    app_rate_17: '2.7%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'imbibitor-lunae',
    app_rate_18: '2.7%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Lynx',
    app_rate_19: '2.7%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Himeko',
    app_rate_20: '2.7%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Seele',
    app: 0.34,
    char_1: 'Sparkle',
    app_rate_1: '88.89%',
    avg_round_1: 9.67,
    app_flat_1: 3,
    char_2: 'fu-xuan',
    app_rate_2: '86.11%',
    avg_round_2: 9.67,
    app_flat_2: 3,
    char_3: 'silver-wolf',
    app_rate_3: '72.22%',
    avg_round_3: 9.5,
    app_flat_3: 2,
    char_4: 'Robin',
    app_rate_4: '25.0%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Bronya',
    app_rate_5: '5.56%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Tingyun',
    app_rate_6: '5.56%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Gallagher',
    app_rate_7: '2.78%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Blade',
    app_rate_8: '2.78%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Aventurine',
    app_rate_9: '2.78%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Kafka',
    app_rate_10: '2.78%',
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'Luocha',
    app_rate_11: '2.78%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'march-7th-swordmaster',
    app_rate_12: '2.78%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Qingque',
    app: 0.31,
    char_1: 'Sparkle',
    app_rate_1: '100.0%',
    avg_round_1: 10.0,
    app_flat_1: 4,
    char_2: 'fu-xuan',
    app_rate_2: '96.97%',
    avg_round_2: 10.0,
    app_flat_2: 4,
    char_3: 'silver-wolf',
    app_rate_3: '81.82%',
    avg_round_3: 10.0,
    app_flat_3: 4,
    char_4: 'Robin',
    app_rate_4: '6.06%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Tingyun',
    app_rate_5: '6.06%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Huohuo',
    app_rate_6: '3.03%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'ruan-mei',
    app_rate_7: '3.03%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Bronya',
    app_rate_8: '3.03%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Serval',
    app: 0.18,
    char_1: 'Sparkle',
    app_rate_1: '42.11%',
    avg_round_1: 10,
    app_flat_1: 2,
    char_2: 'Tingyun',
    app_rate_2: '42.11%',
    avg_round_2: 9.5,
    app_flat_2: 2,
    char_3: 'fu-xuan',
    app_rate_3: '31.58%',
    avg_round_3: 10,
    app_flat_3: 1,
    char_4: 'Robin',
    app_rate_4: '26.32%',
    avg_round_4: 9,
    app_flat_4: 1,
    char_5: 'Huohuo',
    app_rate_5: '26.32%',
    avg_round_5: 9,
    app_flat_5: 3,
    char_6: 'Gallagher',
    app_rate_6: '21.05%',
    avg_round_6: 9.67,
    app_flat_6: 3,
    char_7: 'ruan-mei',
    app_rate_7: '21.05%',
    avg_round_7: 9,
    app_flat_7: 4,
    char_8: 'Luocha',
    app_rate_8: '10.53%',
    avg_round_8: 8,
    app_flat_8: 1,
    char_9: 'trailblazer-harmony',
    app_rate_9: '10.53%',
    avg_round_9: 9.5,
    app_flat_9: 2,
    char_10: 'Acheron',
    app_rate_10: '10.53%',
    avg_round_10: 8.5,
    app_flat_10: 2,
    char_11: 'black-swan',
    app_rate_11: '10.53%',
    avg_round_11: 8,
    app_flat_11: 1,
    char_12: 'silver-wolf',
    app_rate_12: '10.53%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Kafka',
    app_rate_13: '10.53%',
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'Aventurine',
    app_rate_14: '5.26%',
    avg_round_14: 8,
    app_flat_14: 1,
    char_15: 'Bronya',
    app_rate_15: '5.26%',
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'Pela',
    app_rate_16: '5.26%',
    avg_round_16: 9,
    app_flat_16: 1,
    char_17: 'march-7th',
    app_rate_17: '5.26%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Himeko',
    app_rate_18: '5.26%',
    avg_round_18: 8,
    app_flat_18: 1,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Herta',
    app: 0.14,
    char_1: 'Robin',
    app_rate_1: '53.33%',
    avg_round_1: 10,
    app_flat_1: 2,
    char_2: 'Aventurine',
    app_rate_2: '46.67%',
    avg_round_2: 10,
    app_flat_2: 1,
    char_3: 'ruan-mei',
    app_rate_3: '33.33%',
    avg_round_3: 10,
    app_flat_3: 1,
    char_4: 'Lingsha',
    app_rate_4: '33.33%',
    avg_round_4: 9.67,
    app_flat_4: 3,
    char_5: 'Sparkle',
    app_rate_5: '26.67%',
    avg_round_5: 10,
    app_flat_5: 1,
    char_6: 'Himeko',
    app_rate_6: '26.67%',
    avg_round_6: 10,
    app_flat_6: 2,
    char_7: 'Jade',
    app_rate_7: '20.0%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Pela',
    app_rate_8: '13.33%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Gallagher',
    app_rate_9: '6.67%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Acheron',
    app_rate_10: '6.67%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Jiaoqiu',
    app_rate_11: '6.67%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Clara',
    app_rate_12: '6.67%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Firefly',
    app_rate_13: '6.67%',
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'trailblazer-harmony',
    app_rate_14: '6.67%',
    avg_round_14: 9,
    app_flat_14: 1,
    char_15: 'topaz',
    app_rate_15: '6.67%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hanya',
    app: 0.1,
    char_1: 'Tingyun',
    app_rate_1: '36.36%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'imbibitor-lunae',
    app_rate_2: '36.36%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'ruan-mei',
    app_rate_3: '27.27%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Aventurine',
    app_rate_4: '27.27%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Huohuo',
    app_rate_5: '18.18%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Acheron',
    app_rate_6: '18.18%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'black-swan',
    app_rate_7: '18.18%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Yunli',
    app_rate_8: '18.18%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Gallagher',
    app_rate_9: '18.18%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Rappa',
    app_rate_10: '9.09%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'trailblazer-harmony',
    app_rate_11: '9.09%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'jing-yuan',
    app_rate_12: '9.09%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Kafka',
    app_rate_13: '9.09%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Lynx',
    app_rate_14: '9.09%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Sparkle',
    app_rate_15: '9.09%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Luocha',
    app_rate_16: '9.09%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Pela',
    app_rate_17: '9.09%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Gepard',
    app_rate_18: '9.09%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    app: 0.08,
    char_1: 'Acheron',
    app_rate_1: '50.0%',
    avg_round_1: 10,
    app_flat_1: 2,
    char_2: 'Robin',
    app_rate_2: '37.5%',
    avg_round_2: 3,
    app_flat_2: 1,
    char_3: 'Pela',
    app_rate_3: '37.5%',
    avg_round_3: 10,
    app_flat_3: 2,
    char_4: 'Feixiao',
    app_rate_4: '25.0%',
    avg_round_4: 3,
    app_flat_4: 1,
    char_5: 'topaz',
    app_rate_5: '25.0%',
    avg_round_5: 3,
    app_flat_5: 1,
    char_6: 'Jiaoqiu',
    app_rate_6: '25.0%',
    avg_round_6: 10,
    app_flat_6: 1,
    char_7: 'Moze',
    app_rate_7: '12.5%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Yunli',
    app_rate_8: '12.5%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Bronya',
    app_rate_9: '12.5%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'silver-wolf',
    app_rate_10: '12.5%',
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'black-swan',
    app_rate_11: '12.5%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Serval',
    app_rate_12: '12.5%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Tingyun',
    app_rate_13: '12.5%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Sparkle',
    app_rate_14: '12.5%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Argenti',
    app: 0.04,
    char_1: 'Tingyun',
    app_rate_1: '75.0%',
    avg_round_1: 10,
    app_flat_1: 1,
    char_2: 'Robin',
    app_rate_2: '50.0%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'Sparkle',
    app_rate_3: '50.0%',
    avg_round_3: 10,
    app_flat_3: 1,
    char_4: 'Huohuo',
    app_rate_4: '50.0%',
    avg_round_4: 10,
    app_flat_4: 1,
    char_5: 'Gallagher',
    app_rate_5: '25.0%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Jade',
    app_rate_6: '25.0%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Aventurine',
    app_rate_7: '25.0%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yukong',
    app: 0.04,
    char_1: 'Tingyun',
    app_rate_1: '75.0%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'Feixiao',
    app_rate_2: '50.0%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'Huohuo',
    app_rate_3: '50.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Robin',
    app_rate_4: '25.0%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Lingsha',
    app_rate_5: '25.0%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'imbibitor-lunae',
    app_rate_6: '25.0%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Luocha',
    app_rate_7: '25.0%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Yunli',
    app_rate_8: '25.0%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Xueyi',
    app: 0.04,
    char_1: 'silver-wolf',
    app_rate_1: '50.0%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'Sparkle',
    app_rate_2: '50.0%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'fu-xuan',
    app_rate_3: '50.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Firefly',
    app_rate_4: '50.0%',
    avg_round_4: 10,
    app_flat_4: 1,
    char_5: 'trailblazer-harmony',
    app_rate_5: '50.0%',
    avg_round_5: 10,
    app_flat_5: 1,
    char_6: 'Lingsha',
    app_rate_6: '25.0%',
    avg_round_6: 10,
    app_flat_6: 1,
    char_7: 'ruan-mei',
    app_rate_7: '25.0%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dan-heng',
    app: 0.04,
    char_1: 'Robin',
    app_rate_1: '50.0%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'march-7th-swordmaster',
    app_rate_2: '50.0%',
    avg_round_2: 8,
    app_flat_2: 1,
    char_3: 'Bronya',
    app_rate_3: '25.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'ruan-mei',
    app_rate_4: '25.0%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'fu-xuan',
    app_rate_5: '25.0%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Feixiao',
    app_rate_6: '25.0%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'topaz',
    app_rate_7: '25.0%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Aventurine',
    app_rate_8: '25.0%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Tingyun',
    app_rate_9: '25.0%',
    avg_round_9: 8,
    app_flat_9: 1,
    char_10: 'Huohuo',
    app_rate_10: '25.0%',
    avg_round_10: 8,
    app_flat_10: 1,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hook',
    app: 0.03,
    char_1: 'Lingsha',
    app_rate_1: '66.67%',
    avg_round_1: 8,
    app_flat_1: 1,
    char_2: 'trailblazer-harmony',
    app_rate_2: '33.33%',
    avg_round_2: 8,
    app_flat_2: 1,
    char_3: 'ruan-mei',
    app_rate_3: '33.33%',
    avg_round_3: 8,
    app_flat_3: 1,
    char_4: 'Acheron',
    app_rate_4: '33.33%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Jiaoqiu',
    app_rate_5: '33.33%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Aventurine',
    app_rate_6: '33.33%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Robin',
    app_rate_7: '33.33%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Sparkle',
    app_rate_8: '33.33%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Natasha',
    app: 0.03,
    char_1: 'ruan-mei',
    app_rate_1: '66.67%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'Firefly',
    app_rate_2: '33.33%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'trailblazer-harmony',
    app_rate_3: '33.33%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Yunli',
    app_rate_4: '33.33%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Bronya',
    app_rate_5: '33.33%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Tingyun',
    app_rate_6: '33.33%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Kafka',
    app_rate_7: '33.33%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Pela',
    app_rate_8: '33.33%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sushang',
    app: 0.02,
    char_1: 'Lingsha',
    app_rate_1: '100.0%',
    avg_round_1: 9,
    app_flat_1: 1,
    char_2: 'Robin',
    app_rate_2: '50.0%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'silver-wolf',
    app_rate_3: '50.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'trailblazer-harmony',
    app_rate_4: '50.0%',
    avg_round_4: 9,
    app_flat_4: 1,
    char_5: 'ruan-mei',
    app_rate_5: '50.0%',
    avg_round_5: 9,
    app_flat_5: 1,
    char_6: '-',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yanqing',
    app: 0.01,
    char_1: 'Kafka',
    app_rate_1: '100.0%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'black-swan',
    app_rate_2: '100.0%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'Aventurine',
    app_rate_3: '100.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: '-',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Misha',
    app: 0.01,
    char_1: 'trailblazer-harmony',
    app_rate_1: '100.0%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'Sparkle',
    app_rate_2: '100.0%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'Lingsha',
    app_rate_3: '100.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: '-',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
