import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { HSRLightCone } from '../common/components/hsr-light-cone';

interface IProps {
  slug: string;
  name: string;
  element: string;
  path: string;
}

export const HSRProfileCones: React.FC<IProps> = ({
  slug,
  element,
  name,
  path
}) => {
  return (
    <>
      <div className="build-relics">
        <div className={`content-header ${element}`}>
          <FontAwesomeIcon icon={faSquare} width="18" /> Best Light Cones
        </div>
        {path === 'Erudition' && (
          <>
            <div className="info-box with-margin">
              <p>
                <strong className={`${element}`}>{name}</strong> Relic and Light
                Cone calculations are based on fighting against 3 targets.
              </p>
            </div>
          </>
        )}
        <div className="detailed-cones moc">
          {/* Sustain */}
          {slug === 'aventurine' && (
            <>
              <h6>Best Light Cones</h6>

              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Inherently Unjust Destiny"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Powerful hybrid option for DEF% scaling Preservation
                  characters capable of dealing some damage with their kit. For
                  wearers with Follow-Up attacks, it also adds a powerful damage
                  Amplification debuff to each of those Follow-Ups, offering a
                  nice increase to team wide damage.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="Moment of Victory"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Provides a permanent Taunting effect on the wearer, increasing
                  the chance they'll be attacked dramatically. This effect and
                  the DEF bonuses make this Cone a very powerful choice for most
                  Preservation characters.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone
                  name="Day One of My New Life"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Decent option based on its team-wide damage reduction and good
                  stats for DEF% scaling characters.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>4</p>
                </div>
                <HSRLightCone
                  name="Concert for Two"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Reasonable hybrid option for DEF% scaling preservation
                  characters that apply team-wide Shields and are capable of
                  dealing some damage with their kit.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Trend of the Universal Market"
                  mode="profile"
                  superImp="1-5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Utility option useful in teams that thrive on having multiple
                  debuffs on enemies or applying debuffs frequently. Grants
                  solid stats for DEF scaling characters. Most notably used to
                  great effect in Acheron teams as it generates her charges each
                  time the wearer is hit.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Landau's Choice"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  An alternative to Moment of Victory with DMG% Reduction
                  instead of DEF% scaling. Primarily useful for characters that
                  specifically want to redirect and mitigate hits.
                </p>
              </div>
            </>
          )}
          {slug === 'fu-xuan' && (
            <>
              <h6>Best Light Cones</h6>

              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="She Already Shut Her Eyes"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Powerful supportive Cone with extra benefits to characters
                  that scale off HP% and that can make use of additional Energy
                  Regen. Easiest to use on characters liable to take direct
                  damage toward their health (strong AoE shielding characters
                  will struggle with this). Healing ability can be invaluable in
                  challenging content - specifically encounters with many waves.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="Moment of Victory"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Provides a permanent Taunting effect on the wearer, increasing
                  the chance they'll be attacked dramatically. This effect and
                  the DEF bonuses make this Cone a very powerful choice for most
                  Preservation characters.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone
                  name="Texture of Memories"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Survival focused Cone useful for characters that have kits
                  themed around taking and mitigating damage for the team,
                  especially if they have limited or conditional
                  sustain/shielding options of their own.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>4</p>
                </div>
                <HSRLightCone
                  name="Day One of My New Life"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Decent option based on its team-wide damage reduction and good
                  stats for DEF% scaling characters.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>5</p>
                </div>
                <HSRLightCone
                  name="Landau's Choice"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  An alternative to Moment of Victory with DMG% Reduction
                  instead of DEF% scaling. Primarily useful for characters that
                  specifically want to redirect and mitigate hits.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Trend of the Universal Market"
                  mode="profile"
                  superImp="1-5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Utility option useful in teams that thrive on having multiple
                  debuffs on enemies or applying debuffs frequently. Grants
                  solid stats for DEF scaling characters. Most notably used to
                  great effect in Acheron teams as it generates her charges each
                  time the wearer is hit.
                </p>
              </div>
            </>
          )}
          {slug === 'gallagher' && (
            <>
              <h6>Best Light Cones</h6>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Quid Pro Quo"
                  mode="profile"
                  superImp="1-5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  One of the strongest supportive Cones for most Abundance
                  characters - assuming you can deal with losing all the other
                  Cone related healing/utility/support bonuses and have a team
                  that can utilize the energy it provides. Becomes stronger the
                  more often the wearer acts.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="Multiplication"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Very potent pure Speed option for Abundance characters that
                  use a good amount of Basic Attacks. Allows the wearer many
                  more actions, leading to an accelerated rotation but at the
                  cost of base stats and the bonuses other Cones might provide.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone
                  name="Perfect Timing"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Improves healing based on Effect RES and incentivizes
                  characters to stack Effect RES up to 100% for Debuff Immunity
                  (for the ones that can be resisted at least).
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>4</p>
                </div>
                <HSRLightCone
                  name="Post-Op Conversation"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Useful primarily due to its accessible large bonus to Energy
                  Regen - a stat many sustain characters can't get enough of.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Scent Alone Stays True"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  A more offensive option for Gallagher, but you sacrifice his
                  insane energy supply to the team in exchange for a lot of
                  Break Effect and a debuff that amplifies the team's damage.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="What Is Real?"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Mostly a pure offensive Cone useful when sustain is mostly no
                  longer an issue and additional damage via Break or Super Break
                  is preferred.
                </p>
              </div>
            </>
          )}
          {slug === 'gepard' && (
            <>
              <h6>Best Light Cones</h6>

              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Moment of Victory"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Provides a permanent Taunting effect on the wearer, increasing
                  the chance they'll be attacked dramatically. This effect and
                  the DEF bonuses make this Cone a very powerful choice for most
                  Preservation characters.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="Day One of My New Life"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Decent option based on its team-wide damage reduction and good
                  stats for DEF% scaling characters.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone
                  name="Landau's Choice"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  An alternative to Moment of Victory with DMG% Reduction
                  instead of DEF% scaling. Primarily useful for characters that
                  specifically want to redirect and mitigate hits.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>4</p>
                </div>
                <HSRLightCone
                  name="Texture of Memories"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Survival focused Cone useful for characters that have kits
                  themed around taking and mitigating damage for the team,
                  especially if they have limited or conditional
                  sustain/shielding options of their own.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Trend of the Universal Market"
                  mode="profile"
                  superImp="1-5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Utility option useful in teams that thrive on having multiple
                  debuffs on enemies or applying debuffs frequently. Grants
                  solid stats for DEF scaling characters. Most notably used to
                  great effect in Acheron teams as it generates her charges each
                  time the wearer is hit.
                </p>
              </div>
            </>
          )}
          {slug === 'trailblazer-preservation' && (
            <>
              <h6>Best Light Cones</h6>

              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Moment of Victory"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Provides a permanent Taunting effect on the wearer, increasing
                  the chance they'll be attacked dramatically. This effect and
                  the DEF bonuses make this Cone a very powerful choice for most
                  Preservation characters.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="Landau's Choice"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  An alternative to Moment of Victory with DMG% Reduction
                  instead of DEF% scaling. Primarily useful for characters that
                  specifically want to redirect and mitigate hits.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone
                  name="Texture of Memories"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Survival focused Cone useful for characters that have kits
                  themed around taking and mitigating damage for the team,
                  especially if they have limited or conditional
                  sustain/shielding options of their own.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>4</p>
                </div>
                <HSRLightCone
                  name="Day One of My New Life"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Decent option based on its team-wide damage reduction and good
                  stats for DEF% scaling characters.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Trend of the Universal Market"
                  mode="profile"
                  superImp="1-5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Utility option useful in teams that thrive on having multiple
                  debuffs on enemies or applying debuffs frequently. Grants
                  solid stats for DEF scaling characters. Most notably used to
                  great effect in Acheron teams as it generates her charges each
                  time the wearer is hit.
                </p>
              </div>
            </>
          )}
          {slug === 'lingsha' && (
            <>
              <h6>Best Light Cones</h6>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Scent Alone Stays True"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Lingsha's Best in Slot that gives her tons of Break and a
                  debuff that further amplifies her own and the team's damage.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="What Is Real?"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Mostly a pure offensive Cone useful when sustain is mostly no
                  longer an issue and additional damage via Break or Super Break
                  is preferred.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Quid Pro Quo"
                  mode="profile"
                  superImp="1-5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  One of the strongest supportive Cones for most Abundance
                  characters - assuming you can deal with losing all the other
                  Cone related healing/utility/support bonuses and have a team
                  that can utilize the energy it provides. Becomes stronger the
                  more often the wearer acts.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Post-Op Conversation"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Useful primarily due to its accessible large bonus to Energy
                  Regen - a stat many sustain characters can't get enough of.
                </p>
              </div>
            </>
          )}
          {slug === 'luocha' && (
            <>
              <h6>Best Light Cones</h6>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Echoes of the Coffin"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Powerful option for ATK% scaling Abundance characters capable
                  of hitting enemies very frequently - so perfect for Luocha.
                  Though, keep in mind that the Speed boost from this Cone can
                  interfere with Speed tuning in some cases when not played
                  around.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="Perfect Timing"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Improves healing based on Effect RES and incentivizes
                  characters to stack Effect RES up to 100% for Debuff Immunity
                  (for the ones that can be resisted at least).
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone
                  name="Multiplication"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Very potent pure Speed option for Abundance characters that
                  use a good amount of Basic Attacks. Allows the wearer many
                  more actions, leading to an accelerated rotation but at the
                  cost of base stats and the bonuses other Cones might provide.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>4</p>
                </div>
                <HSRLightCone
                  name="Post-Op Conversation"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Useful primarily due to its accessible large bonus to Energy
                  Regen - a stat many sustain characters can't get enough of.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Quid Pro Quo"
                  mode="profile"
                  superImp="1-5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  One of the strongest supportive Cones for most Abundance
                  characters - assuming you can deal with losing all the other
                  Cone related healing/utility/support bonuses and have a team
                  that can utilize the energy it provides. Becomes stronger the
                  more often the wearer acts.
                </p>
              </div>
            </>
          )}
          {slug === 'march-7th' && (
            <>
              <h6>Best Light Cones</h6>

              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Day One of My New Life"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Decent option based on its team-wide damage reduction and good
                  stats for DEF% scaling characters.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="Texture of Memories"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Survival focused Cone useful for characters that have kits
                  themed around taking and mitigating damage for the team,
                  especially if they have limited or conditional
                  sustain/shielding options of their own.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Moment of Victory"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Provides a permanent Taunting effect on the wearer, increasing
                  the chance they'll be attacked dramatically. This effect and
                  the DEF bonuses make this Cone a very powerful choice for most
                  Preservation characters.{' '}
                  <strong>
                    When playing March in teams where the intention is to use
                    her Shield to direct attacks toward a target other than
                    herself, this Cone can serve as a hindrance redirecting
                    attacks from your desired target toward March.
                  </strong>
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Landau's Choice"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  An alternative to Moment of Victory with DMG% Reduction
                  instead of DEF% scaling. Primarily useful for characters that
                  specifically want to redirect and mitigate hits.
                  <strong>
                    When playing March in teams where the intention is to use
                    her Shield to direct attacks toward a target other than
                    herself, this Cone can serve as a hindrance redirecting
                    attacks from your desired target toward March.
                  </strong>
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Trend of the Universal Market"
                  mode="profile"
                  superImp="1-5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Utility option useful in teams that thrive on having multiple
                  debuffs on enemies or applying debuffs frequently. Grants
                  solid stats for DEF scaling characters. Most notably used to
                  great effect in Acheron teams as it generates her charges each
                  time the wearer is hit.
                </p>
              </div>
            </>
          )}
          {slug === 'huohuo' && (
            <>
              <h6>Best Light Cones</h6>

              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Night of Fright"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredible option for most Abundance characters thanks to good
                  Energy Regen, a stackable team wide buff and a unique healing
                  mechanic capable of keeping your team alive in a pinch when
                  your healer has no skills available at the time.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="Post-Op Conversation"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Useful primarily due to its accessible large bonus to Energy
                  Regen - a stat many sustain characters can't get enough of.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone
                  name="Shared Feeling"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Specialty Cone for Abundance characters that use their Skill
                  as much as they're allowed to. Can solve energy requirement
                  issues for the wearer and teammates but can lose out to other
                  Cone without enough Skill uses.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>4</p>
                </div>
                <HSRLightCone
                  name="Time Waits for No One"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  A good combination of Healing%, HP% and base stats making for
                  a decent pure sustain option for HP% scaling healers but
                  offers no utility or supportive benefits.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>5</p>
                </div>
                <HSRLightCone
                  name="Hey, Over Here"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  A solid pure sustain focused F2P option for HP% abundance
                  characters that frequently use their skill.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Quid Pro Quo"
                  mode="profile"
                  superImp="1-5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  One of the strongest supportive Cones for most Abundance
                  characters - assuming you can deal with losing all the other
                  Cone related healing/utility/support bonuses and have a team
                  that can utilize the energy it provides. Becomes stronger the
                  more often the wearer acts.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Multiplication"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Very potent pure Speed option for Abundance characters that
                  use a good amount of Basic Attacks. Allows the wearer many
                  more actions, leading to an accelerated rotation but at the
                  cost of base stats and the bonuses other Cones might provide.
                </p>
              </div>
            </>
          )}
          {(slug === 'lynx' || slug === 'bailu' || slug === 'natasha') && (
            <>
              <h6>Best Light Cones</h6>

              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Night of Fright"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredible option for most Abundance characters thanks to good
                  Energy Regen, a stackable team wide buff and a unique healing
                  mechanic capable of keeping your team alive in a pinch when
                  your healer has no skills available at the time.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="Time Waits for No One"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  A good combination of Healing%, HP% and base stats making for
                  a decent pure sustain option for HP% scaling healers but
                  offers no utility or supportive benefits.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone
                  name="Post-Op Conversation"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Useful primarily due to its accessible large bonus to Energy
                  Regen - a stat many sustain characters can't get enough of.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>4</p>
                </div>
                <HSRLightCone
                  name="Perfect Timing"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Improves healing based on Effect RES and incentivizes
                  characters to stack Effect RES up to 100% for Debuff Immunity
                  (for the ones that can be resisted at least).
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Quid Pro Quo"
                  mode="profile"
                  superImp="1-5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  One of the strongest supportive Cones for most Abundance
                  characters - assuming you can deal with losing all the other
                  Cone related healing/utility/support bonuses and have a team
                  that can utilize the energy it provides. Becomes stronger the
                  more often the wearer acts.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Multiplication"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Very potent pure Speed option for Abundance characters that
                  use a good amount of Basic Attacks. Allows the wearer many
                  more actions, leading to an accelerated rotation but at the
                  cost of base stats and the bonuses other Cones might provide.
                </p>
              </div>
            </>
          )}
          {/* Amplifiers */}
          {slug === 'robin' && (
            <>
              <h6>Best Light Cones</h6>

              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Flowing Nightglow"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredibly potent choice for Harmony characters, expecting to
                  deal a meaningful portion of the teams damage through their
                  Ultimate and who scale via ATK%.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="For Tomorrow's Journey"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Good option for ATK% scaling Harmony characters that intend to
                  deal significant damage after using their Ultimate.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone
                  name="But the Battle Isn't Over"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  A top option for characters that advance others via their
                  skill, guaranteeing the DMG% boost of this Cone. Useful even
                  on characters without the ability, thanks to the Energy Regen
                  and skill point generation.{' '}
                  <strong>
                    Despite not gaining much use from the primary effect of this
                    Cone, the Energy Regeneration Rate, high base stats and
                    additional Skill points make it a viable option compared to
                    alternatives for Robin.
                  </strong>
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone name="Meshing Cogs" mode="profile" superImp="5" />
              </div>
              <div className={`information ${element}`}>
                <p>
                  One of the strongest energy generating Cone in the game -
                  especially in encounters where the wearer is getting hit many
                  times as each hit grants 8 energy. Will help a lot with Energy
                  issues, but at the cost of all other supportive effects and
                  base stats.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Poised to Bloom"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Specialized option usable when supporting teams with 2 damage
                  dealers of the same path who scale their damage through CRIT.
                  Performs better on Harmony characters that have abilities that
                  scale with ATK%.
                </p>
              </div>
            </>
          )}
          {(slug === 'ruan-mei' || slug === 'trailblazer-harmony') && (
            <>
              <h6>Best Light Cones</h6>

              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Past Self in Mirror"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Powerful team buffing option with additional benefits for
                  Break-scaling Harmony supports. Gains more value in endgame
                  modes with more waves of enemies.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="Memories of the Past"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Powerful Energy generation Cone for characters that hit
                  frequently. Especially strong for characters that scale off of
                  Break Effect. Only stronger than Meshing Cogs for Break
                  related characters or those who require the additional base
                  stats of a 4-star vs 3-star Cone.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone name="Meshing Cogs" mode="profile" superImp="5" />
              </div>
              <div className={`information ${element}`}>
                <p>
                  One of the strongest energy generating Cone in the game -
                  especially in encounters where the wearer is getting hit many
                  times as each hit grants 8 energy. Will help a lot with Energy
                  issues, but at the cost of all other supportive effects and
                  base stats.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Dance! Dance! Dance!"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Allows characters with high Speed and/or an accessible
                  Ultimate to grant substantial action boosts to themselves and
                  the team allowing for higher Speed (and cycle) breakpoints.
                  Strongest on characters who can access their Ultimate at least
                  once every 3 (but ideally 2) actions. Specifically strong when
                  combined with other additional action advances or energy
                  generation.
                </p>
              </div>
            </>
          )}
          {slug === 'sparkle' && (
            <>
              <h6>Best Light Cones</h6>

              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Earthly Escapade"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Potent but restrictive team-buffing Cone, granting huge CRIT
                  boosts. Primarily only suitable for characters that have a
                  special way to generate additional Skill Points consistently
                  in their kit.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="But the Battle Isn't Over"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  A top option for characters that advance others via their
                  skill, guaranteeing the DMG% boost of this Cone. Useful even
                  on characters without the ability, thanks to the Energy Regen
                  and skill point generation.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone
                  name="Past and Future"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  F2P option and alternative to But The Battle Isn't Over for
                  characters that advance others via their Skill, guaranteeing
                  the DMG% boost of this Cone.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Dance! Dance! Dance!"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Allows characters with high Speed and/or an accessible
                  Ultimate to grant substantial action boosts to themselves and
                  the team allowing for higher Speed (and cycle) breakpoints.
                  Strongest on characters who can access their Ultimate at least
                  once every 3 (but ideally 2) actions. Specifically strong when
                  combined with other additional action advances or energy
                  generation.
                </p>
              </div>
            </>
          )}
          {(slug === 'tingyun' || slug === 'asta' || slug === 'hanya') && (
            <>
              <h6>Best Light Cones</h6>

              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Dance! Dance! Dance!"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Allows characters with high Speed and/or an accessible
                  Ultimate to grant substantial action boosts to themselves and
                  the team allowing for higher Speed (and cycle) breakpoints.
                  Strongest on characters who can access their Ultimate at least
                  once every 3 (but ideally 2) actions. Specifically strong when
                  combined with other additional action advances or energy
                  generation.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone name="Meshing Cogs" mode="profile" superImp="5" />
              </div>
              <div className={`information ${element}`}>
                <p>
                  One of the strongest energy generating Cone in the game -
                  especially in encounters where the wearer is getting hit many
                  times as each hit grants 8 energy. Will help a lot with Energy
                  issues, but at the cost of all other supportive effects and
                  base stats.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone
                  name="But the Battle Isn't Over"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  A top option for characters that advance others via their
                  skill, guaranteeing the DMG% boost of this Cone. Useful even
                  on characters without the ability, thanks to the Energy Regen
                  and skill point generation.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Planetary Rendezvous"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Decent team buffing Cone, but with the restriction of only
                  granting its boost to allies of the same Element as the
                  wearer.
                </p>
              </div>
            </>
          )}
          {slug === 'bronya' && (
            <>
              <h6>Best Light Cones</h6>

              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="But the Battle Isn't Over"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  A top option for characters that advance others via their
                  skill, guaranteeing the DMG% boost of this Cone. Useful even
                  on characters without the ability, thanks to the Energy Regen
                  and skill point generation.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="Past and Future"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  F2P option and alternative to But The Battle Isn't Over for
                  characters that advance others via their Skill, guaranteeing
                  the DMG% boost of this Cone.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Dance! Dance! Dance!"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Allows characters with high Speed and/or an accessible
                  Ultimate to grant substantial action boosts to themselves and
                  the team allowing for higher Speed (and cycle) breakpoints.
                  Strongest on characters who can access their Ultimate at least
                  once every 3 (but ideally 2) actions. Specifically strong when
                  combined with other additional action advances or energy
                  generation.{' '}
                  <strong>
                    When playing Bronya according to the -1 Speed build exercise
                    caution when using this set bonus as it can ruin your Speed
                    tuning unless carefully managed.
                  </strong>
                </p>
              </div>
            </>
          )}
          {slug === 'yukong' && (
            <>
              <h6>Best Light Cones</h6>

              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Memories of the Past"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Powerful Energy generation Cone for characters that hit
                  frequently. Especially strong for characters that scale off of
                  Break Effect. Only stronger than Meshing Cogs for Break
                  related characters or those who require the additional base
                  stats of a 4-star vs 3-star Cone.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="But the Battle Isn't Over"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  A top option for characters that advance others via their
                  skill, guaranteeing the DMG% boost of this Cone. Useful even
                  on characters without the ability, thanks to the Energy Regen
                  and skill point generation.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone name="Meshing Cogs" mode="profile" superImp="5" />
              </div>
              <div className={`information ${element}`}>
                <p>
                  One of the strongest energy generating Cone in the game -
                  especially in encounters where the wearer is getting hit many
                  times as each hit grants 8 energy. Will help a lot with Energy
                  issues, but at the cost of all other supportive effects and
                  base stats.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Planetary Rendezvous"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Decent team buffing Cone, but with the restriction of only
                  granting its boost to allies of the same Element as the
                  wearer.
                </p>
              </div>
            </>
          )}
          {slug === 'sunday' && (
            <>
              <h6>Best Light Cones</h6>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="A Grounded Ascent"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Best Light Cone option for Sunday by far, allowing him to
                  realistically hit a 3-action rotation and granting permanent
                  uptime on his Ultimate buff. Also allows him to be played
                  Skill Point positive.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="But the Battle Isn't Over"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Decent alternative to Sunday's signature albeit a
                  significantly downgrade. Still grants him additional buffing
                  capabilities, Skill Points and Energy Regen, but not as much.
                  Which leads to a higher reliance on enemy attacks to hit a
                  3-action rotation. Note compared to his signature this Cone's
                  buff lasts 1 turn making it significantly worse for the summon
                  based characters Sunday specializes in supporting.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone
                  name="Past and Future"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  A much worse version of But the Battle Isn't Over, having the
                  same 1 turn duration issue. And on top, it's missing Energy
                  Regen or Skill Point economy. Sunday may struggle to achieve a
                  3-action ultimate when using this Cone.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Dance! Dance! Dance!"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  If playing Sunday according to the -1 Speed build exercise
                  caution when using this Cone as it can ruin your Speed tuning
                  unless carefully managed. Alternatively is a solid option for
                  Hyperspeed Sunday, if you're lacking his signature. Sunday may
                  struggle to achieve a 3-action ultimate when using this Cone.
                </p>
              </div>
            </>
          )}
          {/* Amplifiers - debuffers */}
          {slug === 'pela' && (
            <>
              <h6>Best Light Cones</h6>

              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Resolution Shines As Pearls of Sweat"
                  mode="profile"
                  superImp="1-5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  One of the top Nihility supportive options available. Must be
                  played around effectively due to its 1 turn duration - aim to
                  apply this during the enemies turn or right after it. Becomes
                  more powerful when combined with other sources of DEF%
                  reduction.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Before the Tutorial Mission Starts"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Specialty Cone only useful when the enemy already has a DEF%
                  reduction applied to them. Benefits characters looking for
                  Effect Hit Rating and substantial amounts of additional Energy
                  generation. Easiest to use on characters that apply a DEF%
                  reduction in their kit.
                </p>
              </div>
            </>
          )}
          {slug === 'silver-wolf' && (
            <>
              <h6>Best Light Cones</h6>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Before the Tutorial Mission Starts"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Specialty Cone only useful when the enemy already has a DEF%
                  reduction applied to them. Benefits characters looking for
                  Effect Hit Rating and substantial amounts of additional Energy
                  generation. Easiest to use on characters that apply a DEF%
                  reduction in their kit.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="Incessant Rain"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Combines solid Effect Hit Rate, a damage boost and a solid on
                  hit debuff to boost the teams damage, resulting in a good
                  all-around Cone usable by most Nihility supports who can
                  benefit from all most of the stats.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone
                  name="Resolution Shines As Pearls of Sweat"
                  mode="profile"
                  superImp="1-5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  One of the top Nihility supportive options available. Must be
                  played around effectively due to its 1 turn duration - aim to
                  apply this during the enemies turn or right after it. Becomes
                  more powerful when combined with other sources of DEF%
                  reduction.
                </p>
              </div>
            </>
          )}
          {slug === 'jiaoqiu' && (
            <>
              <h6>Best Light Cones</h6>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Those Many Springs"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredibly strong team-wide supportive option for DoT based
                  Nihility supports seeking a large amount of Effect Hit Rating
                  and who can apply and maintain their DoT throughout combat.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="Eyes of the Prey"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Solid option for those seeking a strong amount of Effect Hit
                  Rating and or a large boost to DoT damage.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>3</p>
                </div>
                <HSRLightCone
                  name="Resolution Shines As Pearls of Sweat"
                  mode="profile"
                  superImp="1-5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  One of the top Nihility supportive options available. Must be
                  played around effectively due to its 1 turn duration - aim to
                  apply this during the enemies turn or right after it. Becomes
                  more powerful when combined with other sources of DEF%
                  reduction.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Before the Tutorial Mission Starts"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Specialty Cone only useful when the enemy already has a DEF%
                  reduction applied to them. Benefits characters looking for
                  Effect Hit Rating and substantial amounts of additional Energy
                  generation. Easiest to use on characters that apply a DEF%
                  reduction in their kit.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Solitary Healing"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Specialty Energy Regeneration Cone for DoT users that grants
                  tremendous Energy generation in endgame modes with many
                  enemies that can be killed quickly like Pure Fiction, allowing
                  for Ult spamming assuming the wearer can apply their DoT's
                  fast enough. Also provides solid DoT DMG% and some Break
                  Effect as an additional bonus.
                </p>
              </div>
            </>
          )}
          {slug === 'guinaifen' && (
            <>
              <h6>Best Light Cones</h6>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>1</p>
                </div>
                <HSRLightCone
                  name="Those Many Springs"
                  mode="profile"
                  superImp="1"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Incredibly strong team-wide supportive option for DoT based
                  Nihility supports seeking a large amount of Effect Hit Rating
                  and who can apply and maintain their DoT throughout combat.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <div className="percentage">
                  <p>2</p>
                </div>
                <HSRLightCone
                  name="Resolution Shines As Pearls of Sweat"
                  mode="profile"
                  superImp="1-5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  One of the top Nihility supportive options available. Must be
                  played around effectively due to its 1 turn duration - aim to
                  apply this during the enemies turn or right after it. Becomes
                  more powerful when combined with other sources of DEF%
                  reduction.
                </p>
              </div>
              <h6>Special Light Cones</h6>
              <p>
                Alternative or more specialized Light Cones that also are viable
                options for the character.
              </p>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Eyes of the Prey"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Solid option for those seeking a strong amount of Effect Hit
                  Rating and or a large boost to DoT damage.
                </p>
              </div>
              <div className={`single-cone with-notes ${element}`}>
                <HSRLightCone
                  name="Solitary Healing"
                  mode="profile"
                  superImp="5"
                />
              </div>
              <div className={`information ${element}`}>
                <p>
                  Specialty Energy Regeneration Cone for DoT users that grants
                  tremendous Energy generation in endgame modes with many
                  enemies that can be killed quickly like Pure Fiction, allowing
                  for Ult spamming assuming the wearer can apply their DoT's
                  fast enough. Also provides solid DoT DMG% and some Break
                  Effect as an additional bonus.
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
