export const PFCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    current_app_rate: 54.33,
    prev_app_rate: 55.33,
    current_avg_score: 34487,
    prev_avg_score: 32395,
    round_eidolons: 37140,
    rarity: 'Limited 5*'
  },
  {
    char: 'argenti',
    name: 'Argenti',
    current_app_rate: 1.64,
    prev_app_rate: 4.03,
    current_avg_score: 25539,
    prev_avg_score: 29285,
    round_eidolons: 32610,
    rarity: 'Limited 5*'
  },
  {
    char: 'arlan',
    name: 'Arlan',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'asta',
    name: 'Asta',
    current_app_rate: 1.23,
    prev_app_rate: 0.86,
    current_avg_score: 28447,
    prev_avg_score: 27726,
    round_eidolons: 32050,
    rarity: '4*'
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    current_app_rate: 39.05,
    prev_app_rate: 37.3,
    current_avg_score: 34033,
    prev_avg_score: 32352,
    round_eidolons: 37870,
    rarity: 'Limited 5*'
  },
  {
    char: 'bailu',
    name: 'Bailu',
    current_app_rate: 0.87,
    prev_app_rate: 1,
    current_avg_score: 29547,
    prev_avg_score: 28531,
    round_eidolons: 35900,
    rarity: 'Standard 5*'
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    current_app_rate: 59.78,
    prev_app_rate: 21.39,
    current_avg_score: 35164,
    prev_avg_score: 30785,
    round_eidolons: 38760,
    rarity: 'Limited 5*'
  },
  {
    char: 'blade',
    name: 'Blade',
    current_app_rate: 2.25,
    prev_app_rate: 1.05,
    current_avg_score: 27272,
    prev_avg_score: 31113,
    round_eidolons: 31200,
    rarity: 'Limited 5*'
  },
  {
    char: 'boothill',
    name: 'Boothill',
    current_app_rate: 0.22,
    prev_app_rate: 0.15,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 32300,
    rarity: 'Limited 5*'
  },
  {
    char: 'bronya',
    name: 'Bronya',
    current_app_rate: 3.4,
    prev_app_rate: 4.44,
    current_avg_score: 27151,
    prev_avg_score: 27506,
    round_eidolons: 33230,
    rarity: 'Standard 5*'
  },
  {
    char: 'clara',
    name: 'Clara',
    current_app_rate: 4.72,
    prev_app_rate: 3.45,
    current_avg_score: 29853,
    prev_avg_score: 28951,
    round_eidolons: 31980,
    rarity: 'Standard 5*'
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    current_app_rate: 0.08,
    prev_app_rate: 0.24,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'feixiao',
    name: 'Feixiao',
    current_app_rate: 10.95,
    prev_app_rate: 14.62,
    current_avg_score: 30907,
    prev_avg_score: 30866,
    round_eidolons: 34330,
    rarity: 'Limited 5*'
  },
  {
    char: 'trailblazer-preservation',
    name: 'Fire Trailblazer',
    current_app_rate: 0.12,
    prev_app_rate: 0.3,
    current_avg_score: 0,
    prev_avg_score: 29764,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'firefly',
    name: 'Firefly',
    current_app_rate: 45.37,
    prev_app_rate: 59.26,
    current_avg_score: 27888,
    prev_avg_score: 32216,
    round_eidolons: 33730,
    rarity: 'Limited 5*'
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    current_app_rate: 20.63,
    prev_app_rate: 40.14,
    current_avg_score: 32425,
    prev_avg_score: 31464,
    round_eidolons: 33630,
    rarity: 'Limited 5*'
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    current_app_rate: 46.2,
    prev_app_rate: 59.01,
    current_avg_score: 29530,
    prev_avg_score: 31070,
    round_eidolons: 32560,
    rarity: '4*'
  },
  {
    char: 'gepard',
    name: 'Gepard',
    current_app_rate: 2.52,
    prev_app_rate: 2.67,
    current_avg_score: 33216,
    prev_avg_score: 31434,
    round_eidolons: 33880,
    rarity: 'Standard 5*'
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    current_app_rate: 2.99,
    prev_app_rate: 0.7,
    current_avg_score: 28191,
    prev_avg_score: 25799,
    round_eidolons: 30410,
    rarity: '4*'
  },
  {
    char: 'hanya',
    name: 'Hanya',
    current_app_rate: 0.08,
    prev_app_rate: 0.11,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'herta',
    name: 'Herta',
    current_app_rate: 12.42,
    prev_app_rate: 32.38,
    current_avg_score: 31597,
    prev_avg_score: 31502,
    round_eidolons: 34890,
    rarity: '4*'
  },
  {
    char: 'himeko',
    name: 'Himeko',
    current_app_rate: 31.24,
    prev_app_rate: 43.28,
    current_avg_score: 28900,
    prev_avg_score: 32809,
    round_eidolons: 32210,
    rarity: 'Standard 5*'
  },
  {
    char: 'hook',
    name: 'Hook',
    current_app_rate: 0,
    prev_app_rate: 0.01,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    current_app_rate: 30.62,
    prev_app_rate: 20.4,
    current_avg_score: 33369,
    prev_avg_score: 30131,
    round_eidolons: 38440,
    rarity: 'Limited 5*'
  },
  {
    char: 'trailblazer-harmony',
    name: 'Imaginary Trailblazer',
    current_app_rate: 72.45,
    prev_app_rate: 68.96,
    current_avg_score: 30119,
    prev_avg_score: 32188,
    round_eidolons: 34090,
    rarity: '4*'
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    current_app_rate: 1.06,
    prev_app_rate: 1.55,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 29950,
    rarity: 'Limited 5*'
  },
  {
    char: 'jade',
    name: 'Jade',
    current_app_rate: 8.58,
    prev_app_rate: 22.17,
    current_avg_score: 31437,
    prev_avg_score: 33671,
    round_eidolons: 34870,
    rarity: 'Limited 5*'
  },
  {
    char: 'jiaoqiu',
    name: 'Jiaoqiu',
    current_app_rate: 30.65,
    prev_app_rate: 29.02,
    current_avg_score: 35433,
    prev_avg_score: 34473,
    round_eidolons: 38980,
    rarity: 'Limited 5*'
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    current_app_rate: 1.37,
    prev_app_rate: 6.03,
    current_avg_score: 24413,
    prev_avg_score: 28802,
    round_eidolons: 29750,
    rarity: 'Limited 5*'
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    current_app_rate: 0.11,
    prev_app_rate: 0.19,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'kafka',
    name: 'Kafka',
    current_app_rate: 58.04,
    prev_app_rate: 28.4,
    current_avg_score: 34773,
    prev_avg_score: 31364,
    round_eidolons: 38690,
    rarity: 'Limited 5*'
  },
  {
    char: 'lingsha',
    name: 'Lingsha',
    current_app_rate: 37.81,
    prev_app_rate: 10.01,
    current_avg_score: 32791,
    prev_avg_score: 36985,
    round_eidolons: 37720,
    rarity: 'Limited 5*'
  },
  {
    char: 'luka',
    name: 'Luka',
    current_app_rate: 0.02,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'luocha',
    name: 'Luocha',
    current_app_rate: 10.96,
    prev_app_rate: 9.17,
    current_avg_score: 31592,
    prev_avg_score: 29901,
    round_eidolons: 34520,
    rarity: 'Limited 5*'
  },
  {
    char: 'lynx',
    name: 'Lynx',
    current_app_rate: 0.89,
    prev_app_rate: 0.91,
    current_avg_score: 30803,
    prev_avg_score: 29896,
    round_eidolons: 34180,
    rarity: '4*'
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    current_app_rate: 0.56,
    prev_app_rate: 0.1,
    current_avg_score: 32972,
    prev_avg_score: 0,
    round_eidolons: 35620,
    rarity: '4*'
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - The Hunt',
    current_app_rate: 2.87,
    prev_app_rate: 6.31,
    current_avg_score: 27240,
    prev_avg_score: 30203,
    round_eidolons: 30870,
    rarity: '4*'
  },
  {
    char: 'misha',
    name: 'Misha',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'moze',
    name: 'Moze',
    current_app_rate: 0.03,
    prev_app_rate: 0.19,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'natasha',
    name: 'Natasha',
    current_app_rate: 0.08,
    prev_app_rate: 0.1,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'pela',
    name: 'Pela',
    current_app_rate: 9.72,
    prev_app_rate: 15.87,
    current_avg_score: 32581,
    prev_avg_score: 32378,
    round_eidolons: 32620,
    rarity: '4*'
  },
  {
    char: 'trailblazer-destruction',
    name: 'Physical Trailblazer',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'qingque',
    name: 'Qingque',
    current_app_rate: 0.02,
    prev_app_rate: 0.19,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'rappa',
    name: 'Rappa',
    current_app_rate: 23.22,
    prev_app_rate: 23.22,
    current_avg_score: 33565,
    prev_avg_score: 33565,
    round_eidolons: 38940,
    rarity: 'Limited 5*'
  },
  {
    char: 'robin',
    name: 'Robin',
    current_app_rate: 44.38,
    prev_app_rate: 52.7,
    current_avg_score: 31895,
    prev_avg_score: 31739,
    round_eidolons: 37190,
    rarity: 'Limited 5*'
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    current_app_rate: 89.21,
    prev_app_rate: 84.42,
    current_avg_score: 31010,
    prev_avg_score: 32311,
    round_eidolons: 34800,
    rarity: 'Limited 5*'
  },
  {
    char: 'sampo',
    name: 'Sampo',
    current_app_rate: 3.13,
    prev_app_rate: 0.12,
    current_avg_score: 28793,
    prev_avg_score: 0,
    round_eidolons: 32770,
    rarity: '4*'
  },
  {
    char: 'seele',
    name: 'Seele',
    current_app_rate: 0.29,
    prev_app_rate: 1.63,
    current_avg_score: 0,
    prev_avg_score: 27391,
    round_eidolons: 30150,
    rarity: 'Limited 5*'
  },
  {
    char: 'serval',
    name: 'Serval',
    current_app_rate: 0.48,
    prev_app_rate: 0.96,
    current_avg_score: 28413,
    prev_avg_score: 24992,
    round_eidolons: 33250,
    rarity: '4*'
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    current_app_rate: 1.12,
    prev_app_rate: 3.16,
    current_avg_score: 28070,
    prev_avg_score: 28541,
    round_eidolons: 27860,
    rarity: 'Limited 5*'
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    current_app_rate: 15.88,
    prev_app_rate: 27.22,
    current_avg_score: 28726,
    prev_avg_score: 29221,
    round_eidolons: 34060,
    rarity: 'Limited 5*'
  },
  {
    char: 'sushang',
    name: 'Sushang',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    current_app_rate: 5.52,
    prev_app_rate: 11.1,
    current_avg_score: 27812,
    prev_avg_score: 28864,
    round_eidolons: 32460,
    rarity: '4*'
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    current_app_rate: 3.1,
    prev_app_rate: 6.14,
    current_avg_score: 25679,
    prev_avg_score: 30361,
    round_eidolons: 30980,
    rarity: 'Limited 5*'
  },
  {
    char: 'welt',
    name: 'Welt',
    current_app_rate: 0.26,
    prev_app_rate: 0.37,
    current_avg_score: 26276,
    prev_avg_score: 0,
    round_eidolons: 28920,
    rarity: 'Standard 5*'
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    current_app_rate: 0,
    prev_app_rate: 0.75,
    current_avg_score: 0,
    prev_avg_score: 25679,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    current_app_rate: 0.01,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: 'Standard 5*'
  },
  {
    char: 'yukong',
    name: 'Yukong',
    current_app_rate: 0.01,
    prev_app_rate: 0.03,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    rarity: '4*'
  },
  {
    char: 'yunli',
    name: 'Yunli',
    current_app_rate: 7.36,
    prev_app_rate: 9.96,
    current_avg_score: 32020,
    prev_avg_score: 31118,
    round_eidolons: 36750,
    rarity: 'Limited 5*'
  }
];
