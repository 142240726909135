export const ApoDuos = [
  {
    char: 'ruan-mei',
    app: 84.81,
    char_1: 'trailblazer-harmony',
    app_rate_1: '87.57%',
    avg_round_1: 3522,
    app_flat_1: 3030,
    char_2: 'Firefly',
    app_rate_2: '71.18%',
    avg_round_2: 3536,
    app_flat_2: 2262,
    char_3: 'Gallagher',
    app_rate_3: '53.24%',
    avg_round_3: 3506,
    app_flat_3: 2065,
    char_4: 'Lingsha',
    app_rate_4: '33.58%',
    avg_round_4: 3563,
    app_flat_4: 942,
    char_5: 'Rappa',
    app_rate_5: '13.95%',
    avg_round_5: 3466,
    app_flat_5: 653,
    char_6: 'Huohuo',
    app_rate_6: '4.22%',
    avg_round_6: 3310,
    app_flat_6: 80,
    char_7: 'Sunday',
    app_rate_7: '3.92%',
    avg_round_7: 3366,
    app_flat_7: 103,
    char_8: 'Kafka',
    app_rate_8: '3.81%',
    avg_round_8: 3172,
    app_flat_8: 42,
    char_9: 'black-swan',
    app_rate_9: '3.63%',
    avg_round_9: 3166,
    app_flat_9: 40,
    char_10: 'Boothill',
    app_rate_10: '3.48%',
    avg_round_10: 3467,
    app_flat_10: 126,
    char_11: 'Aventurine',
    app_rate_11: '2.78%',
    avg_round_11: 3424,
    app_flat_11: 73,
    char_12: 'fu-xuan',
    app_rate_12: '2.39%',
    avg_round_12: 3299,
    app_flat_12: 44,
    char_13: 'Bronya',
    app_rate_13: '2.07%',
    avg_round_13: 3450,
    app_flat_13: 47,
    char_14: 'jing-yuan',
    app_rate_14: '1.95%',
    avg_round_14: 3327,
    app_flat_14: 56,
    char_15: 'Luocha',
    app_rate_15: '1.81%',
    avg_round_15: 3335,
    app_flat_15: 54,
    char_16: 'Feixiao',
    app_rate_16: '1.33%',
    avg_round_16: 3363,
    app_flat_16: 30,
    char_17: 'Acheron',
    app_rate_17: '1.24%',
    avg_round_17: 3280,
    app_flat_17: 10,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '1.02%',
    avg_round_18: 3293,
    app_flat_18: 36,
    char_19: 'Jiaoqiu',
    app_rate_19: '0.74%',
    avg_round_19: 3295,
    app_flat_19: 7,
    char_20: 'Sparkle',
    app_rate_20: '0.7%',
    avg_round_20: 3184,
    app_flat_20: 7,
    char_21: 'topaz',
    app_rate_21: '0.66%',
    avg_round_21: 3373,
    app_flat_21: 12,
    char_22: 'imbibitor-lunae',
    app_rate_22: '0.58%',
    avg_round_22: 3069,
    app_flat_22: 1,
    char_23: 'Xueyi',
    app_rate_23: '0.51%',
    avg_round_23: 3208,
    app_flat_23: 24,
    char_24: 'Jade',
    app_rate_24: '0.46%',
    avg_round_24: 3396,
    app_flat_24: 11,
    char_25: 'silver-wolf',
    app_rate_25: '0.36%',
    avg_round_25: 3268,
    app_flat_25: 5,
    char_26: 'Pela',
    app_rate_26: '0.33%',
    avg_round_26: 3245,
    app_flat_26: 3,
    char_27: 'Robin',
    app_rate_27: '0.32%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Seele',
    app_rate_28: '0.3%',
    avg_round_28: 3211,
    app_flat_28: 7,
    char_29: 'Himeko',
    app_rate_29: '0.25%',
    avg_round_29: 3221,
    app_flat_29: 5,
    char_30: 'Lynx',
    app_rate_30: '0.22%',
    avg_round_30: 3342,
    app_flat_30: 4
  },
  {
    char: 'trailblazer-harmony',
    app: 77.26,
    char_1: 'ruan-mei',
    app_rate_1: '96.12%',
    avg_round_1: 3522,
    app_flat_1: 3030,
    char_2: 'Firefly',
    app_rate_2: '79.61%',
    avg_round_2: 3534,
    app_flat_2: 2314,
    char_3: 'Gallagher',
    app_rate_3: '56.79%',
    avg_round_3: 3506,
    app_flat_3: 2063,
    char_4: 'Lingsha',
    app_rate_4: '37.11%',
    avg_round_4: 3561,
    app_flat_4: 969,
    char_5: 'Rappa',
    app_rate_5: '17.18%',
    avg_round_5: 3464,
    app_flat_5: 753,
    char_6: 'Huohuo',
    app_rate_6: '1.68%',
    avg_round_6: 3379,
    app_flat_6: 38,
    char_7: 'Boothill',
    app_rate_7: '1.59%',
    avg_round_7: 3470,
    app_flat_7: 65,
    char_8: 'Aventurine',
    app_rate_8: '1.53%',
    avg_round_8: 3447,
    app_flat_8: 53,
    char_9: 'fu-xuan',
    app_rate_9: '0.98%',
    avg_round_9: 3336,
    app_flat_9: 22,
    char_10: 'Luocha',
    app_rate_10: '0.91%',
    avg_round_10: 3352,
    app_flat_10: 40,
    char_11: 'Bronya',
    app_rate_11: '0.88%',
    avg_round_11: 3379,
    app_flat_11: 17,
    char_12: 'Sunday',
    app_rate_12: '0.78%',
    avg_round_12: 3385,
    app_flat_12: 30,
    char_13: 'march-7th-swordmaster',
    app_rate_13: '0.72%',
    avg_round_13: 3268,
    app_flat_13: 32,
    char_14: 'Pela',
    app_rate_14: '0.72%',
    avg_round_14: 3523,
    app_flat_14: 36,
    char_15: 'Himeko',
    app_rate_15: '0.64%',
    avg_round_15: 3278,
    app_flat_15: 25,
    char_16: 'Xueyi',
    app_rate_16: '0.55%',
    avg_round_16: 3210,
    app_flat_16: 25,
    char_17: 'Jade',
    app_rate_17: '0.3%',
    avg_round_17: 3382,
    app_flat_17: 10,
    char_18: 'Asta',
    app_rate_18: '0.25%',
    avg_round_18: 3428,
    app_flat_18: 9,
    char_19: 'silver-wolf',
    app_rate_19: '0.19%',
    avg_round_19: 3310,
    app_flat_19: 4,
    char_20: 'Robin',
    app_rate_20: '0.17%',
    avg_round_20: 3413,
    app_flat_20: 4,
    char_21: 'Lynx',
    app_rate_21: '0.16%',
    avg_round_21: 3459,
    app_flat_21: 2,
    char_22: 'Jiaoqiu',
    app_rate_22: '0.16%',
    avg_round_22: 3423,
    app_flat_22: 6,
    char_23: 'Bailu',
    app_rate_23: '0.12%',
    avg_round_23: 3446,
    app_flat_23: 4,
    char_24: 'Acheron',
    app_rate_24: '0.1%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Tingyun',
    app_rate_25: '0.09%',
    avg_round_25: 3374,
    app_flat_25: 4,
    char_26: 'Feixiao',
    app_rate_26: '0.07%',
    avg_round_26: 3352,
    app_flat_26: 4,
    char_27: 'Sparkle',
    app_rate_27: '0.06%',
    avg_round_27: 3334,
    app_flat_27: 4,
    char_28: 'jing-yuan',
    app_rate_28: '0.06%',
    avg_round_28: 3072,
    app_flat_28: 1,
    char_29: 'Herta',
    app_rate_29: '0.06%',
    avg_round_29: 3077,
    app_flat_29: 4,
    char_30: 'topaz',
    app_rate_30: '0.04%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Firefly',
    app: 61.69,
    char_1: 'trailblazer-harmony',
    app_rate_1: '99.69%',
    avg_round_1: 3534,
    app_flat_1: 2314,
    char_2: 'ruan-mei',
    app_rate_2: '97.84%',
    avg_round_2: 3536,
    app_flat_2: 2262,
    char_3: 'Gallagher',
    app_rate_3: '58.99%',
    avg_round_3: 3526,
    app_flat_3: 1575,
    char_4: 'Lingsha',
    app_rate_4: '36.52%',
    avg_round_4: 3565,
    app_flat_4: 673,
    char_5: 'Huohuo',
    app_rate_5: '1.45%',
    avg_round_5: 3376,
    app_flat_5: 24,
    char_6: 'fu-xuan',
    app_rate_6: '1.11%',
    avg_round_6: 3345,
    app_flat_6: 19,
    char_7: 'Bronya',
    app_rate_7: '0.74%',
    avg_round_7: 3367,
    app_flat_7: 4,
    char_8: 'Rappa',
    app_rate_8: '0.67%',
    avg_round_8: 3331,
    app_flat_8: 16,
    char_9: 'Aventurine',
    app_rate_9: '0.51%',
    avg_round_9: 3398,
    app_flat_9: 8,
    char_10: 'Luocha',
    app_rate_10: '0.36%',
    avg_round_10: 3320,
    app_flat_10: 11,
    char_11: 'Asta',
    app_rate_11: '0.29%',
    avg_round_11: 3441,
    app_flat_11: 7,
    char_12: 'march-7th-swordmaster',
    app_rate_12: '0.24%',
    avg_round_12: 3265,
    app_flat_12: 6,
    char_13: 'Lynx',
    app_rate_13: '0.2%',
    avg_round_13: 3459,
    app_flat_13: 2,
    char_14: 'Sunday',
    app_rate_14: '0.18%',
    avg_round_14: 3355,
    app_flat_14: 3,
    char_15: 'Robin',
    app_rate_15: '0.16%',
    avg_round_15: 3454,
    app_flat_15: 1,
    char_16: 'Himeko',
    app_rate_16: '0.14%',
    avg_round_16: 3245,
    app_flat_16: 3,
    char_17: 'Bailu',
    app_rate_17: '0.13%',
    avg_round_17: 3420,
    app_flat_17: 3,
    char_18: 'Pela',
    app_rate_18: '0.11%',
    avg_round_18: 3123,
    app_flat_18: 3,
    char_19: 'silver-wolf',
    app_rate_19: '0.11%',
    avg_round_19: 3318,
    app_flat_19: 1,
    char_20: 'Boothill',
    app_rate_20: '0.09%',
    avg_round_20: 3302,
    app_flat_20: 3,
    char_21: 'Feixiao',
    app_rate_21: '0.07%',
    avg_round_21: 3396,
    app_flat_21: 2,
    char_22: 'Sparkle',
    app_rate_22: '0.05%',
    avg_round_22: 3380,
    app_flat_22: 2,
    char_23: 'Acheron',
    app_rate_23: '0.05%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'topaz',
    app_rate_24: '0.04%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Jiaoqiu',
    app_rate_25: '0.04%',
    avg_round_25: 3069,
    app_flat_25: 1,
    char_26: 'black-swan',
    app_rate_26: '0.04%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Guinaifen',
    app_rate_27: '0.04%',
    avg_round_27: 3074,
    app_flat_27: 1,
    char_28: 'Kafka',
    app_rate_28: '0.04%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Tingyun',
    app_rate_29: '0.04%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Xueyi',
    app_rate_30: '0.02%',
    avg_round_30: 3277,
    app_flat_30: 1
  },
  {
    char: 'Aventurine',
    app: 60.14,
    char_1: 'Acheron',
    app_rate_1: '49.14%',
    avg_round_1: 3432,
    app_flat_1: 1171,
    char_2: 'Robin',
    app_rate_2: '41.35%',
    avg_round_2: 3423,
    app_flat_2: 972,
    char_3: 'Pela',
    app_rate_3: '27.24%',
    avg_round_3: 3450,
    app_flat_3: 877,
    char_4: 'Sunday',
    app_rate_4: '26.6%',
    avg_round_4: 3377,
    app_flat_4: 593,
    char_5: 'Feixiao',
    app_rate_5: '26.01%',
    avg_round_5: 3479,
    app_flat_5: 636,
    char_6: 'Jiaoqiu',
    app_rate_6: '24.89%',
    avg_round_6: 3508,
    app_flat_6: 553,
    char_7: 'topaz',
    app_rate_7: '17.08%',
    avg_round_7: 3486,
    app_flat_7: 357,
    char_8: 'Sparkle',
    app_rate_8: '15.93%',
    avg_round_8: 3324,
    app_flat_8: 177,
    char_9: 'jing-yuan',
    app_rate_9: '15.6%',
    avg_round_9: 3376,
    app_flat_9: 396,
    char_10: 'Kafka',
    app_rate_10: '7.18%',
    avg_round_10: 3332,
    app_flat_10: 199,
    char_11: 'black-swan',
    app_rate_11: '6.99%',
    avg_round_11: 3326,
    app_flat_11: 175,
    char_12: 'silver-wolf',
    app_rate_12: '6.73%',
    avg_round_12: 3375,
    app_flat_12: 213,
    char_13: 'march-7th-swordmaster',
    app_rate_13: '6.66%',
    avg_round_13: 3466,
    app_flat_13: 208,
    char_14: 'ruan-mei',
    app_rate_14: '3.92%',
    avg_round_14: 3424,
    app_flat_14: 73,
    char_15: 'Bronya',
    app_rate_15: '2.81%',
    avg_round_15: 3357,
    app_flat_15: 43,
    char_16: 'Jade',
    app_rate_16: '2.64%',
    avg_round_16: 3246,
    app_flat_16: 38,
    char_17: 'Moze',
    app_rate_17: '2.58%',
    avg_round_17: 3435,
    app_flat_17: 69,
    char_18: 'Tingyun',
    app_rate_18: '2.34%',
    avg_round_18: 3270,
    app_flat_18: 53,
    char_19: 'imbibitor-lunae',
    app_rate_19: '2.1%',
    avg_round_19: 3227,
    app_flat_19: 34,
    char_20: 'trailblazer-harmony',
    app_rate_20: '1.97%',
    avg_round_20: 3447,
    app_flat_20: 53,
    char_21: 'Rappa',
    app_rate_21: '1.78%',
    avg_round_21: 3430,
    app_flat_21: 55,
    char_22: 'Guinaifen',
    app_rate_22: '1.39%',
    avg_round_22: 3395,
    app_flat_22: 53,
    char_23: 'Welt',
    app_rate_23: '1.19%',
    avg_round_23: 3354,
    app_flat_23: 40,
    char_24: 'Herta',
    app_rate_24: '0.84%',
    avg_round_24: 3159,
    app_flat_24: 24,
    char_25: 'dr-ratio',
    app_rate_25: '0.78%',
    avg_round_25: 3159,
    app_flat_25: 6,
    char_26: 'Yunli',
    app_rate_26: '0.65%',
    avg_round_26: 3156,
    app_flat_26: 13,
    char_27: 'Himeko',
    app_rate_27: '0.63%',
    avg_round_27: 3147,
    app_flat_27: 19,
    char_28: 'Firefly',
    app_rate_28: '0.52%',
    avg_round_28: 3398,
    app_flat_28: 8,
    char_29: 'Jingliu',
    app_rate_29: '0.5%',
    avg_round_29: 3114,
    app_flat_29: 15,
    char_30: 'Serval',
    app_rate_30: '0.39%',
    avg_round_30: 3328,
    app_flat_30: 13
  },
  {
    char: 'Gallagher',
    app: 56.52,
    char_1: 'ruan-mei',
    app_rate_1: '79.88%',
    avg_round_1: 3506,
    app_flat_1: 2065,
    char_2: 'trailblazer-harmony',
    app_rate_2: '77.63%',
    avg_round_2: 3506,
    app_flat_2: 2063,
    char_3: 'Firefly',
    app_rate_3: '64.39%',
    avg_round_3: 3526,
    app_flat_3: 1575,
    char_4: 'Rappa',
    app_rate_4: '11.12%',
    avg_round_4: 3412,
    app_flat_4: 417,
    char_5: 'Acheron',
    app_rate_5: '9.97%',
    avg_round_5: 3397,
    app_flat_5: 227,
    char_6: 'Robin',
    app_rate_6: '6.39%',
    avg_round_6: 3447,
    app_flat_6: 134,
    char_7: 'Sunday',
    app_rate_7: '6.35%',
    avg_round_7: 3374,
    app_flat_7: 145,
    char_8: 'Pela',
    app_rate_8: '6.15%',
    avg_round_8: 3408,
    app_flat_8: 192,
    char_9: 'Jiaoqiu',
    app_rate_9: '5.62%',
    avg_round_9: 3469,
    app_flat_9: 136,
    char_10: 'Feixiao',
    app_rate_10: '3.78%',
    avg_round_10: 3491,
    app_flat_10: 97,
    char_11: 'Boothill',
    app_rate_11: '3.7%',
    avg_round_11: 3467,
    app_flat_11: 106,
    char_12: 'jing-yuan',
    app_rate_12: '2.85%',
    avg_round_12: 3390,
    app_flat_12: 70,
    char_13: 'Bronya',
    app_rate_13: '2.79%',
    avg_round_13: 3431,
    app_flat_13: 68,
    char_14: 'Kafka',
    app_rate_14: '2.75%',
    avg_round_14: 3291,
    app_flat_14: 37,
    char_15: 'Sparkle',
    app_rate_15: '2.65%',
    avg_round_15: 3294,
    app_flat_15: 32,
    char_16: 'black-swan',
    app_rate_16: '2.61%',
    avg_round_16: 3283,
    app_flat_16: 38,
    char_17: 'march-7th-swordmaster',
    app_rate_17: '2.59%',
    avg_round_17: 3422,
    app_flat_17: 83,
    char_18: 'topaz',
    app_rate_18: '1.11%',
    avg_round_18: 3493,
    app_flat_18: 22,
    char_19: 'silver-wolf',
    app_rate_19: '1.03%',
    avg_round_19: 3299,
    app_flat_19: 27,
    char_20: 'Jade',
    app_rate_20: '0.93%',
    avg_round_20: 3301,
    app_flat_20: 15,
    char_21: 'Himeko',
    app_rate_21: '0.77%',
    avg_round_21: 3255,
    app_flat_21: 25,
    char_22: 'Guinaifen',
    app_rate_22: '0.67%',
    avg_round_22: 3375,
    app_flat_22: 25,
    char_23: 'Xueyi',
    app_rate_23: '0.63%',
    avg_round_23: 3215,
    app_flat_23: 22,
    char_24: 'Tingyun',
    app_rate_24: '0.53%',
    avg_round_24: 3385,
    app_flat_24: 15,
    char_25: 'imbibitor-lunae',
    app_rate_25: '0.45%',
    avg_round_25: 3145,
    app_flat_25: 1,
    char_26: 'Welt',
    app_rate_26: '0.36%',
    avg_round_26: 3264,
    app_flat_26: 10,
    char_27: 'Asta',
    app_rate_27: '0.26%',
    avg_round_27: 3347,
    app_flat_27: 9,
    char_28: 'Qingque',
    app_rate_28: '0.26%',
    avg_round_28: 3334,
    app_flat_28: 7,
    char_29: 'Serval',
    app_rate_29: '0.24%',
    avg_round_29: 3303,
    app_flat_29: 6,
    char_30: 'Yunli',
    app_rate_30: '0.22%',
    avg_round_30: 3008,
    app_flat_30: 4
  },
  {
    char: 'Acheron',
    app: 56.18,
    char_1: 'Aventurine',
    app_rate_1: '52.6%',
    avg_round_1: 3432,
    app_flat_1: 1171,
    char_2: 'Pela',
    app_rate_2: '51.68%',
    avg_round_2: 3435,
    app_flat_2: 1474,
    char_3: 'Jiaoqiu',
    app_rate_3: '47.34%',
    avg_round_3: 3492,
    app_flat_3: 958,
    char_4: 'Sparkle',
    app_rate_4: '24.62%',
    avg_round_4: 3360,
    app_flat_4: 140,
    char_5: 'fu-xuan',
    app_rate_5: '22.09%',
    avg_round_5: 3373,
    app_flat_5: 374,
    char_6: 'Kafka',
    app_rate_6: '17.0%',
    avg_round_6: 3313,
    app_flat_6: 367,
    char_7: 'black-swan',
    app_rate_7: '15.8%',
    avg_round_7: 3305,
    app_flat_7: 322,
    char_8: 'Sunday',
    app_rate_8: '12.58%',
    avg_round_8: 3401,
    app_flat_8: 177,
    char_9: 'silver-wolf',
    app_rate_9: '12.24%',
    avg_round_9: 3365,
    app_flat_9: 322,
    char_10: 'Gallagher',
    app_rate_10: '10.03%',
    avg_round_10: 3397,
    app_flat_10: 227,
    char_11: 'Luocha',
    app_rate_11: '4.5%',
    avg_round_11: 3363,
    app_flat_11: 92,
    char_12: 'Lingsha',
    app_rate_12: '4.48%',
    avg_round_12: 3409,
    app_flat_12: 95,
    char_13: 'Robin',
    app_rate_13: '4.2%',
    avg_round_13: 3352,
    app_flat_13: 50,
    char_14: 'Bronya',
    app_rate_14: '3.88%',
    avg_round_14: 3323,
    app_flat_14: 12,
    char_15: 'Huohuo',
    app_rate_15: '3.84%',
    avg_round_15: 3311,
    app_flat_15: 45,
    char_16: 'Guinaifen',
    app_rate_16: '3.46%',
    avg_round_16: 3373,
    app_flat_16: 121,
    char_17: 'Welt',
    app_rate_17: '2.69%',
    avg_round_17: 3308,
    app_flat_17: 77,
    char_18: 'ruan-mei',
    app_rate_18: '1.87%',
    avg_round_18: 3280,
    app_flat_18: 10,
    char_19: 'Gepard',
    app_rate_19: '1.21%',
    avg_round_19: 3382,
    app_flat_19: 26,
    char_20: 'Himeko',
    app_rate_20: '0.66%',
    avg_round_20: 3239,
    app_flat_20: 13,
    char_21: 'Jade',
    app_rate_21: '0.44%',
    avg_round_21: 3318,
    app_flat_21: 6,
    char_22: 'Rappa',
    app_rate_22: '0.42%',
    avg_round_22: 3277,
    app_flat_22: 8,
    char_23: 'Yunli',
    app_rate_23: '0.4%',
    avg_round_23: 3207,
    app_flat_23: 12,
    char_24: 'Bailu',
    app_rate_24: '0.32%',
    avg_round_24: 3326,
    app_flat_24: 6,
    char_25: 'Feixiao',
    app_rate_25: '0.28%',
    avg_round_25: 3274,
    app_flat_25: 2,
    char_26: 'Sampo',
    app_rate_26: '0.18%',
    avg_round_26: 3397,
    app_flat_26: 5,
    char_27: 'Clara',
    app_rate_27: '0.16%',
    avg_round_27: 3094,
    app_flat_27: 2,
    char_28: 'trailblazer-harmony',
    app_rate_28: '0.14%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Herta',
    app_rate_29: '0.12%',
    avg_round_29: 3052,
    app_flat_29: 1,
    char_30: 'Lynx',
    app_rate_30: '0.1%',
    avg_round_30: 3348,
    app_flat_30: 3
  },
  {
    char: 'Robin',
    app: 45.08,
    char_1: 'Aventurine',
    app_rate_1: '55.16%',
    avg_round_1: 3423,
    app_flat_1: 972,
    char_2: 'Feixiao',
    app_rate_2: '48.19%',
    avg_round_2: 3464,
    app_flat_2: 865,
    char_3: 'Sunday',
    app_rate_3: '35.52%',
    avg_round_3: 3392,
    app_flat_3: 623,
    char_4: 'jing-yuan',
    app_rate_4: '29.34%',
    avg_round_4: 3391,
    app_flat_4: 535,
    char_5: 'topaz',
    app_rate_5: '26.46%',
    avg_round_5: 3473,
    app_flat_5: 409,
    char_6: 'march-7th-swordmaster',
    app_rate_6: '16.37%',
    avg_round_6: 3479,
    app_flat_6: 362,
    char_7: 'Huohuo',
    app_rate_7: '12.67%',
    avg_round_7: 3361,
    app_flat_7: 193,
    char_8: 'fu-xuan',
    app_rate_8: '9.57%',
    avg_round_8: 3356,
    app_flat_8: 136,
    char_9: 'Gallagher',
    app_rate_9: '8.01%',
    avg_round_9: 3447,
    app_flat_9: 134,
    char_10: 'Jade',
    app_rate_10: '7.22%',
    avg_round_10: 3325,
    app_flat_10: 84,
    char_11: 'Lingsha',
    app_rate_11: '6.65%',
    avg_round_11: 3436,
    app_flat_11: 132,
    char_12: 'Acheron',
    app_rate_12: '5.23%',
    avg_round_12: 3352,
    app_flat_12: 50,
    char_13: 'Luocha',
    app_rate_13: '5.21%',
    avg_round_13: 3363,
    app_flat_13: 78,
    char_14: 'Moze',
    app_rate_14: '3.22%',
    avg_round_14: 3442,
    app_flat_14: 65,
    char_15: 'Jiaoqiu',
    app_rate_15: '3.0%',
    avg_round_15: 3413,
    app_flat_15: 24,
    char_16: 'Kafka',
    app_rate_16: '2.93%',
    avg_round_16: 3185,
    app_flat_16: 15,
    char_17: 'black-swan',
    app_rate_17: '2.85%',
    avg_round_17: 3157,
    app_flat_17: 15,
    char_18: 'Sparkle',
    app_rate_18: '2.58%',
    avg_round_18: 3135,
    app_flat_18: 27,
    char_19: 'Yunli',
    app_rate_19: '2.16%',
    avg_round_19: 3148,
    app_flat_19: 25,
    char_20: 'Bronya',
    app_rate_20: '2.08%',
    avg_round_20: 3412,
    app_flat_20: 20,
    char_21: 'Herta',
    app_rate_21: '1.81%',
    avg_round_21: 3157,
    app_flat_21: 35,
    char_22: 'Pela',
    app_rate_22: '1.66%',
    avg_round_22: 3348,
    app_flat_22: 22,
    char_23: 'Seele',
    app_rate_23: '1.26%',
    avg_round_23: 3254,
    app_flat_23: 8,
    char_24: 'Himeko',
    app_rate_24: '1.19%',
    avg_round_24: 3135,
    app_flat_24: 25,
    char_25: 'imbibitor-lunae',
    app_rate_25: '0.89%',
    avg_round_25: 3222,
    app_flat_25: 12,
    char_26: 'Jingliu',
    app_rate_26: '0.84%',
    avg_round_26: 3136,
    app_flat_26: 13,
    char_27: 'Serval',
    app_rate_27: '0.82%',
    avg_round_27: 3318,
    app_flat_27: 18,
    char_28: 'Rappa',
    app_rate_28: '0.82%',
    avg_round_28: 3179,
    app_flat_28: 18,
    char_29: 'dr-ratio',
    app_rate_29: '0.82%',
    avg_round_29: 3108,
    app_flat_29: 4,
    char_30: 'ruan-mei',
    app_rate_30: '0.6%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Sunday',
    app: 35.63,
    char_1: 'jing-yuan',
    app_rate_1: '57.58%',
    avg_round_1: 3374,
    app_flat_1: 831,
    char_2: 'Robin',
    app_rate_2: '44.93%',
    avg_round_2: 3392,
    app_flat_2: 623,
    char_3: 'Aventurine',
    app_rate_3: '44.9%',
    avg_round_3: 3377,
    app_flat_3: 593,
    char_4: 'Acheron',
    app_rate_4: '19.83%',
    avg_round_4: 3401,
    app_flat_4: 177,
    char_5: 'Huohuo',
    app_rate_5: '16.38%',
    avg_round_5: 3355,
    app_flat_5: 210,
    char_6: 'Jiaoqiu',
    app_rate_6: '11.95%',
    avg_round_6: 3492,
    app_flat_6: 84,
    char_7: 'Sparkle',
    app_rate_7: '11.11%',
    avg_round_7: 3328,
    app_flat_7: 136,
    char_8: 'Gallagher',
    app_rate_8: '10.07%',
    avg_round_8: 3374,
    app_flat_8: 145,
    char_9: 'fu-xuan',
    app_rate_9: '10.04%',
    avg_round_9: 3300,
    app_flat_9: 93,
    char_10: 'ruan-mei',
    app_rate_10: '9.32%',
    avg_round_10: 3366,
    app_flat_10: 103,
    char_11: 'Luocha',
    app_rate_11: '9.32%',
    avg_round_11: 3350,
    app_flat_11: 119,
    char_12: 'Pela',
    app_rate_12: '8.57%',
    avg_round_12: 3314,
    app_flat_12: 111,
    char_13: 'Tingyun',
    app_rate_13: '6.43%',
    avg_round_13: 3321,
    app_flat_13: 98,
    char_14: 'Lingsha',
    app_rate_14: '6.43%',
    avg_round_14: 3444,
    app_flat_14: 98,
    char_15: 'Jade',
    app_rate_15: '4.17%',
    avg_round_15: 3437,
    app_flat_15: 57,
    char_16: 'imbibitor-lunae',
    app_rate_16: '4.02%',
    avg_round_16: 3285,
    app_flat_16: 24,
    char_17: 'Boothill',
    app_rate_17: '2.57%',
    avg_round_17: 3450,
    app_flat_17: 35,
    char_18: 'Bronya',
    app_rate_18: '2.35%',
    avg_round_18: 3340,
    app_flat_18: 31,
    char_19: 'Feixiao',
    app_rate_19: '2.1%',
    avg_round_19: 3410,
    app_flat_19: 19,
    char_20: 'trailblazer-harmony',
    app_rate_20: '1.69%',
    avg_round_20: 3385,
    app_flat_20: 30,
    char_21: 'Seele',
    app_rate_21: '1.69%',
    avg_round_21: 3278,
    app_flat_21: 16,
    char_22: 'Rappa',
    app_rate_22: '1.29%',
    avg_round_22: 3310,
    app_flat_22: 27,
    char_23: 'Serval',
    app_rate_23: '1.22%',
    avg_round_23: 3317,
    app_flat_23: 21,
    char_24: 'Argenti',
    app_rate_24: '1.19%',
    avg_round_24: 3259,
    app_flat_24: 17,
    char_25: 'Jingliu',
    app_rate_25: '1.16%',
    avg_round_25: 3182,
    app_flat_25: 14,
    char_26: 'Qingque',
    app_rate_26: '0.94%',
    avg_round_26: 3324,
    app_flat_26: 10,
    char_27: 'march-7th-swordmaster',
    app_rate_27: '0.94%',
    avg_round_27: 3354,
    app_flat_27: 11,
    char_28: 'silver-wolf',
    app_rate_28: '0.94%',
    avg_round_28: 3312,
    app_flat_28: 4,
    char_29: 'topaz',
    app_rate_29: '0.88%',
    avg_round_29: 3344,
    app_flat_29: 4,
    char_30: 'Yunli',
    app_rate_30: '0.72%',
    avg_round_30: 3029,
    app_flat_30: 7
  },
  {
    char: 'Lingsha',
    app: 35.17,
    char_1: 'trailblazer-harmony',
    app_rate_1: '81.5%',
    avg_round_1: 3561,
    app_flat_1: 969,
    char_2: 'ruan-mei',
    app_rate_2: '80.96%',
    avg_round_2: 3563,
    app_flat_2: 942,
    char_3: 'Firefly',
    app_rate_3: '64.05%',
    avg_round_3: 3565,
    app_flat_3: 673,
    char_4: 'Rappa',
    app_rate_4: '15.86%',
    avg_round_4: 3560,
    app_flat_4: 270,
    char_5: 'Robin',
    app_rate_5: '8.52%',
    avg_round_5: 3436,
    app_flat_5: 132,
    char_6: 'Acheron',
    app_rate_6: '7.15%',
    avg_round_6: 3409,
    app_flat_6: 95,
    char_7: 'Sunday',
    app_rate_7: '6.52%',
    avg_round_7: 3444,
    app_flat_7: 98,
    char_8: 'Pela',
    app_rate_8: '4.74%',
    avg_round_8: 3416,
    app_flat_8: 86,
    char_9: 'Feixiao',
    app_rate_9: '3.56%',
    avg_round_9: 3448,
    app_flat_9: 63,
    char_10: 'Jade',
    app_rate_10: '3.53%',
    avg_round_10: 3482,
    app_flat_10: 51,
    char_11: 'Sparkle',
    app_rate_11: '3.08%',
    avg_round_11: 3307,
    app_flat_11: 38,
    char_12: 'Jiaoqiu',
    app_rate_12: '3.02%',
    avg_round_12: 3498,
    app_flat_12: 35,
    char_13: 'march-7th-swordmaster',
    app_rate_13: '2.13%',
    avg_round_13: 3412,
    app_flat_13: 41,
    char_14: 'Kafka',
    app_rate_14: '1.94%',
    avg_round_14: 3295,
    app_flat_14: 21,
    char_15: 'topaz',
    app_rate_15: '1.94%',
    avg_round_15: 3513,
    app_flat_15: 27,
    char_16: 'black-swan',
    app_rate_16: '1.78%',
    avg_round_16: 3264,
    app_flat_16: 15,
    char_17: 'jing-yuan',
    app_rate_17: '1.43%',
    avg_round_17: 3429,
    app_flat_17: 21,
    char_18: 'Himeko',
    app_rate_18: '1.18%',
    avg_round_18: 3263,
    app_flat_18: 17,
    char_19: 'Bronya',
    app_rate_19: '1.05%',
    avg_round_19: 3284,
    app_flat_19: 12,
    char_20: 'Boothill',
    app_rate_20: '0.92%',
    avg_round_20: 3542,
    app_flat_20: 15,
    char_21: 'silver-wolf',
    app_rate_21: '0.79%',
    avg_round_21: 3416,
    app_flat_21: 15,
    char_22: 'Tingyun',
    app_rate_22: '0.76%',
    avg_round_22: 3224,
    app_flat_22: 11,
    char_23: 'Guinaifen',
    app_rate_23: '0.54%',
    avg_round_23: 3457,
    app_flat_23: 11,
    char_24: 'Herta',
    app_rate_24: '0.48%',
    avg_round_24: 3155,
    app_flat_24: 5,
    char_25: 'Welt',
    app_rate_25: '0.35%',
    avg_round_25: 3311,
    app_flat_25: 6,
    char_26: 'Jingliu',
    app_rate_26: '0.32%',
    avg_round_26: 3114,
    app_flat_26: 5,
    char_27: 'Xueyi',
    app_rate_27: '0.25%',
    avg_round_27: 3284,
    app_flat_27: 4,
    char_28: 'Yunli',
    app_rate_28: '0.22%',
    avg_round_28: 3181,
    app_flat_28: 4,
    char_29: 'imbibitor-lunae',
    app_rate_29: '0.19%',
    avg_round_29: 3169,
    app_flat_29: 3,
    char_30: 'Clara',
    app_rate_30: '0.19%',
    avg_round_30: 3129,
    app_flat_30: 3
  },
  {
    char: 'Pela',
    app: 30.53,
    char_1: 'Acheron',
    app_rate_1: '95.09%',
    avg_round_1: 3435,
    app_flat_1: 1474,
    char_2: 'Aventurine',
    app_rate_2: '53.64%',
    avg_round_2: 3450,
    app_flat_2: 877,
    char_3: 'Jiaoqiu',
    app_rate_3: '32.04%',
    avg_round_3: 3537,
    app_flat_3: 649,
    char_4: 'fu-xuan',
    app_rate_4: '19.96%',
    avg_round_4: 3406,
    app_flat_4: 270,
    char_5: 'Sparkle',
    app_rate_5: '14.28%',
    avg_round_5: 3333,
    app_flat_5: 75,
    char_6: 'silver-wolf',
    app_rate_6: '13.15%',
    avg_round_6: 3378,
    app_flat_6: 252,
    char_7: 'Gallagher',
    app_rate_7: '11.39%',
    avg_round_7: 3408,
    app_flat_7: 192,
    char_8: 'Sunday',
    app_rate_8: '10.0%',
    avg_round_8: 3314,
    app_flat_8: 111,
    char_9: 'Kafka',
    app_rate_9: '6.77%',
    avg_round_9: 3348,
    app_flat_9: 122,
    char_10: 'Lingsha',
    app_rate_10: '5.46%',
    avg_round_10: 3416,
    app_flat_10: 86,
    char_11: 'Luocha',
    app_rate_11: '4.83%',
    avg_round_11: 3366,
    app_flat_11: 70,
    char_12: 'Guinaifen',
    app_rate_12: '4.76%',
    avg_round_12: 3380,
    app_flat_12: 100,
    char_13: 'black-swan',
    app_rate_13: '4.47%',
    avg_round_13: 3334,
    app_flat_13: 79,
    char_14: 'Welt',
    app_rate_14: '3.44%',
    avg_round_14: 3312,
    app_flat_14: 59,
    char_15: 'Bronya',
    app_rate_15: '3.15%',
    avg_round_15: 3221,
    app_flat_15: 10,
    char_16: 'Huohuo',
    app_rate_16: '2.56%',
    avg_round_16: 3376,
    app_flat_16: 26,
    char_17: 'Robin',
    app_rate_17: '2.45%',
    avg_round_17: 3348,
    app_flat_17: 22,
    char_18: 'Rappa',
    app_rate_18: '2.01%',
    avg_round_18: 3506,
    app_flat_18: 41,
    char_19: 'trailblazer-harmony',
    app_rate_19: '1.83%',
    avg_round_19: 3523,
    app_flat_19: 36,
    char_20: 'Gepard',
    app_rate_20: '1.28%',
    avg_round_20: 3402,
    app_flat_20: 21,
    char_21: 'jing-yuan',
    app_rate_21: '0.99%',
    avg_round_21: 3321,
    app_flat_21: 12,
    char_22: 'Himeko',
    app_rate_22: '0.92%',
    avg_round_22: 3217,
    app_flat_22: 14,
    char_23: 'ruan-mei',
    app_rate_23: '0.92%',
    avg_round_23: 3245,
    app_flat_23: 3,
    char_24: 'Yunli',
    app_rate_24: '0.66%',
    avg_round_24: 3254,
    app_flat_24: 9,
    char_25: 'Jade',
    app_rate_25: '0.59%',
    avg_round_25: 3380,
    app_flat_25: 7,
    char_26: 'Jingliu',
    app_rate_26: '0.59%',
    avg_round_26: 3094,
    app_flat_26: 12,
    char_27: 'imbibitor-lunae',
    app_rate_27: '0.44%',
    avg_round_27: 3149,
    app_flat_27: 3,
    char_28: 'Bailu',
    app_rate_28: '0.4%',
    avg_round_28: 3288,
    app_flat_28: 7,
    char_29: 'Herta',
    app_rate_29: '0.29%',
    avg_round_29: 3052,
    app_flat_29: 1,
    char_30: 'Firefly',
    app_rate_30: '0.22%',
    avg_round_30: 3123,
    app_flat_30: 3
  },
  {
    char: 'Jiaoqiu',
    app: 27.92,
    char_1: 'Acheron',
    app_rate_1: '95.27%',
    avg_round_1: 3492,
    app_flat_1: 958,
    char_2: 'Aventurine',
    app_rate_2: '53.62%',
    avg_round_2: 3508,
    app_flat_2: 553,
    char_3: 'Pela',
    app_rate_3: '35.04%',
    avg_round_3: 3537,
    app_flat_3: 649,
    char_4: 'Sparkle',
    app_rate_4: '26.51%',
    avg_round_4: 3412,
    app_flat_4: 53,
    char_5: 'fu-xuan',
    app_rate_5: '19.18%',
    avg_round_5: 3463,
    app_flat_5: 165,
    char_6: 'Sunday',
    app_rate_6: '15.26%',
    avg_round_6: 3492,
    app_flat_6: 84,
    char_7: 'Gallagher',
    app_rate_7: '11.37%',
    avg_round_7: 3469,
    app_flat_7: 136,
    char_8: 'Luocha',
    app_rate_8: '5.37%',
    avg_round_8: 3454,
    app_flat_8: 51,
    char_9: 'Robin',
    app_rate_9: '4.85%',
    avg_round_9: 3413,
    app_flat_9: 24,
    char_10: 'black-swan',
    app_rate_10: '4.73%',
    avg_round_10: 3338,
    app_flat_10: 41,
    char_11: 'Kafka',
    app_rate_11: '4.37%',
    avg_round_11: 3354,
    app_flat_11: 33,
    char_12: 'Huohuo',
    app_rate_12: '3.96%',
    avg_round_12: 3403,
    app_flat_12: 24,
    char_13: 'Lingsha',
    app_rate_13: '3.8%',
    avg_round_13: 3498,
    app_flat_13: 35,
    char_14: 'Bronya',
    app_rate_14: '3.56%',
    avg_round_14: 3409,
    app_flat_14: 4,
    char_15: 'silver-wolf',
    app_rate_15: '3.32%',
    avg_round_15: 3353,
    app_flat_15: 50,
    char_16: 'ruan-mei',
    app_rate_16: '2.24%',
    avg_round_16: 3295,
    app_flat_16: 7,
    char_17: 'jing-yuan',
    app_rate_17: '1.12%',
    avg_round_17: 3449,
    app_flat_17: 7,
    char_18: 'Gepard',
    app_rate_18: '0.92%',
    avg_round_18: 3558,
    app_flat_18: 8,
    char_19: 'Himeko',
    app_rate_19: '0.6%',
    avg_round_19: 3216,
    app_flat_19: 2,
    char_20: 'Guinaifen',
    app_rate_20: '0.52%',
    avg_round_20: 3390,
    app_flat_20: 8,
    char_21: 'Jade',
    app_rate_21: '0.48%',
    avg_round_21: 3335,
    app_flat_21: 2,
    char_22: 'Feixiao',
    app_rate_22: '0.48%',
    avg_round_22: 3179,
    app_flat_22: 3,
    char_23: 'Welt',
    app_rate_23: '0.44%',
    avg_round_23: 3378,
    app_flat_23: 7,
    char_24: 'imbibitor-lunae',
    app_rate_24: '0.44%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'trailblazer-harmony',
    app_rate_25: '0.44%',
    avg_round_25: 3423,
    app_flat_25: 6,
    char_26: 'Rappa',
    app_rate_26: '0.4%',
    avg_round_26: 3376,
    app_flat_26: 8,
    char_27: 'topaz',
    app_rate_27: '0.2%',
    avg_round_27: 2987,
    app_flat_27: 1,
    char_28: 'dr-ratio',
    app_rate_28: '0.16%',
    avg_round_28: 3309,
    app_flat_28: 1,
    char_29: 'Moze',
    app_rate_29: '0.16%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Argenti',
    app_rate_30: '0.16%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Feixiao',
    app: 24.34,
    char_1: 'Robin',
    app_rate_1: '89.25%',
    avg_round_1: 3464,
    app_flat_1: 865,
    char_2: 'Aventurine',
    app_rate_2: '64.26%',
    avg_round_2: 3479,
    app_flat_2: 636,
    char_3: 'topaz',
    app_rate_3: '49.98%',
    avg_round_3: 3469,
    app_flat_3: 432,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '33.21%',
    avg_round_4: 3467,
    app_flat_4: 406,
    char_5: 'fu-xuan',
    app_rate_5: '11.07%',
    avg_round_5: 3400,
    app_flat_5: 94,
    char_6: 'Gallagher',
    app_rate_6: '8.77%',
    avg_round_6: 3491,
    app_flat_6: 97,
    char_7: 'Moze',
    app_rate_7: '6.57%',
    avg_round_7: 3433,
    app_flat_7: 73,
    char_8: 'Jade',
    app_rate_8: '5.93%',
    avg_round_8: 3313,
    app_flat_8: 14,
    char_9: 'Huohuo',
    app_rate_9: '5.51%',
    avg_round_9: 3414,
    app_flat_9: 48,
    char_10: 'Lingsha',
    app_rate_10: '5.14%',
    avg_round_10: 3448,
    app_flat_10: 63,
    char_11: 'ruan-mei',
    app_rate_11: '4.64%',
    avg_round_11: 3363,
    app_flat_11: 30,
    char_12: 'Bronya',
    app_rate_12: '3.86%',
    avg_round_12: 3484,
    app_flat_12: 29,
    char_13: 'Sunday',
    app_rate_13: '3.08%',
    avg_round_13: 3410,
    app_flat_13: 19,
    char_14: 'Luocha',
    app_rate_14: '1.79%',
    avg_round_14: 3409,
    app_flat_14: 11,
    char_15: 'Sparkle',
    app_rate_15: '1.7%',
    avg_round_15: 3296,
    app_flat_15: 12,
    char_16: 'Yunli',
    app_rate_16: '1.01%',
    avg_round_16: 3319,
    app_flat_16: 7,
    char_17: 'Acheron',
    app_rate_17: '0.64%',
    avg_round_17: 3274,
    app_flat_17: 2,
    char_18: 'Jiaoqiu',
    app_rate_18: '0.55%',
    avg_round_18: 3179,
    app_flat_18: 3,
    char_19: 'silver-wolf',
    app_rate_19: '0.41%',
    avg_round_19: 3440,
    app_flat_19: 1,
    char_20: 'Bailu',
    app_rate_20: '0.23%',
    avg_round_20: 3369,
    app_flat_20: 4,
    char_21: 'Himeko',
    app_rate_21: '0.23%',
    avg_round_21: 3100,
    app_flat_21: 3,
    char_22: 'trailblazer-harmony',
    app_rate_22: '0.23%',
    avg_round_22: 3352,
    app_flat_22: 4,
    char_23: 'Firefly',
    app_rate_23: '0.18%',
    avg_round_23: 3396,
    app_flat_23: 2,
    char_24: 'Rappa',
    app_rate_24: '0.18%',
    avg_round_24: 3123,
    app_flat_24: 2,
    char_25: 'Herta',
    app_rate_25: '0.14%',
    avg_round_25: 3097,
    app_flat_25: 1,
    char_26: 'Tingyun',
    app_rate_26: '0.14%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Kafka',
    app_rate_27: '0.14%',
    avg_round_27: 3357,
    app_flat_27: 1,
    char_28: 'Clara',
    app_rate_28: '0.14%',
    avg_round_28: 3311,
    app_flat_28: 1,
    char_29: 'Lynx',
    app_rate_29: '0.14%',
    avg_round_29: 3355,
    app_flat_29: 1,
    char_30: 'Xueyi',
    app_rate_30: '0.14%',
    avg_round_30: 3560,
    app_flat_30: 1
  },
  {
    char: 'Sparkle',
    app: 22.82,
    char_1: 'Acheron',
    app_rate_1: '60.61%',
    avg_round_1: 3360,
    app_flat_1: 140,
    char_2: 'Aventurine',
    app_rate_2: '41.99%',
    avg_round_2: 3324,
    app_flat_2: 177,
    char_3: 'Jiaoqiu',
    app_rate_3: '32.44%',
    avg_round_3: 3412,
    app_flat_3: 53,
    char_4: 'fu-xuan',
    app_rate_4: '31.41%',
    avg_round_4: 3269,
    app_flat_4: 83,
    char_5: 'Pela',
    app_rate_5: '19.11%',
    avg_round_5: 3333,
    app_flat_5: 75,
    char_6: 'Sunday',
    app_rate_6: '17.34%',
    avg_round_6: 3328,
    app_flat_6: 136,
    char_7: 'silver-wolf',
    app_rate_7: '11.56%',
    avg_round_7: 3258,
    app_flat_7: 29,
    char_8: 'jing-yuan',
    app_rate_8: '10.93%',
    avg_round_8: 3317,
    app_flat_8: 112,
    char_9: 'Huohuo',
    app_rate_9: '7.99%',
    avg_round_9: 3280,
    app_flat_9: 39,
    char_10: 'imbibitor-lunae',
    app_rate_10: '7.69%',
    avg_round_10: 3215,
    app_flat_10: 34,
    char_11: 'Seele',
    app_rate_11: '7.01%',
    avg_round_11: 3187,
    app_flat_11: 15,
    char_12: 'Gallagher',
    app_rate_12: '6.57%',
    avg_round_12: 3294,
    app_flat_12: 32,
    char_13: 'Qingque',
    app_rate_13: '6.12%',
    avg_round_13: 3262,
    app_flat_13: 39,
    char_14: 'Luocha',
    app_rate_14: '5.19%',
    avg_round_14: 3195,
    app_flat_14: 20,
    char_15: 'Robin',
    app_rate_15: '5.1%',
    avg_round_15: 3135,
    app_flat_15: 27,
    char_16: 'Lingsha',
    app_rate_16: '4.75%',
    avg_round_16: 3307,
    app_flat_16: 38,
    char_17: 'Tingyun',
    app_rate_17: '4.26%',
    avg_round_17: 3151,
    app_flat_17: 29,
    char_18: 'ruan-mei',
    app_rate_18: '2.6%',
    avg_round_18: 3184,
    app_flat_18: 7,
    char_19: 'black-swan',
    app_rate_19: '1.86%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Feixiao',
    app_rate_20: '1.81%',
    avg_round_20: 3296,
    app_flat_20: 12,
    char_21: 'Kafka',
    app_rate_21: '1.76%',
    avg_round_21: 3316,
    app_flat_21: 4,
    char_22: 'Jade',
    app_rate_22: '1.52%',
    avg_round_22: 3267,
    app_flat_22: 8,
    char_23: 'Yunli',
    app_rate_23: '1.18%',
    avg_round_23: 3007,
    app_flat_23: 6,
    char_24: 'Bronya',
    app_rate_24: '1.08%',
    avg_round_24: 3025,
    app_flat_24: 4,
    char_25: 'march-7th-swordmaster',
    app_rate_25: '0.93%',
    avg_round_25: 3283,
    app_flat_25: 8,
    char_26: 'Argenti',
    app_rate_26: '0.78%',
    avg_round_26: 3325,
    app_flat_26: 3,
    char_27: 'Herta',
    app_rate_27: '0.64%',
    avg_round_27: 2955,
    app_flat_27: 2,
    char_28: 'Rappa',
    app_rate_28: '0.64%',
    avg_round_28: 3161,
    app_flat_28: 7,
    char_29: 'Welt',
    app_rate_29: '0.64%',
    avg_round_29: 3189,
    app_flat_29: 4,
    char_30: 'Serval',
    app_rate_30: '0.59%',
    avg_round_30: 3126,
    app_flat_30: 5
  },
  {
    char: 'fu-xuan',
    app: 22.13,
    char_1: 'Acheron',
    app_rate_1: '56.09%',
    avg_round_1: 3373,
    app_flat_1: 374,
    char_2: 'Sparkle',
    app_rate_2: '32.39%',
    avg_round_2: 3269,
    app_flat_2: 83,
    char_3: 'Pela',
    app_rate_3: '27.54%',
    avg_round_3: 3406,
    app_flat_3: 270,
    char_4: 'Jiaoqiu',
    app_rate_4: '24.2%',
    avg_round_4: 3463,
    app_flat_4: 165,
    char_5: 'Robin',
    app_rate_5: '19.5%',
    avg_round_5: 3356,
    app_flat_5: 136,
    char_6: 'Sunday',
    app_rate_6: '16.17%',
    avg_round_6: 3300,
    app_flat_6: 93,
    char_7: 'silver-wolf',
    app_rate_7: '15.46%',
    avg_round_7: 3328,
    app_flat_7: 85,
    char_8: 'Kafka',
    app_rate_8: '14.4%',
    avg_round_8: 3275,
    app_flat_8: 95,
    char_9: 'black-swan',
    app_rate_9: '13.14%',
    avg_round_9: 3265,
    app_flat_9: 79,
    char_10: 'Feixiao',
    app_rate_10: '12.18%',
    avg_round_10: 3400,
    app_flat_10: 94,
    char_11: 'jing-yuan',
    app_rate_11: '10.06%',
    avg_round_11: 3295,
    app_flat_11: 77,
    char_12: 'ruan-mei',
    app_rate_12: '9.15%',
    avg_round_12: 3299,
    app_flat_12: 44,
    char_13: 'Seele',
    app_rate_13: '7.23%',
    avg_round_13: 3218,
    app_flat_13: 17,
    char_14: 'march-7th-swordmaster',
    app_rate_14: '6.42%',
    avg_round_14: 3433,
    app_flat_14: 63,
    char_15: 'Qingque',
    app_rate_15: '4.65%',
    avg_round_15: 3273,
    app_flat_15: 24,
    char_16: 'topaz',
    app_rate_16: '4.5%',
    avg_round_16: 3335,
    app_flat_16: 28,
    char_17: 'trailblazer-harmony',
    app_rate_17: '3.44%',
    avg_round_17: 3336,
    app_flat_17: 22,
    char_18: 'Jade',
    app_rate_18: '3.23%',
    avg_round_18: 3194,
    app_flat_18: 13,
    char_19: 'Bronya',
    app_rate_19: '3.18%',
    avg_round_19: 3296,
    app_flat_19: 6,
    char_20: 'Firefly',
    app_rate_20: '3.08%',
    avg_round_20: 3345,
    app_flat_20: 19,
    char_21: 'Tingyun',
    app_rate_21: '2.98%',
    avg_round_21: 3248,
    app_flat_21: 20,
    char_22: 'Guinaifen',
    app_rate_22: '2.12%',
    avg_round_22: 3333,
    app_flat_22: 23,
    char_23: 'Welt',
    app_rate_23: '1.52%',
    avg_round_23: 3228,
    app_flat_23: 18,
    char_24: 'Yunli',
    app_rate_24: '1.16%',
    avg_round_24: 3191,
    app_flat_24: 9,
    char_25: 'Herta',
    app_rate_25: '0.96%',
    avg_round_25: 3054,
    app_flat_25: 5,
    char_26: 'Rappa',
    app_rate_26: '0.81%',
    avg_round_26: 3136,
    app_flat_26: 6,
    char_27: 'Blade',
    app_rate_27: '0.61%',
    avg_round_27: 3165,
    app_flat_27: 4,
    char_28: 'Boothill',
    app_rate_28: '0.51%',
    avg_round_28: 3324,
    app_flat_28: 5,
    char_29: 'Moze',
    app_rate_29: '0.51%',
    avg_round_29: 3331,
    app_flat_29: 4,
    char_30: 'Himeko',
    app_rate_30: '0.45%',
    avg_round_30: 3209,
    app_flat_30: 2
  },
  {
    char: 'jing-yuan',
    app: 20.96,
    char_1: 'Sunday',
    app_rate_1: '97.87%',
    avg_round_1: 3374,
    app_flat_1: 831,
    char_2: 'Robin',
    app_rate_2: '63.09%',
    avg_round_2: 3391,
    app_flat_2: 535,
    char_3: 'Aventurine',
    app_rate_3: '44.75%',
    avg_round_3: 3376,
    app_flat_3: 396,
    char_4: 'Huohuo',
    app_rate_4: '21.39%',
    avg_round_4: 3368,
    app_flat_4: 179,
    char_5: 'Sparkle',
    app_rate_5: '11.89%',
    avg_round_5: 3317,
    app_flat_5: 112,
    char_6: 'Luocha',
    app_rate_6: '11.47%',
    avg_round_6: 3378,
    app_flat_6: 96,
    char_7: 'fu-xuan',
    app_rate_7: '10.61%',
    avg_round_7: 3295,
    app_flat_7: 77,
    char_8: 'Tingyun',
    app_rate_8: '9.92%',
    avg_round_8: 3326,
    app_flat_8: 85,
    char_9: 'ruan-mei',
    app_rate_9: '7.89%',
    avg_round_9: 3327,
    app_flat_9: 56,
    char_10: 'Gallagher',
    app_rate_10: '7.68%',
    avg_round_10: 3390,
    app_flat_10: 70,
    char_11: 'Jade',
    app_rate_11: '2.45%',
    avg_round_11: 3398,
    app_flat_11: 16,
    char_12: 'Bronya',
    app_rate_12: '2.45%',
    avg_round_12: 3370,
    app_flat_12: 23,
    char_13: 'Lingsha',
    app_rate_13: '2.4%',
    avg_round_13: 3429,
    app_flat_13: 21,
    char_14: 'Jiaoqiu',
    app_rate_14: '1.49%',
    avg_round_14: 3449,
    app_flat_14: 7,
    char_15: 'Pela',
    app_rate_15: '1.44%',
    avg_round_15: 3321,
    app_flat_15: 12,
    char_16: 'Bailu',
    app_rate_16: '0.59%',
    avg_round_16: 3257,
    app_flat_16: 3,
    char_17: 'Moze',
    app_rate_17: '0.27%',
    avg_round_17: 3410,
    app_flat_17: 1,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '0.27%',
    avg_round_18: 3299,
    app_flat_18: 1,
    char_19: 'topaz',
    app_rate_19: '0.27%',
    avg_round_19: 3235,
    app_flat_19: 1,
    char_20: 'Gepard',
    app_rate_20: '0.21%',
    avg_round_20: 3444,
    app_flat_20: 2,
    char_21: 'Herta',
    app_rate_21: '0.21%',
    avg_round_21: 3039,
    app_flat_21: 1,
    char_22: 'trailblazer-harmony',
    app_rate_22: '0.21%',
    avg_round_22: 3072,
    app_flat_22: 1,
    char_23: 'Acheron',
    app_rate_23: '0.21%',
    avg_round_23: 3117,
    app_flat_23: 1,
    char_24: 'Rappa',
    app_rate_24: '0.16%',
    avg_round_24: 3412,
    app_flat_24: 1,
    char_25: 'Asta',
    app_rate_25: '0.16%',
    avg_round_25: 3284,
    app_flat_25: 2,
    char_26: 'Himeko',
    app_rate_26: '0.11%',
    avg_round_26: 3066,
    app_flat_26: 2,
    char_27: 'Feixiao',
    app_rate_27: '0.11%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Hanya',
    app_rate_28: '0.11%',
    avg_round_28: 3377,
    app_flat_28: 1,
    char_29: 'Yunli',
    app_rate_29: '0.11%',
    avg_round_29: 2937,
    app_flat_29: 1,
    char_30: 'Blade',
    app_rate_30: '0.05%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Kafka',
    app: 14.64,
    char_1: 'black-swan',
    app_rate_1: '71.28%',
    avg_round_1: 3259,
    app_flat_1: 242,
    char_2: 'Acheron',
    app_rate_2: '65.24%',
    avg_round_2: 3313,
    app_flat_2: 367,
    char_3: 'Aventurine',
    app_rate_3: '29.49%',
    avg_round_3: 3332,
    app_flat_3: 199,
    char_4: 'Huohuo',
    app_rate_4: '24.6%',
    avg_round_4: 3175,
    app_flat_4: 41,
    char_5: 'ruan-mei',
    app_rate_5: '22.08%',
    avg_round_5: 3172,
    app_flat_5: 42,
    char_6: 'fu-xuan',
    app_rate_6: '21.77%',
    avg_round_6: 3275,
    app_flat_6: 95,
    char_7: 'Pela',
    app_rate_7: '14.13%',
    avg_round_7: 3348,
    app_flat_7: 122,
    char_8: 'Gallagher',
    app_rate_8: '10.62%',
    avg_round_8: 3291,
    app_flat_8: 37,
    char_9: 'Robin',
    app_rate_9: '9.01%',
    avg_round_9: 3185,
    app_flat_9: 15,
    char_10: 'Jiaoqiu',
    app_rate_10: '8.33%',
    avg_round_10: 3354,
    app_flat_10: 33,
    char_11: 'Luocha',
    app_rate_11: '5.96%',
    avg_round_11: 3222,
    app_flat_11: 22,
    char_12: 'Lingsha',
    app_rate_12: '4.66%',
    avg_round_12: 3295,
    app_flat_12: 21,
    char_13: 'Sparkle',
    app_rate_13: '2.75%',
    avg_round_13: 3316,
    app_flat_13: 4,
    char_14: 'Guinaifen',
    app_rate_14: '1.68%',
    avg_round_14: 3185,
    app_flat_14: 6,
    char_15: 'Bailu',
    app_rate_15: '1.07%',
    avg_round_15: 3344,
    app_flat_15: 2,
    char_16: 'Gepard',
    app_rate_16: '1.07%',
    avg_round_16: 3322,
    app_flat_16: 7,
    char_17: 'Sunday',
    app_rate_17: '0.92%',
    avg_round_17: 3359,
    app_flat_17: 5,
    char_18: 'silver-wolf',
    app_rate_18: '0.92%',
    avg_round_18: 3222,
    app_flat_18: 4,
    char_19: 'Sampo',
    app_rate_19: '0.53%',
    avg_round_19: 3122,
    app_flat_19: 1,
    char_20: 'Tingyun',
    app_rate_20: '0.46%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Asta',
    app_rate_21: '0.46%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Rappa',
    app_rate_22: '0.46%',
    avg_round_22: 3189,
    app_flat_22: 1,
    char_23: 'Bronya',
    app_rate_23: '0.38%',
    avg_round_23: 3246,
    app_flat_23: 1,
    char_24: 'Himeko',
    app_rate_24: '0.38%',
    avg_round_24: 3180,
    app_flat_24: 2,
    char_25: 'Welt',
    app_rate_25: '0.31%',
    avg_round_25: 3250,
    app_flat_25: 3,
    char_26: 'Lynx',
    app_rate_26: '0.23%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Feixiao',
    app_rate_27: '0.23%',
    avg_round_27: 3357,
    app_flat_27: 1,
    char_28: 'Serval',
    app_rate_28: '0.23%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'march-7th-swordmaster',
    app_rate_29: '0.15%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Yunli',
    app_rate_30: '0.15%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Rappa',
    app: 14.41,
    char_1: 'trailblazer-harmony',
    app_rate_1: '92.09%',
    avg_round_1: 3464,
    app_flat_1: 753,
    char_2: 'ruan-mei',
    app_rate_2: '82.08%',
    avg_round_2: 3466,
    app_flat_2: 653,
    char_3: 'Gallagher',
    app_rate_3: '43.6%',
    avg_round_3: 3412,
    app_flat_3: 417,
    char_4: 'Lingsha',
    app_rate_4: '38.71%',
    avg_round_4: 3560,
    app_flat_4: 270,
    char_5: 'Aventurine',
    app_rate_5: '7.45%',
    avg_round_5: 3430,
    app_flat_5: 55,
    char_6: 'Pela',
    app_rate_6: '4.27%',
    avg_round_6: 3506,
    app_flat_6: 41,
    char_7: 'Luocha',
    app_rate_7: '4.11%',
    avg_round_7: 3318,
    app_flat_7: 36,
    char_8: 'Huohuo',
    app_rate_8: '3.65%',
    avg_round_8: 3303,
    app_flat_8: 19,
    char_9: 'Sunday',
    app_rate_9: '3.18%',
    avg_round_9: 3310,
    app_flat_9: 27,
    char_10: 'Firefly',
    app_rate_10: '2.87%',
    avg_round_10: 3331,
    app_flat_10: 16,
    char_11: 'Robin',
    app_rate_11: '2.56%',
    avg_round_11: 3179,
    app_flat_11: 18,
    char_12: 'Himeko',
    app_rate_12: '2.25%',
    avg_round_12: 3269,
    app_flat_12: 24,
    char_13: 'Acheron',
    app_rate_13: '1.63%',
    avg_round_13: 3277,
    app_flat_13: 8,
    char_14: 'Jade',
    app_rate_14: '1.47%',
    avg_round_14: 3278,
    app_flat_14: 12,
    char_15: 'fu-xuan',
    app_rate_15: '1.24%',
    avg_round_15: 3136,
    app_flat_15: 6,
    char_16: 'Sparkle',
    app_rate_16: '1.01%',
    avg_round_16: 3161,
    app_flat_16: 7,
    char_17: 'Tingyun',
    app_rate_17: '0.93%',
    avg_round_17: 3250,
    app_flat_17: 8,
    char_18: 'Yunli',
    app_rate_18: '0.78%',
    avg_round_18: 3120,
    app_flat_18: 5,
    char_19: 'Jiaoqiu',
    app_rate_19: '0.78%',
    avg_round_19: 3376,
    app_flat_19: 8,
    char_20: 'march-7th-swordmaster',
    app_rate_20: '0.78%',
    avg_round_20: 3373,
    app_flat_20: 5,
    char_21: 'Herta',
    app_rate_21: '0.62%',
    avg_round_21: 3094,
    app_flat_21: 5,
    char_22: 'Bronya',
    app_rate_22: '0.54%',
    avg_round_22: 3404,
    app_flat_22: 6,
    char_23: 'Kafka',
    app_rate_23: '0.47%',
    avg_round_23: 3189,
    app_flat_23: 1,
    char_24: 'Clara',
    app_rate_24: '0.31%',
    avg_round_24: 3082,
    app_flat_24: 2,
    char_25: 'Bailu',
    app_rate_25: '0.31%',
    avg_round_25: 3238,
    app_flat_25: 3,
    char_26: 'Feixiao',
    app_rate_26: '0.31%',
    avg_round_26: 3123,
    app_flat_26: 2,
    char_27: 'Asta',
    app_rate_27: '0.31%',
    avg_round_27: 3180,
    app_flat_27: 4,
    char_28: 'Welt',
    app_rate_28: '0.23%',
    avg_round_28: 3344,
    app_flat_28: 2,
    char_29: 'jing-yuan',
    app_rate_29: '0.23%',
    avg_round_29: 3412,
    app_flat_29: 1,
    char_30: 'Jingliu',
    app_rate_30: '0.23%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Huohuo',
    app: 14.08,
    char_1: 'Sunday',
    app_rate_1: '41.46%',
    avg_round_1: 3355,
    app_flat_1: 210,
    char_2: 'Robin',
    app_rate_2: '40.59%',
    avg_round_2: 3361,
    app_flat_2: 193,
    char_3: 'jing-yuan',
    app_rate_3: '31.85%',
    avg_round_3: 3368,
    app_flat_3: 179,
    char_4: 'Kafka',
    app_rate_4: '25.58%',
    avg_round_4: 3175,
    app_flat_4: 41,
    char_5: 'ruan-mei',
    app_rate_5: '25.42%',
    avg_round_5: 3310,
    app_flat_5: 80,
    char_6: 'black-swan',
    app_rate_6: '23.59%',
    avg_round_6: 3174,
    app_flat_6: 35,
    char_7: 'Acheron',
    app_rate_7: '15.33%',
    avg_round_7: 3311,
    app_flat_7: 45,
    char_8: 'Sparkle',
    app_rate_8: '12.95%',
    avg_round_8: 3280,
    app_flat_8: 39,
    char_9: 'Feixiao',
    app_rate_9: '9.53%',
    avg_round_9: 3414,
    app_flat_9: 48,
    char_10: 'trailblazer-harmony',
    app_rate_10: '9.21%',
    avg_round_10: 3379,
    app_flat_10: 38,
    char_11: 'Jiaoqiu',
    app_rate_11: '7.86%',
    avg_round_11: 3403,
    app_flat_11: 24,
    char_12: 'Firefly',
    app_rate_12: '6.35%',
    avg_round_12: 3376,
    app_flat_12: 24,
    char_13: 'march-7th-swordmaster',
    app_rate_13: '6.04%',
    avg_round_13: 3425,
    app_flat_13: 41,
    char_14: 'Pela',
    app_rate_14: '5.56%',
    avg_round_14: 3376,
    app_flat_14: 26,
    char_15: 'Tingyun',
    app_rate_15: '5.4%',
    avg_round_15: 3222,
    app_flat_15: 31,
    char_16: 'Jade',
    app_rate_16: '4.13%',
    avg_round_16: 3208,
    app_flat_16: 20,
    char_17: 'Yunli',
    app_rate_17: '4.05%',
    avg_round_17: 3089,
    app_flat_17: 15,
    char_18: 'Rappa',
    app_rate_18: '3.73%',
    avg_round_18: 3303,
    app_flat_18: 19,
    char_19: 'topaz',
    app_rate_19: '3.42%',
    avg_round_19: 3341,
    app_flat_19: 6,
    char_20: 'imbibitor-lunae',
    app_rate_20: '2.54%',
    avg_round_20: 3175,
    app_flat_20: 1,
    char_21: 'Seele',
    app_rate_21: '2.14%',
    avg_round_21: 3259,
    app_flat_21: 7,
    char_22: 'silver-wolf',
    app_rate_22: '2.07%',
    avg_round_22: 3150,
    app_flat_22: 5,
    char_23: 'Bronya',
    app_rate_23: '1.75%',
    avg_round_23: 3313,
    app_flat_23: 6,
    char_24: 'Argenti',
    app_rate_24: '1.51%',
    avg_round_24: 3266,
    app_flat_24: 12,
    char_25: 'Blade',
    app_rate_25: '1.35%',
    avg_round_25: 3220,
    app_flat_25: 3,
    char_26: 'Qingque',
    app_rate_26: '0.87%',
    avg_round_26: 3177,
    app_flat_26: 3,
    char_27: 'Serval',
    app_rate_27: '0.79%',
    avg_round_27: 3263,
    app_flat_27: 5,
    char_28: 'Boothill',
    app_rate_28: '0.79%',
    avg_round_28: 3460,
    app_flat_28: 2,
    char_29: 'Guinaifen',
    app_rate_29: '0.71%',
    avg_round_29: 3121,
    app_flat_29: 1,
    char_30: 'Himeko',
    app_rate_30: '0.56%',
    avg_round_30: 3105,
    app_flat_30: 5
  },
  {
    char: 'black-swan',
    app: 13.79,
    char_1: 'Kafka',
    app_rate_1: '75.67%',
    avg_round_1: 3259,
    app_flat_1: 242,
    char_2: 'Acheron',
    app_rate_2: '64.4%',
    avg_round_2: 3305,
    app_flat_2: 322,
    char_3: 'Aventurine',
    app_rate_3: '30.49%',
    avg_round_3: 3326,
    app_flat_3: 175,
    char_4: 'Huohuo',
    app_rate_4: '24.09%',
    avg_round_4: 3174,
    app_flat_4: 35,
    char_5: 'ruan-mei',
    app_rate_5: '22.3%',
    avg_round_5: 3166,
    app_flat_5: 40,
    char_6: 'fu-xuan',
    app_rate_6: '21.09%',
    avg_round_6: 3265,
    app_flat_6: 79,
    char_7: 'Gallagher',
    app_rate_7: '10.71%',
    avg_round_7: 3283,
    app_flat_7: 38,
    char_8: 'Pela',
    app_rate_8: '9.89%',
    avg_round_8: 3334,
    app_flat_8: 79,
    char_9: 'Jiaoqiu',
    app_rate_9: '9.57%',
    avg_round_9: 3338,
    app_flat_9: 41,
    char_10: 'Robin',
    app_rate_10: '9.33%',
    avg_round_10: 3157,
    app_flat_10: 15,
    char_11: 'Luocha',
    app_rate_11: '6.16%',
    avg_round_11: 3206,
    app_flat_11: 25,
    char_12: 'Lingsha',
    app_rate_12: '4.54%',
    avg_round_12: 3264,
    app_flat_12: 15,
    char_13: 'Sparkle',
    app_rate_13: '3.08%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Sunday',
    app_rate_14: '1.3%',
    avg_round_14: 3327,
    app_flat_14: 3,
    char_15: 'Bailu',
    app_rate_15: '1.14%',
    avg_round_15: 3348,
    app_flat_15: 1,
    char_16: 'Guinaifen',
    app_rate_16: '1.14%',
    avg_round_16: 3236,
    app_flat_16: 2,
    char_17: 'Gepard',
    app_rate_17: '0.97%',
    avg_round_17: 3280,
    app_flat_17: 6,
    char_18: 'silver-wolf',
    app_rate_18: '0.89%',
    avg_round_18: 3325,
    app_flat_18: 5,
    char_19: 'Sampo',
    app_rate_19: '0.65%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Bronya',
    app_rate_20: '0.41%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Asta',
    app_rate_21: '0.41%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Lynx',
    app_rate_22: '0.32%',
    avg_round_22: 3304,
    app_flat_22: 1,
    char_23: 'Welt',
    app_rate_23: '0.24%',
    avg_round_23: 3161,
    app_flat_23: 2,
    char_24: 'Tingyun',
    app_rate_24: '0.24%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'march-7th',
    app_rate_25: '0.16%',
    avg_round_25: 3494,
    app_flat_25: 1,
    char_26: 'Firefly',
    app_rate_26: '0.16%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Clara',
    app_rate_27: '0.16%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'march-7th-swordmaster',
    app_rate_28: '0.08%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Rappa',
    app_rate_29: '0.08%',
    avg_round_29: 3039,
    app_flat_29: 1,
    char_30: 'Blade',
    app_rate_30: '0.08%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'topaz',
    app: 13.28,
    char_1: 'Feixiao',
    app_rate_1: '91.58%',
    avg_round_1: 3469,
    app_flat_1: 432,
    char_2: 'Robin',
    app_rate_2: '89.81%',
    avg_round_2: 3473,
    app_flat_2: 409,
    char_3: 'Aventurine',
    app_rate_3: '77.36%',
    avg_round_3: 3486,
    app_flat_3: 357,
    char_4: 'fu-xuan',
    app_rate_4: '7.49%',
    avg_round_4: 3335,
    app_flat_4: 28,
    char_5: 'Lingsha',
    app_rate_5: '5.13%',
    avg_round_5: 3513,
    app_flat_5: 27,
    char_6: 'Gallagher',
    app_rate_6: '4.71%',
    avg_round_6: 3493,
    app_flat_6: 22,
    char_7: 'ruan-mei',
    app_rate_7: '4.21%',
    avg_round_7: 3373,
    app_flat_7: 12,
    char_8: 'Huohuo',
    app_rate_8: '3.62%',
    avg_round_8: 3341,
    app_flat_8: 6,
    char_9: 'Jade',
    app_rate_9: '2.53%',
    avg_round_9: 3265,
    app_flat_9: 1,
    char_10: 'Sunday',
    app_rate_10: '2.36%',
    avg_round_10: 3344,
    app_flat_10: 4,
    char_11: 'dr-ratio',
    app_rate_11: '2.1%',
    avg_round_11: 3119,
    app_flat_11: 4,
    char_12: 'Moze',
    app_rate_12: '1.6%',
    avg_round_12: 3345,
    app_flat_12: 5,
    char_13: 'march-7th-swordmaster',
    app_rate_13: '1.35%',
    avg_round_13: 3306,
    app_flat_13: 4,
    char_14: 'Sparkle',
    app_rate_14: '1.01%',
    avg_round_14: 3295,
    app_flat_14: 4,
    char_15: 'Luocha',
    app_rate_15: '0.84%',
    avg_round_15: 3147,
    app_flat_15: 1,
    char_16: 'Bronya',
    app_rate_16: '0.76%',
    avg_round_16: 3445,
    app_flat_16: 5,
    char_17: 'Yunli',
    app_rate_17: '0.67%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'silver-wolf',
    app_rate_18: '0.42%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Jiaoqiu',
    app_rate_19: '0.42%',
    avg_round_19: 2987,
    app_flat_19: 1,
    char_20: 'Himeko',
    app_rate_20: '0.42%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'jing-yuan',
    app_rate_21: '0.42%',
    avg_round_21: 3235,
    app_flat_21: 1,
    char_22: 'Acheron',
    app_rate_22: '0.25%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'trailblazer-harmony',
    app_rate_23: '0.25%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Firefly',
    app_rate_24: '0.17%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Tingyun',
    app_rate_25: '0.17%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Asta',
    app_rate_26: '0.08%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Rappa',
    app_rate_27: '0.08%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Xueyi',
    app_rate_28: '0.08%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th-swordmaster',
    app: 9.29,
    char_1: 'Feixiao',
    app_rate_1: '87.0%',
    avg_round_1: 3467,
    app_flat_1: 406,
    char_2: 'Robin',
    app_rate_2: '79.42%',
    avg_round_2: 3479,
    app_flat_2: 362,
    char_3: 'Aventurine',
    app_rate_3: '43.08%',
    avg_round_3: 3466,
    app_flat_3: 208,
    char_4: 'Gallagher',
    app_rate_4: '15.76%',
    avg_round_4: 3422,
    app_flat_4: 83,
    char_5: 'fu-xuan',
    app_rate_5: '15.28%',
    avg_round_5: 3433,
    app_flat_5: 63,
    char_6: 'ruan-mei',
    app_rate_6: '9.27%',
    avg_round_6: 3293,
    app_flat_6: 36,
    char_7: 'Huohuo',
    app_rate_7: '9.15%',
    avg_round_7: 3425,
    app_flat_7: 41,
    char_8: 'Lingsha',
    app_rate_8: '8.06%',
    avg_round_8: 3412,
    app_flat_8: 41,
    char_9: 'trailblazer-harmony',
    app_rate_9: '6.02%',
    avg_round_9: 3268,
    app_flat_9: 32,
    char_10: 'Bronya',
    app_rate_10: '6.02%',
    avg_round_10: 3415,
    app_flat_10: 13,
    char_11: 'Sunday',
    app_rate_11: '3.61%',
    avg_round_11: 3354,
    app_flat_11: 11,
    char_12: 'Sparkle',
    app_rate_12: '2.29%',
    avg_round_12: 3283,
    app_flat_12: 8,
    char_13: 'Luocha',
    app_rate_13: '2.05%',
    avg_round_13: 3482,
    app_flat_13: 7,
    char_14: 'topaz',
    app_rate_14: '1.93%',
    avg_round_14: 3306,
    app_flat_14: 4,
    char_15: 'Firefly',
    app_rate_15: '1.56%',
    avg_round_15: 3265,
    app_flat_15: 6,
    char_16: 'Moze',
    app_rate_16: '1.32%',
    avg_round_16: 3308,
    app_flat_16: 6,
    char_17: 'Rappa',
    app_rate_17: '1.2%',
    avg_round_17: 3373,
    app_flat_17: 5,
    char_18: 'Yunli',
    app_rate_18: '0.84%',
    avg_round_18: 2897,
    app_flat_18: 1,
    char_19: 'jing-yuan',
    app_rate_19: '0.6%',
    avg_round_19: 3299,
    app_flat_19: 1,
    char_20: 'Acheron',
    app_rate_20: '0.6%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Bailu',
    app_rate_21: '0.48%',
    avg_round_21: 3401,
    app_flat_21: 3,
    char_22: 'Seele',
    app_rate_22: '0.48%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'imbibitor-lunae',
    app_rate_23: '0.48%',
    avg_round_23: 3289,
    app_flat_23: 1,
    char_24: 'Pela',
    app_rate_24: '0.48%',
    avg_round_24: 2935,
    app_flat_24: 1,
    char_25: 'Gepard',
    app_rate_25: '0.36%',
    avg_round_25: 3201,
    app_flat_25: 1,
    char_26: 'Lynx',
    app_rate_26: '0.36%',
    avg_round_26: 3355,
    app_flat_26: 1,
    char_27: 'Jade',
    app_rate_27: '0.36%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Himeko',
    app_rate_28: '0.24%',
    avg_round_28: 3077,
    app_flat_28: 1,
    char_29: 'Kafka',
    app_rate_29: '0.24%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Jiaoqiu',
    app_rate_30: '0.24%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'silver-wolf',
    app: 9.07,
    char_1: 'Acheron',
    app_rate_1: '75.83%',
    avg_round_1: 3365,
    app_flat_1: 322,
    char_2: 'Aventurine',
    app_rate_2: '44.64%',
    avg_round_2: 3375,
    app_flat_2: 213,
    char_3: 'Pela',
    app_rate_3: '44.27%',
    avg_round_3: 3378,
    app_flat_3: 252,
    char_4: 'fu-xuan',
    app_rate_4: '37.73%',
    avg_round_4: 3328,
    app_flat_4: 85,
    char_5: 'Sparkle',
    app_rate_5: '29.1%',
    avg_round_5: 3258,
    app_flat_5: 29,
    char_6: 'Jiaoqiu',
    app_rate_6: '10.23%',
    avg_round_6: 3353,
    app_flat_6: 50,
    char_7: 'Seele',
    app_rate_7: '9.12%',
    avg_round_7: 3168,
    app_flat_7: 7,
    char_8: 'Qingque',
    app_rate_8: '7.27%',
    avg_round_8: 3311,
    app_flat_8: 19,
    char_9: 'Gallagher',
    app_rate_9: '6.41%',
    avg_round_9: 3299,
    app_flat_9: 27,
    char_10: 'Sunday',
    app_rate_10: '3.7%',
    avg_round_10: 3312,
    app_flat_10: 4,
    char_11: 'ruan-mei',
    app_rate_11: '3.33%',
    avg_round_11: 3268,
    app_flat_11: 5,
    char_12: 'Huohuo',
    app_rate_12: '3.21%',
    avg_round_12: 3150,
    app_flat_12: 5,
    char_13: 'Lingsha',
    app_rate_13: '3.08%',
    avg_round_13: 3416,
    app_flat_13: 15,
    char_14: 'Robin',
    app_rate_14: '2.84%',
    avg_round_14: 3060,
    app_flat_14: 4,
    char_15: 'Bronya',
    app_rate_15: '2.34%',
    avg_round_15: 3246,
    app_flat_15: 2,
    char_16: 'Luocha',
    app_rate_16: '1.85%',
    avg_round_16: 3326,
    app_flat_16: 9,
    char_17: 'trailblazer-harmony',
    app_rate_17: '1.6%',
    avg_round_17: 3310,
    app_flat_17: 4,
    char_18: 'Boothill',
    app_rate_18: '1.6%',
    avg_round_18: 3339,
    app_flat_18: 3,
    char_19: 'Kafka',
    app_rate_19: '1.48%',
    avg_round_19: 3222,
    app_flat_19: 4,
    char_20: 'black-swan',
    app_rate_20: '1.36%',
    avg_round_20: 3325,
    app_flat_20: 5,
    char_21: 'Yunli',
    app_rate_21: '1.23%',
    avg_round_21: 3043,
    app_flat_21: 2,
    char_22: 'Feixiao',
    app_rate_22: '1.11%',
    avg_round_22: 3440,
    app_flat_22: 1,
    char_23: 'dr-ratio',
    app_rate_23: '0.99%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Firefly',
    app_rate_24: '0.74%',
    avg_round_24: 3318,
    app_flat_24: 1,
    char_25: 'Gepard',
    app_rate_25: '0.74%',
    avg_round_25: 3394,
    app_flat_25: 3,
    char_26: 'topaz',
    app_rate_26: '0.62%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Welt',
    app_rate_27: '0.62%',
    avg_round_27: 3179,
    app_flat_27: 1,
    char_28: 'Guinaifen',
    app_rate_28: '0.49%',
    avg_round_28: 3454,
    app_flat_28: 1,
    char_29: 'Lynx',
    app_rate_29: '0.49%',
    avg_round_29: 3278,
    app_flat_29: 2,
    char_30: 'Rappa',
    app_rate_30: '0.37%',
    avg_round_30: 3243,
    app_flat_30: 1
  },
  {
    char: 'Luocha',
    app: 7.95,
    char_1: 'Sunday',
    app_rate_1: '41.77%',
    avg_round_1: 3350,
    app_flat_1: 119,
    char_2: 'Acheron',
    app_rate_2: '31.79%',
    avg_round_2: 3363,
    app_flat_2: 92,
    char_3: 'jing-yuan',
    app_rate_3: '30.24%',
    avg_round_3: 3378,
    app_flat_3: 96,
    char_4: 'Robin',
    app_rate_4: '29.54%',
    avg_round_4: 3363,
    app_flat_4: 78,
    char_5: 'ruan-mei',
    app_rate_5: '19.27%',
    avg_round_5: 3335,
    app_flat_5: 54,
    char_6: 'Jiaoqiu',
    app_rate_6: '18.85%',
    avg_round_6: 3454,
    app_flat_6: 51,
    char_7: 'Pela',
    app_rate_7: '18.57%',
    avg_round_7: 3366,
    app_flat_7: 70,
    char_8: 'Sparkle',
    app_rate_8: '14.91%',
    avg_round_8: 3195,
    app_flat_8: 20,
    char_9: 'Kafka',
    app_rate_9: '10.97%',
    avg_round_9: 3222,
    app_flat_9: 22,
    char_10: 'black-swan',
    app_rate_10: '10.69%',
    avg_round_10: 3206,
    app_flat_10: 25,
    char_11: 'trailblazer-harmony',
    app_rate_11: '8.86%',
    avg_round_11: 3352,
    app_flat_11: 40,
    char_12: 'Bronya',
    app_rate_12: '8.02%',
    avg_round_12: 3297,
    app_flat_12: 20,
    char_13: 'Rappa',
    app_rate_13: '7.45%',
    avg_round_13: 3318,
    app_flat_13: 36,
    char_14: 'imbibitor-lunae',
    app_rate_14: '7.17%',
    avg_round_14: 3247,
    app_flat_14: 3,
    char_15: 'Jade',
    app_rate_15: '5.49%',
    avg_round_15: 3210,
    app_flat_15: 14,
    char_16: 'Feixiao',
    app_rate_16: '5.49%',
    avg_round_16: 3409,
    app_flat_16: 11,
    char_17: 'Tingyun',
    app_rate_17: '3.23%',
    avg_round_17: 3380,
    app_flat_17: 11,
    char_18: 'Blade',
    app_rate_18: '3.09%',
    avg_round_18: 3156,
    app_flat_18: 4,
    char_19: 'Firefly',
    app_rate_19: '2.81%',
    avg_round_19: 3320,
    app_flat_19: 11,
    char_20: 'Jingliu',
    app_rate_20: '2.67%',
    avg_round_20: 2946,
    app_flat_20: 4,
    char_21: 'Welt',
    app_rate_21: '2.53%',
    avg_round_21: 3128,
    app_flat_21: 6,
    char_22: 'march-7th-swordmaster',
    app_rate_22: '2.39%',
    avg_round_22: 3482,
    app_flat_22: 7,
    char_23: 'silver-wolf',
    app_rate_23: '2.11%',
    avg_round_23: 3326,
    app_flat_23: 9,
    char_24: 'Himeko',
    app_rate_24: '1.55%',
    avg_round_24: 3136,
    app_flat_24: 6,
    char_25: 'topaz',
    app_rate_25: '1.41%',
    avg_round_25: 3147,
    app_flat_25: 1,
    char_26: 'Yunli',
    app_rate_26: '1.41%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Herta',
    app_rate_27: '1.27%',
    avg_round_27: 3119,
    app_flat_27: 5,
    char_28: 'Guinaifen',
    app_rate_28: '1.13%',
    avg_round_28: 3350,
    app_flat_28: 7,
    char_29: 'Boothill',
    app_rate_29: '1.13%',
    avg_round_29: 3346,
    app_flat_29: 5,
    char_30: 'Serval',
    app_rate_30: '0.98%',
    avg_round_30: 2996,
    app_flat_30: 5
  },
  {
    char: 'Bronya',
    app: 6.48,
    char_1: 'Acheron',
    app_rate_1: '33.62%',
    avg_round_1: 3323,
    app_flat_1: 12,
    char_2: 'ruan-mei',
    app_rate_2: '27.07%',
    avg_round_2: 3450,
    app_flat_2: 47,
    char_3: 'Aventurine',
    app_rate_3: '26.03%',
    avg_round_3: 3357,
    app_flat_3: 43,
    char_4: 'Gallagher',
    app_rate_4: '24.31%',
    avg_round_4: 3431,
    app_flat_4: 68,
    char_5: 'Boothill',
    app_rate_5: '21.21%',
    avg_round_5: 3432,
    app_flat_5: 54,
    char_6: 'Jiaoqiu',
    app_rate_6: '15.34%',
    avg_round_6: 3409,
    app_flat_6: 4,
    char_7: 'Pela',
    app_rate_7: '14.83%',
    avg_round_7: 3221,
    app_flat_7: 10,
    char_8: 'Robin',
    app_rate_8: '14.48%',
    avg_round_8: 3412,
    app_flat_8: 20,
    char_9: 'Feixiao',
    app_rate_9: '14.48%',
    avg_round_9: 3484,
    app_flat_9: 29,
    char_10: 'Sunday',
    app_rate_10: '12.93%',
    avg_round_10: 3340,
    app_flat_10: 31,
    char_11: 'fu-xuan',
    app_rate_11: '10.86%',
    avg_round_11: 3296,
    app_flat_11: 6,
    char_12: 'trailblazer-harmony',
    app_rate_12: '10.52%',
    avg_round_12: 3379,
    app_flat_12: 17,
    char_13: 'Luocha',
    app_rate_13: '9.83%',
    avg_round_13: 3297,
    app_flat_13: 20,
    char_14: 'march-7th-swordmaster',
    app_rate_14: '8.62%',
    avg_round_14: 3415,
    app_flat_14: 13,
    char_15: 'jing-yuan',
    app_rate_15: '7.93%',
    avg_round_15: 3370,
    app_flat_15: 23,
    char_16: 'Firefly',
    app_rate_16: '7.07%',
    avg_round_16: 3367,
    app_flat_16: 4,
    char_17: 'Lingsha',
    app_rate_17: '5.69%',
    avg_round_17: 3284,
    app_flat_17: 12,
    char_18: 'Blade',
    app_rate_18: '4.14%',
    avg_round_18: 3160,
    app_flat_18: 5,
    char_19: 'Huohuo',
    app_rate_19: '3.79%',
    avg_round_19: 3313,
    app_flat_19: 6,
    char_20: 'Sparkle',
    app_rate_20: '3.79%',
    avg_round_20: 3025,
    app_flat_20: 4,
    char_21: 'Jade',
    app_rate_21: '3.28%',
    avg_round_21: 3225,
    app_flat_21: 7,
    char_22: 'silver-wolf',
    app_rate_22: '3.28%',
    avg_round_22: 3246,
    app_flat_22: 2,
    char_23: 'Jingliu',
    app_rate_23: '3.1%',
    avg_round_23: 3076,
    app_flat_23: 8,
    char_24: 'topaz',
    app_rate_24: '1.55%',
    avg_round_24: 3445,
    app_flat_24: 5,
    char_25: 'Serval',
    app_rate_25: '1.55%',
    avg_round_25: 3291,
    app_flat_25: 8,
    char_26: 'Rappa',
    app_rate_26: '1.21%',
    avg_round_26: 3404,
    app_flat_26: 6,
    char_27: 'Lynx',
    app_rate_27: '1.21%',
    avg_round_27: 3234,
    app_flat_27: 2,
    char_28: 'Kafka',
    app_rate_28: '0.86%',
    avg_round_28: 3246,
    app_flat_28: 1,
    char_29: 'black-swan',
    app_rate_29: '0.86%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Seele',
    app_rate_30: '0.86%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Jade',
    app: 5.36,
    char_1: 'Robin',
    app_rate_1: '60.75%',
    avg_round_1: 3325,
    app_flat_1: 84,
    char_2: 'Aventurine',
    app_rate_2: '29.65%',
    avg_round_2: 3246,
    app_flat_2: 38,
    char_3: 'Sunday',
    app_rate_3: '27.77%',
    avg_round_3: 3437,
    app_flat_3: 57,
    char_4: 'Feixiao',
    app_rate_4: '26.93%',
    avg_round_4: 3313,
    app_flat_4: 14,
    char_5: 'Lingsha',
    app_rate_5: '23.17%',
    avg_round_5: 3482,
    app_flat_5: 51,
    char_6: 'fu-xuan',
    app_rate_6: '13.36%',
    avg_round_6: 3194,
    app_flat_6: 13,
    char_7: 'Herta',
    app_rate_7: '11.9%',
    avg_round_7: 3149,
    app_flat_7: 28,
    char_8: 'Huohuo',
    app_rate_8: '10.86%',
    avg_round_8: 3208,
    app_flat_8: 20,
    char_9: 'Gallagher',
    app_rate_9: '9.81%',
    avg_round_9: 3301,
    app_flat_9: 15,
    char_10: 'jing-yuan',
    app_rate_10: '9.6%',
    avg_round_10: 3398,
    app_flat_10: 16,
    char_11: 'Luocha',
    app_rate_11: '8.14%',
    avg_round_11: 3210,
    app_flat_11: 14,
    char_12: 'ruan-mei',
    app_rate_12: '7.31%',
    avg_round_12: 3396,
    app_flat_12: 11,
    char_13: 'Blade',
    app_rate_13: '7.31%',
    avg_round_13: 3206,
    app_flat_13: 10,
    char_14: 'Sparkle',
    app_rate_14: '6.47%',
    avg_round_14: 3267,
    app_flat_14: 8,
    char_15: 'topaz',
    app_rate_15: '6.26%',
    avg_round_15: 3265,
    app_flat_15: 1,
    char_16: 'Acheron',
    app_rate_16: '4.59%',
    avg_round_16: 3318,
    app_flat_16: 6,
    char_17: 'trailblazer-harmony',
    app_rate_17: '4.38%',
    avg_round_17: 3382,
    app_flat_17: 10,
    char_18: 'Bronya',
    app_rate_18: '3.97%',
    avg_round_18: 3225,
    app_flat_18: 7,
    char_19: 'Rappa',
    app_rate_19: '3.97%',
    avg_round_19: 3278,
    app_flat_19: 12,
    char_20: 'Himeko',
    app_rate_20: '3.76%',
    avg_round_20: 3222,
    app_flat_20: 13,
    char_21: 'Pela',
    app_rate_21: '3.34%',
    avg_round_21: 3380,
    app_flat_21: 7,
    char_22: 'Jiaoqiu',
    app_rate_22: '2.51%',
    avg_round_22: 3335,
    app_flat_22: 2,
    char_23: 'Argenti',
    app_rate_23: '2.51%',
    avg_round_23: 3183,
    app_flat_23: 5,
    char_24: 'Yunli',
    app_rate_24: '1.88%',
    avg_round_24: 3110,
    app_flat_24: 8,
    char_25: 'Tingyun',
    app_rate_25: '1.46%',
    avg_round_25: 3131,
    app_flat_25: 4,
    char_26: 'Serval',
    app_rate_26: '1.46%',
    avg_round_26: 3229,
    app_flat_26: 4,
    char_27: 'Seele',
    app_rate_27: '1.25%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'imbibitor-lunae',
    app_rate_28: '1.04%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Jingliu',
    app_rate_29: '0.63%',
    avg_round_29: 3087,
    app_flat_29: 1,
    char_30: 'Clara',
    app_rate_30: '0.63%',
    avg_round_30: 3054,
    app_flat_30: 1
  },
  {
    char: 'Tingyun',
    app: 3.82,
    char_1: 'Sunday',
    app_rate_1: '59.94%',
    avg_round_1: 3321,
    app_flat_1: 98,
    char_2: 'jing-yuan',
    app_rate_2: '54.39%',
    avg_round_2: 3326,
    app_flat_2: 85,
    char_3: 'Aventurine',
    app_rate_3: '36.84%',
    avg_round_3: 3270,
    app_flat_3: 53,
    char_4: 'Sparkle',
    app_rate_4: '25.44%',
    avg_round_4: 3151,
    app_flat_4: 29,
    char_5: 'Huohuo',
    app_rate_5: '19.88%',
    avg_round_5: 3222,
    app_flat_5: 31,
    char_6: 'fu-xuan',
    app_rate_6: '17.25%',
    avg_round_6: 3248,
    app_flat_6: 20,
    char_7: 'imbibitor-lunae',
    app_rate_7: '11.7%',
    avg_round_7: 3102,
    app_flat_7: 8,
    char_8: 'Gallagher',
    app_rate_8: '7.89%',
    avg_round_8: 3385,
    app_flat_8: 15,
    char_9: 'Seele',
    app_rate_9: '7.6%',
    avg_round_9: 3273,
    app_flat_9: 9,
    char_10: 'Lingsha',
    app_rate_10: '7.02%',
    avg_round_10: 3224,
    app_flat_10: 11,
    char_11: 'Luocha',
    app_rate_11: '6.73%',
    avg_round_11: 3380,
    app_flat_11: 11,
    char_12: 'Yunli',
    app_rate_12: '5.56%',
    avg_round_12: 3063,
    app_flat_12: 5,
    char_13: 'Robin',
    app_rate_13: '5.56%',
    avg_round_13: 3099,
    app_flat_13: 5,
    char_14: 'Argenti',
    app_rate_14: '4.39%',
    avg_round_14: 3200,
    app_flat_14: 9,
    char_15: 'Rappa',
    app_rate_15: '3.51%',
    avg_round_15: 3250,
    app_flat_15: 8,
    char_16: 'Qingque',
    app_rate_16: '3.22%',
    avg_round_16: 3158,
    app_flat_16: 4,
    char_17: 'Serval',
    app_rate_17: '2.63%',
    avg_round_17: 3255,
    app_flat_17: 7,
    char_18: 'Jade',
    app_rate_18: '2.05%',
    avg_round_18: 3131,
    app_flat_18: 4,
    char_19: 'Kafka',
    app_rate_19: '1.75%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'trailblazer-harmony',
    app_rate_20: '1.75%',
    avg_round_20: 3374,
    app_flat_20: 4,
    char_21: 'ruan-mei',
    app_rate_21: '1.46%',
    avg_round_21: 3181,
    app_flat_21: 1,
    char_22: 'Pela',
    app_rate_22: '1.46%',
    avg_round_22: 3200,
    app_flat_22: 2,
    char_23: 'Bronya',
    app_rate_23: '1.46%',
    avg_round_23: 3189,
    app_flat_23: 3,
    char_24: 'Bailu',
    app_rate_24: '1.17%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Feixiao',
    app_rate_25: '0.88%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Jiaoqiu',
    app_rate_26: '0.88%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Welt',
    app_rate_27: '0.88%',
    avg_round_27: 3220,
    app_flat_27: 2,
    char_28: 'Lynx',
    app_rate_28: '0.88%',
    avg_round_28: 3271,
    app_flat_28: 2,
    char_29: 'black-swan',
    app_rate_29: '0.88%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Clara',
    app_rate_30: '0.58%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Boothill',
    app: 3.24,
    char_1: 'ruan-mei',
    app_rate_1: '91.03%',
    avg_round_1: 3467,
    app_flat_1: 126,
    char_2: 'Gallagher',
    app_rate_2: '64.48%',
    avg_round_2: 3467,
    app_flat_2: 106,
    char_3: 'Bronya',
    app_rate_3: '42.41%',
    avg_round_3: 3432,
    app_flat_3: 54,
    char_4: 'trailblazer-harmony',
    app_rate_4: '37.93%',
    avg_round_4: 3470,
    app_flat_4: 65,
    char_5: 'Sunday',
    app_rate_5: '28.28%',
    avg_round_5: 3450,
    app_flat_5: 35,
    char_6: 'Lingsha',
    app_rate_6: '10.0%',
    avg_round_6: 3542,
    app_flat_6: 15,
    char_7: 'Aventurine',
    app_rate_7: '5.86%',
    avg_round_7: 3353,
    app_flat_7: 9,
    char_8: 'silver-wolf',
    app_rate_8: '4.48%',
    avg_round_8: 3339,
    app_flat_8: 3,
    char_9: 'fu-xuan',
    app_rate_9: '3.45%',
    avg_round_9: 3324,
    app_flat_9: 5,
    char_10: 'Huohuo',
    app_rate_10: '3.45%',
    avg_round_10: 3460,
    app_flat_10: 2,
    char_11: 'Luocha',
    app_rate_11: '2.76%',
    avg_round_11: 3346,
    app_flat_11: 5,
    char_12: 'Firefly',
    app_rate_12: '1.72%',
    avg_round_12: 3302,
    app_flat_12: 3,
    char_13: 'Jiaoqiu',
    app_rate_13: '0.69%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Lynx',
    app_rate_14: '0.69%',
    avg_round_14: 3390,
    app_flat_14: 1,
    char_15: 'Luka',
    app_rate_15: '0.34%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Bailu',
    app_rate_16: '0.34%',
    avg_round_16: 3344,
    app_flat_16: 1,
    char_17: 'Feixiao',
    app_rate_17: '0.34%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Acheron',
    app_rate_18: '0.34%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Robin',
    app_rate_19: '0.34%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Tingyun',
    app_rate_20: '0.34%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'march-7th-swordmaster',
    app_rate_21: '0.34%',
    avg_round_21: 3272,
    app_flat_21: 1,
    char_22: 'Rappa',
    app_rate_22: '0.34%',
    avg_round_22: 3397,
    app_flat_22: 1,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'imbibitor-lunae',
    app: 2.55,
    char_1: 'Sparkle',
    app_rate_1: '68.86%',
    avg_round_1: 3215,
    app_flat_1: 34,
    char_2: 'Sunday',
    app_rate_2: '56.14%',
    avg_round_2: 3285,
    app_flat_2: 24,
    char_3: 'Aventurine',
    app_rate_3: '49.56%',
    avg_round_3: 3227,
    app_flat_3: 34,
    char_4: 'Luocha',
    app_rate_4: '22.37%',
    avg_round_4: 3247,
    app_flat_4: 3,
    char_5: 'ruan-mei',
    app_rate_5: '19.3%',
    avg_round_5: 3069,
    app_flat_5: 1,
    char_6: 'Tingyun',
    app_rate_6: '17.54%',
    avg_round_6: 3102,
    app_flat_6: 8,
    char_7: 'Robin',
    app_rate_7: '15.79%',
    avg_round_7: 3222,
    app_flat_7: 12,
    char_8: 'Huohuo',
    app_rate_8: '14.04%',
    avg_round_8: 3175,
    app_flat_8: 1,
    char_9: 'Gallagher',
    app_rate_9: '10.09%',
    avg_round_9: 3145,
    app_flat_9: 1,
    char_10: 'Pela',
    app_rate_10: '5.26%',
    avg_round_10: 3149,
    app_flat_10: 3,
    char_11: 'Jiaoqiu',
    app_rate_11: '4.82%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Lingsha',
    app_rate_12: '2.63%',
    avg_round_12: 3169,
    app_flat_12: 3,
    char_13: 'Jade',
    app_rate_13: '2.19%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'fu-xuan',
    app_rate_14: '1.75%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '1.75%',
    avg_round_15: 3289,
    app_flat_15: 1,
    char_16: 'Yunli',
    app_rate_16: '1.32%',
    avg_round_16: 3117,
    app_flat_16: 1,
    char_17: 'silver-wolf',
    app_rate_17: '1.32%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'trailblazer-harmony',
    app_rate_18: '0.88%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Bronya',
    app_rate_19: '0.88%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Welt',
    app_rate_20: '0.44%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Clara',
    app_rate_21: '0.44%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Himeko',
    app_rate_22: '0.44%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Herta',
    app_rate_23: '0.44%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'dr-ratio',
    app_rate_24: '0.44%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Bailu',
    app_rate_25: '0.44%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Yukong',
    app_rate_26: '0.44%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Lynx',
    app_rate_27: '0.44%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Seele',
    app: 2.14,
    char_1: 'Sparkle',
    app_rate_1: '74.87%',
    avg_round_1: 3187,
    app_flat_1: 15,
    char_2: 'fu-xuan',
    app_rate_2: '74.87%',
    avg_round_2: 3218,
    app_flat_2: 17,
    char_3: 'silver-wolf',
    app_rate_3: '38.74%',
    avg_round_3: 3168,
    app_flat_3: 7,
    char_4: 'Sunday',
    app_rate_4: '28.27%',
    avg_round_4: 3278,
    app_flat_4: 16,
    char_5: 'Robin',
    app_rate_5: '26.7%',
    avg_round_5: 3254,
    app_flat_5: 8,
    char_6: 'Huohuo',
    app_rate_6: '14.14%',
    avg_round_6: 3259,
    app_flat_6: 7,
    char_7: 'Tingyun',
    app_rate_7: '13.61%',
    avg_round_7: 3273,
    app_flat_7: 9,
    char_8: 'ruan-mei',
    app_rate_8: '12.04%',
    avg_round_8: 3211,
    app_flat_8: 7,
    char_9: 'Gallagher',
    app_rate_9: '5.24%',
    avg_round_9: 3222,
    app_flat_9: 4,
    char_10: 'Jade',
    app_rate_10: '3.14%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Bronya',
    app_rate_11: '2.62%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Aventurine',
    app_rate_12: '2.09%',
    avg_round_12: 3217,
    app_flat_12: 1,
    char_13: 'march-7th-swordmaster',
    app_rate_13: '2.09%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Lynx',
    app_rate_14: '1.05%',
    avg_round_14: 3196,
    app_flat_14: 1,
    char_15: 'Lingsha',
    app_rate_15: '0.52%',
    avg_round_15: 3331,
    app_flat_15: 1,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Guinaifen',
    app: 2.12,
    char_1: 'Acheron',
    app_rate_1: '91.58%',
    avg_round_1: 3373,
    app_flat_1: 121,
    char_2: 'Pela',
    app_rate_2: '68.42%',
    avg_round_2: 3380,
    app_flat_2: 100,
    char_3: 'Aventurine',
    app_rate_3: '39.47%',
    avg_round_3: 3395,
    app_flat_3: 53,
    char_4: 'fu-xuan',
    app_rate_4: '22.11%',
    avg_round_4: 3333,
    app_flat_4: 23,
    char_5: 'Gallagher',
    app_rate_5: '17.89%',
    avg_round_5: 3375,
    app_flat_5: 25,
    char_6: 'Kafka',
    app_rate_6: '11.58%',
    avg_round_6: 3185,
    app_flat_6: 6,
    char_7: 'Lingsha',
    app_rate_7: '8.95%',
    avg_round_7: 3457,
    app_flat_7: 11,
    char_8: 'black-swan',
    app_rate_8: '7.37%',
    avg_round_8: 3236,
    app_flat_8: 2,
    char_9: 'Jiaoqiu',
    app_rate_9: '6.84%',
    avg_round_9: 3390,
    app_flat_9: 8,
    char_10: 'Huohuo',
    app_rate_10: '4.74%',
    avg_round_10: 3121,
    app_flat_10: 1,
    char_11: 'Luocha',
    app_rate_11: '4.21%',
    avg_round_11: 3350,
    app_flat_11: 7,
    char_12: 'Sunday',
    app_rate_12: '3.68%',
    avg_round_12: 3336,
    app_flat_12: 4,
    char_13: 'Gepard',
    app_rate_13: '2.11%',
    avg_round_13: 3304,
    app_flat_13: 2,
    char_14: 'silver-wolf',
    app_rate_14: '2.11%',
    avg_round_14: 3454,
    app_flat_14: 1,
    char_15: 'Bronya',
    app_rate_15: '1.58%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Sparkle',
    app_rate_16: '1.58%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'ruan-mei',
    app_rate_17: '1.05%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Robin',
    app_rate_18: '1.05%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Firefly',
    app_rate_19: '1.05%',
    avg_round_19: 3074,
    app_flat_19: 1,
    char_20: 'trailblazer-harmony',
    app_rate_20: '1.05%',
    avg_round_20: 3074,
    app_flat_20: 1,
    char_21: 'Welt',
    app_rate_21: '1.05%',
    avg_round_21: 3250,
    app_flat_21: 2,
    char_22: 'Bailu',
    app_rate_22: '0.53%',
    avg_round_22: 3224,
    app_flat_22: 1,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Moze',
    app: 1.88,
    char_1: 'Feixiao',
    app_rate_1: '85.12%',
    avg_round_1: 3433,
    app_flat_1: 73,
    char_2: 'Aventurine',
    app_rate_2: '82.74%',
    avg_round_2: 3435,
    app_flat_2: 69,
    char_3: 'Robin',
    app_rate_3: '77.38%',
    avg_round_3: 3442,
    app_flat_3: 65,
    char_4: 'topaz',
    app_rate_4: '11.31%',
    avg_round_4: 3345,
    app_flat_4: 5,
    char_5: 'march-7th-swordmaster',
    app_rate_5: '6.55%',
    avg_round_5: 3308,
    app_flat_5: 6,
    char_6: 'fu-xuan',
    app_rate_6: '5.95%',
    avg_round_6: 3331,
    app_flat_6: 4,
    char_7: 'Gallagher',
    app_rate_7: '5.36%',
    avg_round_7: 3475,
    app_flat_7: 2,
    char_8: 'Sunday',
    app_rate_8: '4.76%',
    avg_round_8: 3410,
    app_flat_8: 1,
    char_9: 'ruan-mei',
    app_rate_9: '4.76%',
    avg_round_9: 3317,
    app_flat_9: 2,
    char_10: 'jing-yuan',
    app_rate_10: '2.98%',
    avg_round_10: 3410,
    app_flat_10: 1,
    char_11: 'dr-ratio',
    app_rate_11: '2.98%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Lingsha',
    app_rate_12: '2.38%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Jiaoqiu',
    app_rate_13: '2.38%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Luocha',
    app_rate_14: '2.38%',
    avg_round_14: 3224,
    app_flat_14: 1,
    char_15: 'Bailu',
    app_rate_15: '0.6%',
    avg_round_15: 3389,
    app_flat_15: 1,
    char_16: 'Sparkle',
    app_rate_16: '0.6%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Huohuo',
    app_rate_17: '0.6%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Yunli',
    app_rate_18: '0.6%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'silver-wolf',
    app_rate_19: '0.6%',
    avg_round_19: 3440,
    app_flat_19: 1,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Welt',
    app: 1.73,
    char_1: 'Acheron',
    app_rate_1: '87.1%',
    avg_round_1: 3308,
    app_flat_1: 77,
    char_2: 'Pela',
    app_rate_2: '60.65%',
    avg_round_2: 3312,
    app_flat_2: 59,
    char_3: 'Aventurine',
    app_rate_3: '41.29%',
    avg_round_3: 3354,
    app_flat_3: 40,
    char_4: 'fu-xuan',
    app_rate_4: '19.35%',
    avg_round_4: 3228,
    app_flat_4: 18,
    char_5: 'Gallagher',
    app_rate_5: '11.61%',
    avg_round_5: 3264,
    app_flat_5: 10,
    char_6: 'Luocha',
    app_rate_6: '11.61%',
    avg_round_6: 3128,
    app_flat_6: 6,
    char_7: 'Sparkle',
    app_rate_7: '8.39%',
    avg_round_7: 3189,
    app_flat_7: 4,
    char_8: 'Sunday',
    app_rate_8: '7.74%',
    avg_round_8: 3150,
    app_flat_8: 8,
    char_9: 'Robin',
    app_rate_9: '7.74%',
    avg_round_9: 3192,
    app_flat_9: 2,
    char_10: 'Lingsha',
    app_rate_10: '7.1%',
    avg_round_10: 3311,
    app_flat_10: 6,
    char_11: 'Jiaoqiu',
    app_rate_11: '7.1%',
    avg_round_11: 3378,
    app_flat_11: 7,
    char_12: 'Gepard',
    app_rate_12: '4.52%',
    avg_round_12: 3314,
    app_flat_12: 4,
    char_13: 'ruan-mei',
    app_rate_13: '3.23%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'silver-wolf',
    app_rate_14: '3.23%',
    avg_round_14: 3179,
    app_flat_14: 1,
    char_15: 'Huohuo',
    app_rate_15: '2.58%',
    avg_round_15: 3478,
    app_flat_15: 1,
    char_16: 'Kafka',
    app_rate_16: '2.58%',
    avg_round_16: 3250,
    app_flat_16: 3,
    char_17: 'Rappa',
    app_rate_17: '1.94%',
    avg_round_17: 3344,
    app_flat_17: 2,
    char_18: 'Tingyun',
    app_rate_18: '1.94%',
    avg_round_18: 3220,
    app_flat_18: 2,
    char_19: 'black-swan',
    app_rate_19: '1.94%',
    avg_round_19: 3161,
    app_flat_19: 2,
    char_20: 'trailblazer-harmony',
    app_rate_20: '1.29%',
    avg_round_20: 3516,
    app_flat_20: 1,
    char_21: 'Guinaifen',
    app_rate_21: '1.29%',
    avg_round_21: 3250,
    app_flat_21: 2,
    char_22: 'Feixiao',
    app_rate_22: '0.65%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'imbibitor-lunae',
    app_rate_23: '0.65%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Argenti',
    app_rate_24: '0.65%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Yunli',
    app_rate_25: '0.65%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'dr-ratio',
    app_rate_26: '0.65%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Serval',
    app_rate_27: '0.65%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Bailu',
    app_rate_28: '0.65%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'march-7th-swordmaster',
    app_rate_29: '0.65%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Bronya',
    app_rate_30: '0.65%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Himeko',
    app: 1.62,
    char_1: 'Robin',
    app_rate_1: '33.1%',
    avg_round_1: 3135,
    app_flat_1: 25,
    char_2: 'trailblazer-harmony',
    app_rate_2: '30.34%',
    avg_round_2: 3278,
    app_flat_2: 25,
    char_3: 'Gallagher',
    app_rate_3: '26.9%',
    avg_round_3: 3255,
    app_flat_3: 25,
    char_4: 'Lingsha',
    app_rate_4: '25.52%',
    avg_round_4: 3263,
    app_flat_4: 17,
    char_5: 'Aventurine',
    app_rate_5: '23.45%',
    avg_round_5: 3147,
    app_flat_5: 19,
    char_6: 'Acheron',
    app_rate_6: '22.76%',
    avg_round_6: 3239,
    app_flat_6: 13,
    char_7: 'Rappa',
    app_rate_7: '20.0%',
    avg_round_7: 3269,
    app_flat_7: 24,
    char_8: 'Pela',
    app_rate_8: '17.24%',
    avg_round_8: 3217,
    app_flat_8: 14,
    char_9: 'ruan-mei',
    app_rate_9: '13.1%',
    avg_round_9: 3221,
    app_flat_9: 5,
    char_10: 'Jade',
    app_rate_10: '12.41%',
    avg_round_10: 3222,
    app_flat_10: 13,
    char_11: 'Jiaoqiu',
    app_rate_11: '10.34%',
    avg_round_11: 3216,
    app_flat_11: 2,
    char_12: 'Sunday',
    app_rate_12: '8.28%',
    avg_round_12: 3180,
    app_flat_12: 7,
    char_13: 'Luocha',
    app_rate_13: '7.59%',
    avg_round_13: 3136,
    app_flat_13: 6,
    char_14: 'fu-xuan',
    app_rate_14: '6.21%',
    avg_round_14: 3209,
    app_flat_14: 2,
    char_15: 'Firefly',
    app_rate_15: '5.52%',
    avg_round_15: 3245,
    app_flat_15: 3,
    char_16: 'Huohuo',
    app_rate_16: '4.83%',
    avg_round_16: 3105,
    app_flat_16: 5,
    char_17: 'Herta',
    app_rate_17: '4.14%',
    avg_round_17: 2964,
    app_flat_17: 4,
    char_18: 'Sparkle',
    app_rate_18: '4.14%',
    avg_round_18: 3153,
    app_flat_18: 3,
    char_19: 'Feixiao',
    app_rate_19: '3.45%',
    avg_round_19: 3100,
    app_flat_19: 3,
    char_20: 'Kafka',
    app_rate_20: '3.45%',
    avg_round_20: 3180,
    app_flat_20: 2,
    char_21: 'topaz',
    app_rate_21: '3.45%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Yunli',
    app_rate_22: '2.76%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Asta',
    app_rate_23: '2.07%',
    avg_round_23: 3126,
    app_flat_23: 1,
    char_24: 'march-7th-swordmaster',
    app_rate_24: '1.38%',
    avg_round_24: 3077,
    app_flat_24: 1,
    char_25: 'jing-yuan',
    app_rate_25: '1.38%',
    avg_round_25: 3066,
    app_flat_25: 2,
    char_26: 'Clara',
    app_rate_26: '1.38%',
    avg_round_26: 3119,
    app_flat_26: 1,
    char_27: 'Natasha',
    app_rate_27: '0.69%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'imbibitor-lunae',
    app_rate_28: '0.69%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'silver-wolf',
    app_rate_29: '0.69%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Jingliu',
    app_rate_30: '0.69%',
    avg_round_30: 2908,
    app_flat_30: 1
  },
  {
    char: 'Yunli',
    app: 1.58,
    char_1: 'Robin',
    app_rate_1: '61.7%',
    avg_round_1: 3148,
    app_flat_1: 25,
    char_2: 'Huohuo',
    app_rate_2: '36.17%',
    avg_round_2: 3089,
    app_flat_2: 15,
    char_3: 'Aventurine',
    app_rate_3: '24.82%',
    avg_round_3: 3156,
    app_flat_3: 13,
    char_4: 'Sparkle',
    app_rate_4: '17.02%',
    avg_round_4: 3007,
    app_flat_4: 6,
    char_5: 'Sunday',
    app_rate_5: '16.31%',
    avg_round_5: 3029,
    app_flat_5: 7,
    char_6: 'fu-xuan',
    app_rate_6: '16.31%',
    avg_round_6: 3191,
    app_flat_6: 9,
    char_7: 'Feixiao',
    app_rate_7: '15.6%',
    avg_round_7: 3319,
    app_flat_7: 7,
    char_8: 'Acheron',
    app_rate_8: '14.18%',
    avg_round_8: 3207,
    app_flat_8: 12,
    char_9: 'Tingyun',
    app_rate_9: '13.48%',
    avg_round_9: 3063,
    app_flat_9: 5,
    char_10: 'Pela',
    app_rate_10: '12.77%',
    avg_round_10: 3254,
    app_flat_10: 9,
    char_11: 'Gallagher',
    app_rate_11: '7.8%',
    avg_round_11: 3008,
    app_flat_11: 4,
    char_12: 'Rappa',
    app_rate_12: '7.09%',
    avg_round_12: 3120,
    app_flat_12: 5,
    char_13: 'Luocha',
    app_rate_13: '7.09%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'silver-wolf',
    app_rate_14: '7.09%',
    avg_round_14: 3043,
    app_flat_14: 2,
    char_15: 'Jade',
    app_rate_15: '6.38%',
    avg_round_15: 3110,
    app_flat_15: 8,
    char_16: 'topaz',
    app_rate_16: '5.67%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Lingsha',
    app_rate_17: '4.96%',
    avg_round_17: 3181,
    app_flat_17: 4,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '4.96%',
    avg_round_18: 2897,
    app_flat_18: 1,
    char_19: 'Herta',
    app_rate_19: '2.84%',
    avg_round_19: 3158,
    app_flat_19: 1,
    char_20: 'ruan-mei',
    app_rate_20: '2.84%',
    avg_round_20: 2825,
    app_flat_20: 1,
    char_21: 'Himeko',
    app_rate_21: '2.84%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'imbibitor-lunae',
    app_rate_22: '2.13%',
    avg_round_22: 3117,
    app_flat_22: 1,
    char_23: 'Kafka',
    app_rate_23: '1.42%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Clara',
    app_rate_24: '1.42%',
    avg_round_24: 3099,
    app_flat_24: 1,
    char_25: 'jing-yuan',
    app_rate_25: '1.42%',
    avg_round_25: 2937,
    app_flat_25: 1,
    char_26: 'Jingliu',
    app_rate_26: '0.71%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Blade',
    app_rate_27: '0.71%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Welt',
    app_rate_28: '0.71%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'trailblazer-harmony',
    app_rate_29: '0.71%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Bronya',
    app_rate_30: '0.71%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Qingque',
    app: 1.45,
    char_1: 'Sparkle',
    app_rate_1: '96.15%',
    avg_round_1: 3262,
    app_flat_1: 39,
    char_2: 'fu-xuan',
    app_rate_2: '70.77%',
    avg_round_2: 3273,
    app_flat_2: 24,
    char_3: 'silver-wolf',
    app_rate_3: '45.38%',
    avg_round_3: 3311,
    app_flat_3: 19,
    char_4: 'Sunday',
    app_rate_4: '23.08%',
    avg_round_4: 3324,
    app_flat_4: 10,
    char_5: 'Robin',
    app_rate_5: '11.54%',
    avg_round_5: 3119,
    app_flat_5: 6,
    char_6: 'ruan-mei',
    app_rate_6: '10.77%',
    avg_round_6: 3271,
    app_flat_6: 3,
    char_7: 'Gallagher',
    app_rate_7: '10.0%',
    avg_round_7: 3334,
    app_flat_7: 7,
    char_8: 'Huohuo',
    app_rate_8: '8.46%',
    avg_round_8: 3177,
    app_flat_8: 3,
    char_9: 'Tingyun',
    app_rate_9: '8.46%',
    avg_round_9: 3158,
    app_flat_9: 4,
    char_10: 'Aventurine',
    app_rate_10: '6.15%',
    avg_round_10: 3182,
    app_flat_10: 4,
    char_11: 'Lingsha',
    app_rate_11: '3.08%',
    avg_round_11: 3249,
    app_flat_11: 3,
    char_12: 'Xueyi',
    app_rate_12: '1.54%',
    avg_round_12: 3045,
    app_flat_12: 1,
    char_13: 'Jade',
    app_rate_13: '1.54%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Bronya',
    app_rate_14: '1.54%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Lynx',
    app_rate_15: '0.77%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Feixiao',
    app_rate_16: '0.77%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Herta',
    app: 1.23,
    char_1: 'Robin',
    app_rate_1: '66.36%',
    avg_round_1: 3157,
    app_flat_1: 35,
    char_2: 'Jade',
    app_rate_2: '51.82%',
    avg_round_2: 3149,
    app_flat_2: 28,
    char_3: 'Aventurine',
    app_rate_3: '40.91%',
    avg_round_3: 3159,
    app_flat_3: 24,
    char_4: 'Sunday',
    app_rate_4: '20.91%',
    avg_round_4: 3185,
    app_flat_4: 10,
    char_5: 'fu-xuan',
    app_rate_5: '17.27%',
    avg_round_5: 3054,
    app_flat_5: 5,
    char_6: 'Lingsha',
    app_rate_6: '13.64%',
    avg_round_6: 3155,
    app_flat_6: 5,
    char_7: 'Sparkle',
    app_rate_7: '11.82%',
    avg_round_7: 2955,
    app_flat_7: 2,
    char_8: 'Gallagher',
    app_rate_8: '9.09%',
    avg_round_8: 3075,
    app_flat_8: 6,
    char_9: 'Luocha',
    app_rate_9: '8.18%',
    avg_round_9: 3119,
    app_flat_9: 5,
    char_10: 'Rappa',
    app_rate_10: '7.27%',
    avg_round_10: 3094,
    app_flat_10: 5,
    char_11: 'Pela',
    app_rate_11: '7.27%',
    avg_round_11: 3052,
    app_flat_11: 1,
    char_12: 'Huohuo',
    app_rate_12: '5.45%',
    avg_round_12: 3196,
    app_flat_12: 2,
    char_13: 'Himeko',
    app_rate_13: '5.45%',
    avg_round_13: 2964,
    app_flat_13: 4,
    char_14: 'Acheron',
    app_rate_14: '5.45%',
    avg_round_14: 3052,
    app_flat_14: 1,
    char_15: 'Bronya',
    app_rate_15: '4.55%',
    avg_round_15: 3114,
    app_flat_15: 1,
    char_16: 'ruan-mei',
    app_rate_16: '4.55%',
    avg_round_16: 3184,
    app_flat_16: 4,
    char_17: 'Yunli',
    app_rate_17: '3.64%',
    avg_round_17: 3158,
    app_flat_17: 1,
    char_18: 'jing-yuan',
    app_rate_18: '3.64%',
    avg_round_18: 3039,
    app_flat_18: 1,
    char_19: 'trailblazer-harmony',
    app_rate_19: '3.64%',
    avg_round_19: 3077,
    app_flat_19: 4,
    char_20: 'Feixiao',
    app_rate_20: '2.73%',
    avg_round_20: 3097,
    app_flat_20: 1,
    char_21: 'Bailu',
    app_rate_21: '2.73%',
    avg_round_21: 3083,
    app_flat_21: 1,
    char_22: 'Jiaoqiu',
    app_rate_22: '1.82%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'imbibitor-lunae',
    app_rate_23: '0.91%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Lynx',
    app_rate_24: '0.91%',
    avg_round_24: 3114,
    app_flat_24: 1,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Jingliu',
    app: 0.81,
    char_1: 'Sunday',
    app_rate_1: '51.39%',
    avg_round_1: 3182,
    app_flat_1: 14,
    char_2: 'Robin',
    app_rate_2: '47.22%',
    avg_round_2: 3136,
    app_flat_2: 13,
    char_3: 'Aventurine',
    app_rate_3: '37.5%',
    avg_round_3: 3114,
    app_flat_3: 15,
    char_4: 'Luocha',
    app_rate_4: '26.39%',
    avg_round_4: 2946,
    app_flat_4: 4,
    char_5: 'Bronya',
    app_rate_5: '25.0%',
    avg_round_5: 3076,
    app_flat_5: 8,
    char_6: 'Pela',
    app_rate_6: '22.22%',
    avg_round_6: 3094,
    app_flat_6: 12,
    char_7: 'ruan-mei',
    app_rate_7: '20.83%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Lingsha',
    app_rate_8: '13.89%',
    avg_round_8: 3114,
    app_flat_8: 5,
    char_9: 'Gallagher',
    app_rate_9: '11.11%',
    avg_round_9: 3101,
    app_flat_9: 3,
    char_10: 'Sparkle',
    app_rate_10: '9.72%',
    avg_round_10: 2924,
    app_flat_10: 4,
    char_11: 'Huohuo',
    app_rate_11: '8.33%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Blade',
    app_rate_12: '5.56%',
    avg_round_12: 3047,
    app_flat_12: 1,
    char_13: 'Rappa',
    app_rate_13: '4.17%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Jade',
    app_rate_14: '4.17%',
    avg_round_14: 3087,
    app_flat_14: 1,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '2.78%',
    avg_round_15: 3074,
    app_flat_15: 1,
    char_16: 'Yunli',
    app_rate_16: '1.39%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Himeko',
    app_rate_17: '1.39%',
    avg_round_17: 2908,
    app_flat_17: 1,
    char_18: 'Acheron',
    app_rate_18: '1.39%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'fu-xuan',
    app_rate_19: '1.39%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'jing-yuan',
    app_rate_20: '1.39%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Bailu',
    app_rate_21: '1.39%',
    avg_round_21: 3100,
    app_flat_21: 1,
    char_22: 'Serval',
    app_rate_22: '1.39%',
    avg_round_22: 2963,
    app_flat_22: 1,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Gepard',
    app: 0.79,
    char_1: 'Acheron',
    app_rate_1: '85.92%',
    avg_round_1: 3382,
    app_flat_1: 26,
    char_2: 'Pela',
    app_rate_2: '49.3%',
    avg_round_2: 3402,
    app_flat_2: 21,
    char_3: 'Jiaoqiu',
    app_rate_3: '32.39%',
    avg_round_3: 3558,
    app_flat_3: 8,
    char_4: 'Kafka',
    app_rate_4: '19.72%',
    avg_round_4: 3322,
    app_flat_4: 7,
    char_5: 'black-swan',
    app_rate_5: '16.9%',
    avg_round_5: 3280,
    app_flat_5: 6,
    char_6: 'Robin',
    app_rate_6: '14.08%',
    avg_round_6: 3363,
    app_flat_6: 3,
    char_7: 'Sunday',
    app_rate_7: '9.86%',
    avg_round_7: 3454,
    app_flat_7: 3,
    char_8: 'Welt',
    app_rate_8: '9.86%',
    avg_round_8: 3314,
    app_flat_8: 4,
    char_9: 'Sparkle',
    app_rate_9: '8.45%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'silver-wolf',
    app_rate_10: '8.45%',
    avg_round_10: 3394,
    app_flat_10: 3,
    char_11: 'Bronya',
    app_rate_11: '7.04%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'jing-yuan',
    app_rate_12: '5.63%',
    avg_round_12: 3444,
    app_flat_12: 2,
    char_13: 'Guinaifen',
    app_rate_13: '5.63%',
    avg_round_13: 3304,
    app_flat_13: 2,
    char_14: 'ruan-mei',
    app_rate_14: '5.63%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '4.23%',
    avg_round_15: 3201,
    app_flat_15: 1,
    char_16: 'trailblazer-harmony',
    app_rate_16: '4.23%',
    avg_round_16: 3135,
    app_flat_16: 1,
    char_17: 'Feixiao',
    app_rate_17: '2.82%',
    avg_round_17: 3201,
    app_flat_17: 1,
    char_18: 'Rappa',
    app_rate_18: '2.82%',
    avg_round_18: 3135,
    app_flat_18: 1,
    char_19: 'Himeko',
    app_rate_19: '1.41%',
    avg_round_19: 3135,
    app_flat_19: 1,
    char_20: 'Firefly',
    app_rate_20: '1.41%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Gallagher',
    app_rate_21: '1.41%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Lingsha',
    app_rate_22: '1.41%',
    avg_round_22: 3408,
    app_flat_22: 1,
    char_23: 'fu-xuan',
    app_rate_23: '1.41%',
    avg_round_23: 3084,
    app_flat_23: 1,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Blade',
    app: 0.74,
    char_1: 'Jade',
    app_rate_1: '53.03%',
    avg_round_1: 3206,
    app_flat_1: 10,
    char_2: 'Bronya',
    app_rate_2: '36.36%',
    avg_round_2: 3160,
    app_flat_2: 5,
    char_3: 'Robin',
    app_rate_3: '34.85%',
    avg_round_3: 3200,
    app_flat_3: 5,
    char_4: 'Luocha',
    app_rate_4: '33.33%',
    avg_round_4: 3156,
    app_flat_4: 4,
    char_5: 'Sunday',
    app_rate_5: '25.76%',
    avg_round_5: 3186,
    app_flat_5: 3,
    char_6: 'Huohuo',
    app_rate_6: '25.76%',
    avg_round_6: 3220,
    app_flat_6: 3,
    char_7: 'fu-xuan',
    app_rate_7: '18.18%',
    avg_round_7: 3165,
    app_flat_7: 4,
    char_8: 'ruan-mei',
    app_rate_8: '15.15%',
    avg_round_8: 3118,
    app_flat_8: 2,
    char_9: 'Sparkle',
    app_rate_9: '12.12%',
    avg_round_9: 3121,
    app_flat_9: 1,
    char_10: 'Gallagher',
    app_rate_10: '10.61%',
    avg_round_10: 3206,
    app_flat_10: 2,
    char_11: 'Jingliu',
    app_rate_11: '6.06%',
    avg_round_11: 3047,
    app_flat_11: 1,
    char_12: 'Jiaoqiu',
    app_rate_12: '6.06%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Aventurine',
    app_rate_13: '4.55%',
    avg_round_13: 3047,
    app_flat_13: 1,
    char_14: 'Lynx',
    app_rate_14: '4.55%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'trailblazer-harmony',
    app_rate_15: '3.03%',
    avg_round_15: 3242,
    app_flat_15: 1,
    char_16: 'Lingsha',
    app_rate_16: '1.52%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'jing-yuan',
    app_rate_17: '1.52%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Acheron',
    app_rate_18: '1.52%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Pela',
    app_rate_19: '1.52%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Yunli',
    app_rate_20: '1.52%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'black-swan',
    app_rate_21: '1.52%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'silver-wolf',
    app_rate_22: '1.52%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Serval',
    app: 0.73,
    char_1: 'Sunday',
    app_rate_1: '60.0%',
    avg_round_1: 3317,
    app_flat_1: 21,
    char_2: 'Robin',
    app_rate_2: '50.77%',
    avg_round_2: 3318,
    app_flat_2: 18,
    char_3: 'Aventurine',
    app_rate_3: '32.31%',
    avg_round_3: 3328,
    app_flat_3: 13,
    char_4: 'Gallagher',
    app_rate_4: '18.46%',
    avg_round_4: 3303,
    app_flat_4: 6,
    char_5: 'Sparkle',
    app_rate_5: '18.46%',
    avg_round_5: 3126,
    app_flat_5: 5,
    char_6: 'Huohuo',
    app_rate_6: '15.38%',
    avg_round_6: 3263,
    app_flat_6: 5,
    char_7: 'Bronya',
    app_rate_7: '13.85%',
    avg_round_7: 3291,
    app_flat_7: 8,
    char_8: 'Tingyun',
    app_rate_8: '13.85%',
    avg_round_8: 3255,
    app_flat_8: 7,
    char_9: 'Luocha',
    app_rate_9: '10.77%',
    avg_round_9: 2996,
    app_flat_9: 5,
    char_10: 'Jade',
    app_rate_10: '10.77%',
    avg_round_10: 3229,
    app_flat_10: 4,
    char_11: 'fu-xuan',
    app_rate_11: '10.77%',
    avg_round_11: 3229,
    app_flat_11: 1,
    char_12: 'Pela',
    app_rate_12: '7.69%',
    avg_round_12: 3130,
    app_flat_12: 5,
    char_13: 'ruan-mei',
    app_rate_13: '7.69%',
    avg_round_13: 3344,
    app_flat_13: 1,
    char_14: 'Lingsha',
    app_rate_14: '6.15%',
    avg_round_14: 3435,
    app_flat_14: 3,
    char_15: 'Kafka',
    app_rate_15: '4.62%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'trailblazer-harmony',
    app_rate_16: '4.62%',
    avg_round_16: 3334,
    app_flat_16: 1,
    char_17: 'Lynx',
    app_rate_17: '3.08%',
    avg_round_17: 3168,
    app_flat_17: 2,
    char_18: 'Acheron',
    app_rate_18: '3.08%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Welt',
    app_rate_19: '1.54%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Bailu',
    app_rate_20: '1.54%',
    avg_round_20: 3326,
    app_flat_20: 1,
    char_21: 'Rappa',
    app_rate_21: '1.54%',
    avg_round_21: 3334,
    app_flat_21: 1,
    char_22: 'Natasha',
    app_rate_22: '1.54%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Jingliu',
    app_rate_23: '1.54%',
    avg_round_23: 2963,
    app_flat_23: 1,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Argenti',
    app: 0.63,
    char_1: 'Sunday',
    app_rate_1: '67.86%',
    avg_round_1: 3259,
    app_flat_1: 17,
    char_2: 'Huohuo',
    app_rate_2: '33.93%',
    avg_round_2: 3266,
    app_flat_2: 12,
    char_3: 'Sparkle',
    app_rate_3: '28.57%',
    avg_round_3: 3325,
    app_flat_3: 3,
    char_4: 'Robin',
    app_rate_4: '28.57%',
    avg_round_4: 3337,
    app_flat_4: 5,
    char_5: 'Tingyun',
    app_rate_5: '26.79%',
    avg_round_5: 3200,
    app_flat_5: 9,
    char_6: 'Aventurine',
    app_rate_6: '23.21%',
    avg_round_6: 3180,
    app_flat_6: 3,
    char_7: 'Jade',
    app_rate_7: '21.43%',
    avg_round_7: 3183,
    app_flat_7: 5,
    char_8: 'Luocha',
    app_rate_8: '12.5%',
    avg_round_8: 3176,
    app_flat_8: 3,
    char_9: 'Bronya',
    app_rate_9: '8.93%',
    avg_round_9: 3094,
    app_flat_9: 2,
    char_10: 'fu-xuan',
    app_rate_10: '7.14%',
    avg_round_10: 3299,
    app_flat_10: 1,
    char_11: 'Jiaoqiu',
    app_rate_11: '7.14%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'ruan-mei',
    app_rate_12: '7.14%',
    avg_round_12: 3299,
    app_flat_12: 1,
    char_13: 'Acheron',
    app_rate_13: '5.36%',
    avg_round_13: 3067,
    app_flat_13: 1,
    char_14: 'Gallagher',
    app_rate_14: '5.36%',
    avg_round_14: 3214,
    app_flat_14: 1,
    char_15: 'Pela',
    app_rate_15: '3.57%',
    avg_round_15: 3067,
    app_flat_15: 1,
    char_16: 'Lynx',
    app_rate_16: '3.57%',
    avg_round_16: 3394,
    app_flat_16: 1,
    char_17: 'Welt',
    app_rate_17: '1.79%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'jing-yuan',
    app_rate_18: '1.79%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Lingsha',
    app_rate_19: '1.79%',
    avg_round_19: 3067,
    app_flat_19: 1,
    char_20: 'Rappa',
    app_rate_20: '1.79%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'trailblazer-harmony',
    app_rate_21: '1.79%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Xueyi',
    app: 0.61,
    char_1: 'ruan-mei',
    app_rate_1: '70.91%',
    avg_round_1: 3208,
    app_flat_1: 24,
    char_2: 'trailblazer-harmony',
    app_rate_2: '69.09%',
    avg_round_2: 3210,
    app_flat_2: 25,
    char_3: 'Gallagher',
    app_rate_3: '58.18%',
    avg_round_3: 3215,
    app_flat_3: 22,
    char_4: 'Robin',
    app_rate_4: '20.0%',
    avg_round_4: 3560,
    app_flat_4: 1,
    char_5: 'Sparkle',
    app_rate_5: '16.36%',
    avg_round_5: 3045,
    app_flat_5: 1,
    char_6: 'Lingsha',
    app_rate_6: '14.55%',
    avg_round_6: 3284,
    app_flat_6: 4,
    char_7: 'Aventurine',
    app_rate_7: '12.73%',
    avg_round_7: 3045,
    app_flat_7: 1,
    char_8: 'fu-xuan',
    app_rate_8: '9.09%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Sunday',
    app_rate_9: '7.27%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Huohuo',
    app_rate_10: '5.45%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Feixiao',
    app_rate_11: '5.45%',
    avg_round_11: 3560,
    app_flat_11: 1,
    char_12: 'Qingque',
    app_rate_12: '3.64%',
    avg_round_12: 3045,
    app_flat_12: 1,
    char_13: 'Firefly',
    app_rate_13: '1.82%',
    avg_round_13: 3277,
    app_flat_13: 1,
    char_14: 'march-7th-swordmaster',
    app_rate_14: '1.82%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'silver-wolf',
    app_rate_15: '1.82%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'topaz',
    app_rate_16: '1.82%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Bailu',
    app: 0.61,
    char_1: 'Sunday',
    app_rate_1: '30.91%',
    avg_round_1: 3239,
    app_flat_1: 7,
    char_2: 'Acheron',
    app_rate_2: '29.09%',
    avg_round_2: 3326,
    app_flat_2: 6,
    char_3: 'Kafka',
    app_rate_3: '25.45%',
    avg_round_3: 3344,
    app_flat_3: 2,
    char_4: 'black-swan',
    app_rate_4: '25.45%',
    avg_round_4: 3348,
    app_flat_4: 1,
    char_5: 'Robin',
    app_rate_5: '23.64%',
    avg_round_5: 3275,
    app_flat_5: 8,
    char_6: 'ruan-mei',
    app_rate_6: '21.82%',
    avg_round_6: 3425,
    app_flat_6: 5,
    char_7: 'Pela',
    app_rate_7: '20.0%',
    avg_round_7: 3288,
    app_flat_7: 7,
    char_8: 'jing-yuan',
    app_rate_8: '20.0%',
    avg_round_8: 3257,
    app_flat_8: 3,
    char_9: 'trailblazer-harmony',
    app_rate_9: '14.55%',
    avg_round_9: 3446,
    app_flat_9: 4,
    char_10: 'Firefly',
    app_rate_10: '12.73%',
    avg_round_10: 3420,
    app_flat_10: 3,
    char_11: 'Feixiao',
    app_rate_11: '9.09%',
    avg_round_11: 3369,
    app_flat_11: 4,
    char_12: 'Sparkle',
    app_rate_12: '9.09%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'march-7th-swordmaster',
    app_rate_13: '7.27%',
    avg_round_13: 3401,
    app_flat_13: 3,
    char_14: 'Rappa',
    app_rate_14: '7.27%',
    avg_round_14: 3238,
    app_flat_14: 3,
    char_15: 'Tingyun',
    app_rate_15: '7.27%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Jiaoqiu',
    app_rate_16: '5.45%',
    avg_round_16: 3466,
    app_flat_16: 2,
    char_17: 'Herta',
    app_rate_17: '5.45%',
    avg_round_17: 3083,
    app_flat_17: 1,
    char_18: 'Jade',
    app_rate_18: '5.45%',
    avg_round_18: 3088,
    app_flat_18: 2,
    char_19: 'Bronya',
    app_rate_19: '3.64%',
    avg_round_19: 3337,
    app_flat_19: 1,
    char_20: 'Boothill',
    app_rate_20: '1.82%',
    avg_round_20: 3344,
    app_flat_20: 1,
    char_21: 'Serval',
    app_rate_21: '1.82%',
    avg_round_21: 3326,
    app_flat_21: 1,
    char_22: 'Welt',
    app_rate_22: '1.82%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'imbibitor-lunae',
    app_rate_23: '1.82%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Moze',
    app_rate_24: '1.82%',
    avg_round_24: 3389,
    app_flat_24: 1,
    char_25: 'Guinaifen',
    app_rate_25: '1.82%',
    avg_round_25: 3224,
    app_flat_25: 1,
    char_26: 'Yunli',
    app_rate_26: '1.82%',
    avg_round_26: 3099,
    app_flat_26: 1,
    char_27: 'Clara',
    app_rate_27: '1.82%',
    avg_round_27: 3099,
    app_flat_27: 1,
    char_28: 'Jingliu',
    app_rate_28: '1.82%',
    avg_round_28: 3100,
    app_flat_28: 1,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    app: 0.51,
    char_1: 'Aventurine',
    app_rate_1: '91.3%',
    avg_round_1: 3159,
    app_flat_1: 6,
    char_2: 'Robin',
    app_rate_2: '71.74%',
    avg_round_2: 3108,
    app_flat_2: 4,
    char_3: 'topaz',
    app_rate_3: '54.35%',
    avg_round_3: 3119,
    app_flat_3: 4,
    char_4: 'silver-wolf',
    app_rate_4: '17.39%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Moze',
    app_rate_5: '10.87%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Jiaoqiu',
    app_rate_6: '8.7%',
    avg_round_6: 3309,
    app_flat_6: 1,
    char_7: 'Sunday',
    app_rate_7: '6.52%',
    avg_round_7: 3309,
    app_flat_7: 1,
    char_8: 'Pela',
    app_rate_8: '6.52%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Gallagher',
    app_rate_9: '6.52%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Tingyun',
    app_rate_10: '4.35%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'ruan-mei',
    app_rate_11: '4.35%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Feixiao',
    app_rate_12: '4.35%',
    avg_round_12: 3214,
    app_flat_12: 1,
    char_13: 'Welt',
    app_rate_13: '2.17%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'imbibitor-lunae',
    app_rate_14: '2.17%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Bronya',
    app_rate_15: '2.17%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Rappa',
    app_rate_16: '2.17%',
    avg_round_16: 3168,
    app_flat_16: 1,
    char_17: 'Acheron',
    app_rate_17: '2.17%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '2.17%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Clara',
    app: 0.41,
    char_1: 'Robin',
    app_rate_1: '56.76%',
    avg_round_1: 3063,
    app_flat_1: 6,
    char_2: 'Aventurine',
    app_rate_2: '24.32%',
    avg_round_2: 3119,
    app_flat_2: 1,
    char_3: 'Sparkle',
    app_rate_3: '21.62%',
    avg_round_3: 3056,
    app_flat_3: 2,
    char_4: 'Acheron',
    app_rate_4: '21.62%',
    avg_round_4: 3094,
    app_flat_4: 2,
    char_5: 'fu-xuan',
    app_rate_5: '21.62%',
    avg_round_5: 2999,
    app_flat_5: 1,
    char_6: 'Sunday',
    app_rate_6: '18.92%',
    avg_round_6: 3129,
    app_flat_6: 3,
    char_7: 'Lingsha',
    app_rate_7: '16.22%',
    avg_round_7: 3129,
    app_flat_7: 3,
    char_8: 'Huohuo',
    app_rate_8: '13.51%',
    avg_round_8: 3121,
    app_flat_8: 2,
    char_9: 'Luocha',
    app_rate_9: '13.51%',
    avg_round_9: 3062,
    app_flat_9: 3,
    char_10: 'Pela',
    app_rate_10: '10.81%',
    avg_round_10: 3032,
    app_flat_10: 2,
    char_11: 'Rappa',
    app_rate_11: '10.81%',
    avg_round_11: 3082,
    app_flat_11: 2,
    char_12: 'Jade',
    app_rate_12: '8.11%',
    avg_round_12: 3054,
    app_flat_12: 1,
    char_13: 'Feixiao',
    app_rate_13: '8.11%',
    avg_round_13: 3311,
    app_flat_13: 1,
    char_14: 'Gallagher',
    app_rate_14: '8.11%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'ruan-mei',
    app_rate_15: '5.41%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Tingyun',
    app_rate_16: '5.41%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'march-7th-swordmaster',
    app_rate_17: '5.41%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'black-swan',
    app_rate_18: '5.41%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Yunli',
    app_rate_19: '5.41%',
    avg_round_19: 3099,
    app_flat_19: 1,
    char_20: 'Himeko',
    app_rate_20: '5.41%',
    avg_round_20: 3119,
    app_flat_20: 1,
    char_21: 'imbibitor-lunae',
    app_rate_21: '2.7%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'trailblazer-harmony',
    app_rate_22: '2.7%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'silver-wolf',
    app_rate_23: '2.7%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Bailu',
    app_rate_24: '2.7%',
    avg_round_24: 3099,
    app_flat_24: 1,
    char_25: 'Jiaoqiu',
    app_rate_25: '2.7%',
    avg_round_25: 3188,
    app_flat_25: 1,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lynx',
    app: 0.41,
    char_1: 'ruan-mei',
    app_rate_1: '45.95%',
    avg_round_1: 3342,
    app_flat_1: 4,
    char_2: 'Firefly',
    app_rate_2: '29.73%',
    avg_round_2: 3459,
    app_flat_2: 2,
    char_3: 'trailblazer-harmony',
    app_rate_3: '29.73%',
    avg_round_3: 3459,
    app_flat_3: 2,
    char_4: 'Bronya',
    app_rate_4: '18.92%',
    avg_round_4: 3234,
    app_flat_4: 2,
    char_5: 'Sparkle',
    app_rate_5: '16.22%',
    avg_round_5: 3196,
    app_flat_5: 1,
    char_6: 'Robin',
    app_rate_6: '16.22%',
    avg_round_6: 3188,
    app_flat_6: 1,
    char_7: 'Acheron',
    app_rate_7: '13.51%',
    avg_round_7: 3348,
    app_flat_7: 3,
    char_8: 'black-swan',
    app_rate_8: '10.81%',
    avg_round_8: 3304,
    app_flat_8: 1,
    char_9: 'silver-wolf',
    app_rate_9: '10.81%',
    avg_round_9: 3278,
    app_flat_9: 2,
    char_10: 'Kafka',
    app_rate_10: '8.11%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Jade',
    app_rate_11: '8.11%',
    avg_round_11: 3151,
    app_flat_11: 2,
    char_12: 'Blade',
    app_rate_12: '8.11%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Feixiao',
    app_rate_13: '8.11%',
    avg_round_13: 3355,
    app_flat_13: 1,
    char_14: 'march-7th-swordmaster',
    app_rate_14: '8.11%',
    avg_round_14: 3355,
    app_flat_14: 1,
    char_15: 'Sunday',
    app_rate_15: '8.11%',
    avg_round_15: 3346,
    app_flat_15: 3,
    char_16: 'Tingyun',
    app_rate_16: '8.11%',
    avg_round_16: 3271,
    app_flat_16: 2,
    char_17: 'Jiaoqiu',
    app_rate_17: '8.11%',
    avg_round_17: 3439,
    app_flat_17: 2,
    char_18: 'Pela',
    app_rate_18: '8.11%',
    avg_round_18: 3296,
    app_flat_18: 3,
    char_19: 'Seele',
    app_rate_19: '5.41%',
    avg_round_19: 3196,
    app_flat_19: 1,
    char_20: 'Serval',
    app_rate_20: '5.41%',
    avg_round_20: 3168,
    app_flat_20: 2,
    char_21: 'Argenti',
    app_rate_21: '5.41%',
    avg_round_21: 3394,
    app_flat_21: 1,
    char_22: 'Boothill',
    app_rate_22: '5.41%',
    avg_round_22: 3390,
    app_flat_22: 1,
    char_23: 'Sampo',
    app_rate_23: '2.7%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Qingque',
    app_rate_24: '2.7%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Herta',
    app_rate_25: '2.7%',
    avg_round_25: 3114,
    app_flat_25: 1,
    char_26: 'jing-yuan',
    app_rate_26: '2.7%',
    avg_round_26: 3253,
    app_flat_26: 1,
    char_27: 'imbibitor-lunae',
    app_rate_27: '2.7%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Asta',
    app: 0.39,
    char_1: 'trailblazer-harmony',
    app_rate_1: '48.57%',
    avg_round_1: 3428,
    app_flat_1: 9,
    char_2: 'Firefly',
    app_rate_2: '45.71%',
    avg_round_2: 3441,
    app_flat_2: 7,
    char_3: 'Gallagher',
    app_rate_3: '37.14%',
    avg_round_3: 3347,
    app_flat_3: 9,
    char_4: 'Aventurine',
    app_rate_4: '20.0%',
    avg_round_4: 3187,
    app_flat_4: 4,
    char_5: 'Kafka',
    app_rate_5: '17.14%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'black-swan',
    app_rate_6: '14.29%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Huohuo',
    app_rate_7: '14.29%',
    avg_round_7: 3439,
    app_flat_7: 1,
    char_8: 'ruan-mei',
    app_rate_8: '14.29%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Sunday',
    app_rate_9: '11.43%',
    avg_round_9: 3173,
    app_flat_9: 3,
    char_10: 'Rappa',
    app_rate_10: '11.43%',
    avg_round_10: 3180,
    app_flat_10: 4,
    char_11: 'Himeko',
    app_rate_11: '8.57%',
    avg_round_11: 3126,
    app_flat_11: 1,
    char_12: 'jing-yuan',
    app_rate_12: '8.57%',
    avg_round_12: 3284,
    app_flat_12: 2,
    char_13: 'Acheron',
    app_rate_13: '8.57%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Lingsha',
    app_rate_14: '5.71%',
    avg_round_14: 3575,
    app_flat_14: 1,
    char_15: 'Feixiao',
    app_rate_15: '5.71%',
    avg_round_15: 3382,
    app_flat_15: 1,
    char_16: 'Luocha',
    app_rate_16: '5.71%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Pela',
    app_rate_17: '5.71%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'fu-xuan',
    app_rate_18: '5.71%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Robin',
    app_rate_19: '2.86%',
    avg_round_19: 3126,
    app_flat_19: 1,
    char_20: 'Jade',
    app_rate_20: '2.86%',
    avg_round_20: 3382,
    app_flat_20: 1,
    char_21: 'topaz',
    app_rate_21: '2.86%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Tingyun',
    app_rate_22: '2.86%',
    avg_round_22: 3007,
    app_flat_22: 1,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sampo',
    app: 0.19,
    char_1: 'Acheron',
    app_rate_1: '52.94%',
    avg_round_1: 3397,
    app_flat_1: 5,
    char_2: 'black-swan',
    app_rate_2: '47.06%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Kafka',
    app_rate_3: '41.18%',
    avg_round_3: 3122,
    app_flat_3: 1,
    char_4: 'fu-xuan',
    app_rate_4: '29.41%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Aventurine',
    app_rate_5: '29.41%',
    avg_round_5: 3294,
    app_flat_5: 4,
    char_6: 'Pela',
    app_rate_6: '29.41%',
    avg_round_6: 3312,
    app_flat_6: 3,
    char_7: 'Jiaoqiu',
    app_rate_7: '17.65%',
    avg_round_7: 3526,
    app_flat_7: 2,
    char_8: 'Gallagher',
    app_rate_8: '11.76%',
    avg_round_8: 3386,
    app_flat_8: 1,
    char_9: 'Huohuo',
    app_rate_9: '11.76%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Luocha',
    app_rate_10: '11.76%',
    avg_round_10: 3545,
    app_flat_10: 1,
    char_11: 'Lynx',
    app_rate_11: '5.88%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'silver-wolf',
    app_rate_12: '5.88%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'ruan-mei',
    app_rate_13: '5.88%',
    avg_round_13: 3122,
    app_flat_13: 1,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    app: 0.06,
    char_1: 'Acheron',
    app_rate_1: '80.0%',
    avg_round_1: 3462,
    app_flat_1: 2,
    char_2: 'Pela',
    app_rate_2: '60.0%',
    avg_round_2: 3462,
    app_flat_2: 2,
    char_3: 'black-swan',
    app_rate_3: '40.0%',
    avg_round_3: 3494,
    app_flat_3: 1,
    char_4: 'Kafka',
    app_rate_4: '40.0%',
    avg_round_4: 3430,
    app_flat_4: 1,
    char_5: 'Sparkle',
    app_rate_5: '20.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Rappa',
    app_rate_6: '20.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'trailblazer-harmony',
    app_rate_7: '20.0%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'ruan-mei',
    app_rate_8: '20.0%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Natasha',
    app: 0.04,
    char_1: 'Sunday',
    app_rate_1: '50.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Jade',
    app_rate_2: '25.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Feixiao',
    app_rate_3: '25.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Acheron',
    app_rate_4: '25.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Himeko',
    app_rate_5: '25.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Kafka',
    app_rate_6: '25.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Yunli',
    app_rate_7: '25.0%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Bronya',
    app_rate_8: '25.0%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Tingyun',
    app_rate_9: '25.0%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Serval',
    app_rate_10: '25.0%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'trailblazer-harmony',
    app_rate_11: '25.0%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hook',
    app: 0.02,
    char_1: 'ruan-mei',
    app_rate_1: '100.0%',
    avg_round_1: 3340,
    app_flat_1: 1,
    char_2: 'Lingsha',
    app_rate_2: '100.0%',
    avg_round_2: 3340,
    app_flat_2: 1,
    char_3: 'trailblazer-harmony',
    app_rate_3: '50.0%',
    avg_round_3: 3340,
    app_flat_3: 1,
    char_4: 'Sparkle',
    app_rate_4: '50.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hanya',
    app: 0.02,
    char_1: 'jing-yuan',
    app_rate_1: '100.0%',
    avg_round_1: 3377,
    app_flat_1: 1,
    char_2: 'Sunday',
    app_rate_2: '100.0%',
    avg_round_2: 3377,
    app_flat_2: 1,
    char_3: 'fu-xuan',
    app_rate_3: '50.0%',
    avg_round_3: 3377,
    app_flat_3: 1,
    char_4: 'Aventurine',
    app_rate_4: '50.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Misha',
    app: 0.02,
    char_1: 'Robin',
    app_rate_1: '50.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Sunday',
    app_rate_2: '50.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Aventurine',
    app_rate_3: '50.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Jade',
    app_rate_4: '50.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'trailblazer-harmony',
    app_rate_5: '50.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Lingsha',
    app_rate_6: '50.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Luka',
    app: 0.01,
    char_1: 'Boothill',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'trailblazer-harmony',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Aventurine',
    app_rate_3: '100.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '-',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sushang',
    app: 0.01,
    char_1: 'Robin',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'silver-wolf',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Lingsha',
    app_rate_3: '100.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '-',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yukong',
    app: 0.01,
    char_1: 'imbibitor-lunae',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Tingyun',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Aventurine',
    app_rate_3: '100.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '-',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
